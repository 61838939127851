import { useEffect, useState } from "react";

import Header from "components/shared/Header";
import Sidebar from "components/shared/Sidebar";
import Spinner from "components/shared/spinner";
import Filters from "components/Overview/Filters";
import AdsPieChart from "./adsPieChart";
import UnlocksPieChart from "./unlocksPieChart";
import TopLeft from "./topLeft";
import SmadsBreakdown from "./smadsBreakdown";
import SkipClicks from "./skipClicks";
import AvgAdDur from "./avgAdDur";
import Dropoff from "./Dropoff";
import Viewability from "./viewability";
import ConversionRate from "./conversionRate";
import AvgUnlockPrice from "./avgunlockprice";
import { getOverviewData } from "components/shared/utils";
import customFetch from "components/shared/customFetch";

const PaywindowSection = () => {
    const currentCurrency = localStorage.getItem("currentCurrency");

    const [tab, setTab] = useState("overview");
    const [filter, setFilter] = useState(7);
    const [currency, setCurrency] = useState(currentCurrency || "inr");
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [currencyRate, setCurrencyRate] = useState("0.012849");
    const [cacheTime, setCacheTime] = useState("");
    const [date, setDate] = useState([]);

    // Function to convert currency
    const currencyConverter = async () => {
        try {
            const response = await fetch(
                `https://a2.qx.live/currency-conversion?base=${currency}&to=usd`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );
            // Storing data in form of JSON
            const data = await response.json();

            if (data.ok) {
                localStorage.setItem("currentCurrency", currency);
                setCurrencyRate(data.rate);
            }
        } catch (err) {
            console.log(err);
        }
    };

    // Function to get data points for primary article
    const getData = async () => {
        let _date = date;
        if (localStorage.filterDates) {
            _date = JSON.parse(localStorage.filterDates);
        }
        let url =
            _date.length > 0
                ? getOverviewData + `?&startDate=${_date[0]}&endDate=${_date[1]}`
                : getOverviewData;

        const response = await customFetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        // Storing data in form of JSON
        const data = await response.json();
        if (data.ok && data?.data?.paywindow) {
            setData(data.data.paywindow);
            setCacheTime(data.data.createdAt);
            // setLoading(false);
        }

        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    useEffect(() => {
        currencyConverter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currency]);
    return (
        <div className="bg-theme-gray dark:bg-black flex flex-1 flex-col min-h-screen min-w-full">
            <Header tab={tab} cacheTime={cacheTime} />
            <div className="flex flex-1 flex-row">
                <Sidebar tab={tab} setTab={setTab} />
                {loading ? (
                    <div className="flex flex-1 items-center justify-center w-full min-h-full">
                        <Spinner />
                    </div>
                ) : (
                    <div className="flex flex-1 flex-col items-center w-full pb-8">
                        <Filters
                            setFilter={setFilter}
                            cacheTime={cacheTime}
                            currency={currency}
                            setCurrency={setCurrency}
                            date={date}
                            setDate={setDate}
                        />

                        <div className="flex gap-5 flex-col xl:flex-row w-11/12 max-w-1200 xl:w-full items-start 2xl:justify-center">
                            <div className="flex flex-col flex-1 gap-5">
                                <div className="flex items-center justify-between gap-5">
                                    <SkipClicks data={data?.potentialBuyers} currency={currency} />
                                    <AvgAdDur data={data?.avgAdDuration} currency={currency} />{" "}
                                    <AvgUnlockPrice
                                        data={data?.avgUnlockPrice}
                                        currency={currency}
                                        currencyRate={currencyRate}
                                    />
                                    <Dropoff data={data?.dropOffRatio} currency={currency} />
                                    <Viewability data={data?.viewability} currency={currency} />
                                    <ConversionRate
                                        data={data?.conversionRate}
                                        currency={currency}
                                    />
                                </div>
                                <TopLeft
                                    data={data}
                                    currency={currency}
                                    currencyRate={currencyRate}
                                    fetchingArticleData={loading}
                                />
                                <div
                                    className={
                                        data.smadsBreakdown.total_smads_solved
                                            ? "flex justify-between gap-5"
                                            : "flex justify-center gap-5"
                                    }
                                >
                                    <UnlocksPieChart data={data?.unlocksBreakdown} />
                                    <AdsPieChart data={data?.adsBreakdown} />
                                    {data.smadsBreakdown.total_smads_solved ? (
                                        <SmadsBreakdown data={data?.smadsBreakdown} />
                                    ) : null}
                                </div>
                            </div>
                            {/* <TopRight currency={currency} currencyRate={currencyRate} /> */}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default PaywindowSection;
