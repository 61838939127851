import { useState } from "react";
import mixpanel from "mixpanel-browser";

import Info from "components/shared/icons/Info";

// import PieChart from "./PieChart";
import DemandBarChart from "./BarChart";
import { Tooltip } from "components/shared/Tooltip";

const ArticleInteractions = ({ data }) => {
    const [hover, setHover] = useState(false);

    const mixPanelTrack = () => {
        // mix panel
        mixpanel.track("ReportClicked", {
            view: "Conversions",
            report: "Category based Conversion",
        });
    };

    return (
        <div
            onClick={() => mixPanelTrack()}
            className="flex flex-col bg-white dark:bg-theme-bgDark rounded-xl py-4 pb-3 w-full xl:max-w-270 mb-4 lg:mb-0"
        >
            <div className="flex items-center justify-between mb-3 px-4">
                <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                        <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                            Category based Conversion
                        </h5>
                        <p
                            className="relative cursor-pointer"
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            <Info />
                            {hover && (
                                <Tooltip
                                    left="-80px"
                                    text="This shows user conversions across different categories of your website."
                                />
                            )}
                        </p>
                    </div>
                </div>
            </div>
            <DemandBarChart data={data} />
            {/* <PieChart data={data} /> */}
        </div>
    );
};

export default ArticleInteractions;
