import ReactECharts from "echarts-for-react";

const DemandBarChart = ({ data }) => {
    let _data = data;

    const colorListLight = ["#009393", "#25CA83", "#00A3FF", "#006B6B", "#929292", "#25CA83"];
    const colorListDark = ["#25CA83", "#9CFFD4", "#3BABFF", "#009393", "#929292", "#1D6BA6"];

    let colorList = localStorage.theme === "light" ? colorListLight : colorListDark;

    _data.map((barPoints, index) => (barPoints.itemStyle = { color: colorList[index] }));

    const options = {
        tooltip: {
            show: true,
            trigger: "item",
        },
        grid: {
            left: "0%",
            right: "0%",
            bottom: "0%",
            top: "0%",
            containLabel: true,
        },
        legend: {
            show: false,
        },
        // xAxis: {
        //     type: "value",
        //     axisLine: {
        //         show: true,
        //         onZero: true,
        //         onZeroAxisIndex: null,
        //     },
        //     show: true,
        //     axisTick: {
        //         show: false,
        //     },
        //     axisLabel: {
        //         show: false,
        //     },
        //     splitLine: {
        //         show: true,
        //         lineStyle: {
        //             color: localStorage.theme === "light" ? "#D1D1D1" : "#393939",
        //             type: "dashed",
        //             opacity: "0.6",
        //         },
        //     },
        // },
        // yAxis: [
        //     {
        //         type: "category",
        //         inverse: true,
        //         // show: false,
        //         // data: ["IPL 2022", "Russia vs Ukrain war ", "Gujrat Election 2022"],
        //         axisTick: {
        //             show: false,
        //         },
        //         axisLine: {
        //             show: true,
        //             onZero: true,
        //             onZeroAxisIndex: null,
        //         },
        //         axisLabel: {
        //             show: false,
        //         },
        //     },
        //     {
        //         type: "category",
        //         // data: ["54%", "74%", "64%", "49%", "85%"],
        //         axisTick: {
        //             show: false,
        //         },
        //         axisLine: {
        //             show: false,
        //             onZero: true,
        //             onZeroAxisIndex: null,
        //         },
        //         axisLabel: {
        //             show: true,
        //             color: localStorage.theme === "light" ? "#000" : "#fff",
        //         },
        //     },
        // ],
        series: [
            {
                data: _data?.map((points) => ({
                    value: points.valueClicks,
                    name: points.category,
                    itemStyle: points.itemStyle,
                })),
                // data: [

                //     {
                //         value: 85,
                //         name: "Entertainment",
                //     },
                //     {
                //         value: 49,
                //         name: "Sports",
                //     },
                //     {
                //         value: 64,
                //         name: "Politics",
                //     },
                //     {
                //         value: 74,
                //         name: "Health & Lifestyle",
                //     },
                //     {
                //         value: 54,
                //         name: "Others",
                //     },
                // ],
                type: "pie",
                radius: "70%",
                // barWidth: 20,
                showBackground: true,
                color: "#1D6BA6",
                // backgroundStyle: {
                //     color: localStorage.theme === "light" ? "#00A45D" : "#00A45D",
                // },
                label: {
                    show: true,
                    // position: [5, -12],
                    // align: "left",
                    // fontWeight: 700,
                    fontSize: 8,
                    // padding: 50,
                    formatter: function (d) {
                        return d.name;
                    },
                    color: localStorage.theme === "light" ? "#000" : "#fff",
                },
            },
        ],
    };

    if (!options) return <div> Loading....</div>;

    return (
        <div className="flex flex-1 items-center justify-center">
            {options && (
                <ReactECharts
                    option={options}
                    style={{
                        display: "block",
                        width: "270px",
                        height: "220px",
                    }}
                />
            )}
        </div>
    );
};

export default DemandBarChart;
