import React, { useEffect } from "react";

import Header from "components/shared/Header/index";
import Footer from "components/shared/Footer";
import ArrowUp from "components/shared/icons/ArrowUpScroll";
import bg from "assests/images/bg2.png";
import { Link } from "react-router-dom";

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div
            className="flex flex-1 min-h-full w-full flex-col text-white relative"
            style={{
                backgroundImage: `url(${bg})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
            }}
        >
            <Header />
            <div className="flex flex-1 flex-col items-center my-16">
                <button
                    onClick={() => window.scrollTo(0, 0)}
                    className="fixed bottom-24 right-10 rounded-full bg-slate-50 z-[500]"
                >
                    <ArrowUp />
                </button>

                <h1 className="mb-2 text-2xl font-bold lg:text-5xl text-blue2">TERMS OF SERVICE</h1>
                <p className="mb-12 text-center text-sm text-gray-300">
                    (Last Updated on 15/09/2023)
                </p>

                <div className="w-full text-base max-w-800 text-gray2 mb-14 px-4 lg:px-0">
                    <h6 className="font-bold mb-4 text-gray-300">
                        Hello Kunato users, content creators, and internet explorers!
                    </h6>
                    <div>
                        <p className="mb-4">
                            This Terms of Service (“Terms”) applies to your access to, and use of
                            the websites, mobile apps, widgets, APIs, emails, and other online
                            products and services (collectively, the “Products”) provided by Kunato,
                            Inc. (“Kunato,” “we,” “us,” or “our”).
                        </p>
                        <p className="mb-4">
                            By accessing or using our Products, you agree to be bound by these Terms
                            and Disclaimers. If you do not agree to these Terms and Disclaimers, you
                            may not access or use our Products.
                        </p>
                        <p className="mb-4">
                            <h6 className="font-bold mb-1 text-gray-300">
                                1. Your Access to the Products
                            </h6>
                            <br />
                            <b className="text-lg">
                                Temporary Access: Before registering an account, content consumers
                                have 24-hour access to unlocked articles, limited to the IP address.
                                This terms will change upon account creation.{" "}
                            </b>{" "}
                            <br /> <br />
                            No one under 13 is allowed to use or access the Products. We may offer
                            additional Products that require you to be older to use them, so please
                            read all notices and any Additional Terms carefully when you access the
                            Products.
                        </p>

                        <p className="mb-4">
                            By using the Products, you state that:
                            <ol className=" list-disc pl-5">
                                <li>
                                    You are at least 13 years old and over the minimum age required
                                    by the laws of your country of residence to access and use the
                                    Products.
                                </li>
                                <li>
                                    You can form a binding contract with Kunato, or, if you are over
                                    13 but under the age of majority in your jurisdiction, that your
                                    legal guardian has reviewed and agrees to these Terms.
                                </li>
                                <li>
                                    You are not barred from using the Products under all applicable
                                    laws; and
                                </li>
                                <li>
                                    You have not been permanently suspended or removed from the
                                    Products.
                                </li>
                            </ol>
                        </p>

                        <p className="mb-4 font-medium">
                            You have not been permanently suspended or removed from the Products.
                        </p>

                        <p className="mb-4">
                            <h6 className="font-bold mb-1 text-gray-300">2. Privacy</h6>
                            Kunato’s{" "}
                            <Link className="text-blue2" to="/privacy">
                                Privacy Policy{" "}
                            </Link>{" "}
                            explains how and why we collect, use, and share information about you
                            when you access or use our Products. You understand that through your
                            use of the Products, you consent to the collection and use of this
                            information as set forth in the
                            <Link className="text-blue2" to="/privacy">
                                Privacy Policy{" "}
                            </Link>{" "}
                            .
                        </p>

                        <p className="mb-4">
                            <h6 className="font-bold mb-1 text-gray-300">
                                3. Your Use of the Products
                            </h6>
                            Subject to your complete and ongoing compliance with these Terms, Kunato
                            grants you a personal, non-transferable, non-exclusive, revocable,
                            limited license to: (a) install and use a copy of our mobile application
                            associated with the Products that is obtained from a legitimate
                            marketplace on a mobile device owned or controlled by you; and (b)
                            access and use the Products. We reserve all rights not expressly granted
                            to you by these Terms. Except and solely to the extent such a
                            restriction is impermissible under applicable law, you may not, without
                            our written agreement:
                            <p>
                                {" "}
                                <ul className="pl-5 list-disc">
                                    <li>
                                        License, sell, transfer, assign, distribute, host, or
                                        otherwise commercially exploit the Products or Content;
                                    </li>
                                    <li>
                                        Modify, prepare derivative works of, disassemble, decompile,
                                        or reverse engineer any part of the Products or Content; or
                                    </li>
                                    <li>
                                        Access the Products or Content in order to build a similar
                                        or competitive website, product, or service, except as
                                        permitted under any Additional Terms (as defined below).
                                    </li>
                                </ul>
                            </p>
                        </p>
                        <p className="mb-4">
                            We are always improving our Products. This means we may add or remove
                            features, products, or functionalities; we will try to notify you
                            beforehand, but that won’t always be possible. We reserve the right to
                            modify, suspend, or discontinue the Products (in whole or in part) at
                            any time, with or without notice to you. Any future release, update, or
                            other addition to functionality of the Products will be subject to these
                            Terms, which may be updated from time to time. You agree that we will
                            not be liable to you or to any third party for any modification,
                            suspension, or discontinuation of the Products or any part thereof.
                        </p>

                        <p className="mb-4">
                            <h6 className="font-bold mb-1 text-gray-300">
                                4. Your Kunato Account and Account Security
                            </h6>
                            To use certain features of our Products, you may be required to create a
                            Kunato account (an “Account”) and provide us with a username, password,
                            and certain other information about yourself as set forth in the Privacy
                            Policy
                        </p>

                        <p className="mb-4">
                            You are solely responsible for the information associated with your
                            Account and anything that happens related to your Account. You must
                            maintain the security of your Account and immediately notify Kunato if
                            you discover or suspect that someone has accessed your Account without
                            your permission. We recommend that you use a strong password that is
                            used only with your Account and enable two-factor authentication.
                        </p>

                        <p className="mb-4">
                            You will not license, sell, or transfer your Account without our prior
                            written approva
                        </p>
                        <p className="mb-4">
                            <h6 className="font-bold mb-1 text-gray-300">
                                5. Articles and Content
                            </h6>
                            We do not expressly or implicitly endorse, support, or guarantee the
                            completeness, truthfulness, accuracy, or reliability of any information,
                            text, links, graphics, photos, videos, audio, streams, or other
                            materials (“Content”) by content creators and publishers, including
                            Content created with or submitted using our Products by them, you or
                            through your Account (“Your Content”).
                        </p>
                        <p className="mb-4">
                            By submitting Your Content to the Products, you represent and warrant
                            that you have all rights, power, and authority necessary to grant the
                            rights to Your Content contained within these Terms. Because you alone
                            are responsible for Your Content, you may expose yourself to liability
                            if you post or share Content without all necessary rights.
                        </p>
                        <p className="mb-4">
                            You retain any ownership rights you have in Your Content, but you grant
                            Kunato the following license to use that Content:
                        </p>
                        <p className="mb-4">
                            When Your Content is created with or submitted to the Products for
                            valuation or other related purpose, you grant us a worldwide,
                            royalty-free, perpetual, irrevocable, non-exclusive, transferable, and
                            sublicensable license to use, copy, modify, adapt, prepare derivative
                            works of, distribute, store, perform, and display Your Content and any
                            name, username, voice, or likeness provided in connection with Your
                            Content in all media formats and channels now known or later developed
                            anywhere in the world. This license includes the right for us to make
                            Your Content available for syndication, broadcast, distribution, or
                            publication by other companies, organizations, or individuals who
                            partner with Kunato. You also agree that we may remove metadata
                            associated with Your Content, and you irrevocably waive any claims and
                            assertions of moral rights or attribution with respect to Your Content.
                        </p>
                        <p className="mb-4">
                            Any ideas, suggestions, and feedback about Kunato or our Products that
                            you provide to us are entirely voluntary, and you agree that Kunato may
                            use such ideas, suggestions, and feedback without compensation or
                            obligation to you.
                        </p>
                        <p className="mb-4">
                            Although we have no obligation to screen, edit, or monitor the Content,
                            we may, in our sole discretion, stop valuing the Content at any time and
                            for any reason, including for violating these Terms, violating our
                            Content Policy, or if it otherwise creates or are likely to create
                            liability for us.
                        </p>

                        <p className="mb-4">
                            <h6 className="font-bold mb-1 text-gray-300">
                                6. Third-Party Content, Advertisements, and Promotions
                            </h6>
                            The Content may contain links to third-party websites, products, or
                            services, which may be posted by advertisers, our affiliates, our
                            partners, or other users (“Third-Party Content”). Third-Party Content is
                            not under our control, and we are not responsible for any third party’s
                            websites, products, or services. Your use of Third-Party Content is at
                            your own risk and you should make any investigation you feel necessary
                            before proceeding with any transaction in connection with such
                            Third-Party Content.
                        </p>
                        <p className="mb-4">
                            The Products may also contain sponsored Third-Party Content or
                            advertisements. The type, degree, and targeting of advertisements are
                            subject to change, and you acknowledge and agree that we may place
                            advertisements in connection with the display of any Content or
                            information on the Products, including Your Content.
                        </p>
                        <p className="mb-4">
                            If you choose to use the Products to conduct a promotion, including a
                            contest or sweepstakes (“Promotion”), you alone are responsible for
                            conducting the Promotion in compliance with all applicable laws and
                            regulations, including but not limited to creating official rules, offer
                            terms, eligibility requirements, and compliance with applicable laws,
                            rules, and regulations which govern the Promotion (such as licenses,
                            registrations, bonds, and regulatory approval). Your Promotion must
                            state that the Promotion is not sponsored by, endorsed by, or associated
                            with Kunato, and the rules for your Promotion must require each entrant
                            or participant to release Kunato from any liability related to the
                            Promotion. You acknowledge and agree that we will not assist you in any
                            way with your promotion, and you agree to conduct your Promotion at your
                            own risk.
                        </p>
                        <p className="mb-4">
                            <h6 className="font-bold mb-1 text-gray-300">
                                7. Things You Cannot Do
                            </h6>
                            When using or accessing Kunato, you must comply with these Terms and all
                            applicable laws, rules, and regulations. Please review the Content
                            Policy, which are incorporated by this reference into, and made a part
                            of, these Terms and contain Kunato’s rules about prohibited content and
                            conduct. In addition to what is prohibited in the Content Policy, you
                            may not do any of the following:
                        </p>

                        <p className="mb-4">
                            Use the Products in any manner that could interfere with, disable,
                            disrupt, overburden, or otherwise impair the Products; Gain access to
                            (or attempt to gain access to) another user’s Account or any non-public
                            portions of the Products, including the computer systems or networks
                            connected to or used together with the Products; Upload, transmit, or
                            distribute to or through the Products any viruses, worms, malicious
                            code, or other software intended to interfere with the Products,
                            including its security-related features; Use the Products to violate
                            applicable law or infringe any person’s or entity's intellectual
                            property rights or any other proprietary rights; Access, search, or
                            collect data from the Products by any means (automated or otherwise)
                            except as permitted in these Terms or in a separate agreement with
                            Kunato (we conditionally grant permission to crawl the Products in
                            accordance with the parameters set forth in our robots.txt file, but
                            scraping the Products without Kunato’s prior written consent is
                            prohibited); or Use the Products in any manner that we reasonably
                            believe to be an abuse of or fraud on Kunato or any payment system. We
                            encourage you to report content or conduct that you believe violates
                            these Terms or our Content Policy. We also support the responsible
                            reporting of security vulnerabilities. To report a security issue,
                            please{" "}
                            <a href="mailto:contact@qx.live" className="text-blue2 cursor-pointer">
                                support@kunato.io{" "}
                            </a>
                        </p>
                        <p className="mb-4">
                            <h6 className="font-bold mb-1 text-gray-300">
                                8. Copyright, Trademark, the DMCA, and Takedowns
                            </h6>
                            Kunato respects the intellectual property of others and requires that
                            users of our Products do the same. We have a policy that includes the
                            removal of any infringing material from the Products and for assisting
                            in the removal of content owned by publishers.
                        </p>
                        <p className="mb-4">
                            Please note that Kunato does not publish the articles and assumes no
                            responsibility for their content or any related issues. Kunato cannot
                            directly remove publications; however, we will make efforts to notify
                            publishers of any infringing content and encourage them to take
                            appropriate action.
                        </p>
                        <p className="mb-4">
                            If you believe that anything on our Products infringes a copyright or a
                            trademark that you own or control, you may notify Kunato’s Designated
                            Agent by filling out our Copyright Report Form or Trademark Report Form,
                            or by contacting{" "}
                            <a href="mailto:contact@qx.live" className="text-blue2 cursor-pointer">
                                support@kunato.io{" "}
                            </a>
                        </p>
                        <p className="mb-4">
                            Also, if you knowingly misrepresent that any activity or material on our
                            Products is infringing, you may be liable to Kunato for certain costs
                            and damages.
                        </p>

                        <p className="mb-4">
                            <h6 className="font-bold mb-1 text-gray-300">
                                9. Paid Products and Payment Detail
                            </h6>
                            While many features of the Products are available for free, some, such
                            as access to premium. Content require payment (“Paid Products”). By
                            purchasing or using Paid Products, you agree to these Terms.
                        </p>
                        <p className="mb-4">
                            Kunato may change fees or benefits related to Paid Products with
                            reasonable advance notice of significant changes. However, temporary
                            promotions, including fee reductions, don't require advance notice. Any
                            changes in pricing will take effect no earlier than 30 days after
                            notifying you.
                        </p>
                        <p className="mb-4">
                            To purchase Paid Products, you can submit your debit card, credit card,
                            or other payment information (“Payment Information”) through our
                            Products. We use third-party providers to process your Payment
                            Information. By submitting your Payment Information, you agree to pay
                            all incurred costs and give us permission to charge you when payment is
                            due. This includes all costs, applicable taxes, and fees. All
                            transactions are final, and we don't offer refunds or credits for
                            partially used billing periods. You shall also abide by the terms and
                            condition of the payment gateway or provider.
                        </p>

                        <p className="mb-4">
                            <h6 className="font-bold mb-1 text-gray-300">
                                10. Intellectual Property
                            </h6>
                            Kunato owns and operates the Products. The visual interfaces, graphics,
                            design, compilation, information, data, computer code, products,
                            services, trademarks, and all other elements of the Products
                            (“Materials”) are protected by intellectual property and other laws.
                            Except for the content owned by publishers in their respective platforms
                            or websites, materials within the Products are the property of Kunato or
                            its third-party licensors. You agree not to claim any ownership rights
                            by accessing Content or purchasing any Kunato Paid Products. Except as
                            authorized by Kunato, you may not use the materials. Kunato reserves all
                            rights not expressly granted in these Terms.
                        </p>

                        <p className="mb-4">
                            <h6 className="font-bold mb-1 text-gray-300">11. Indemnity</h6>
                            Except where prohibited by law, you agree to defend, indemnify, and hold
                            Kunato, its affiliates, and their respective directors, officers,
                            employees, agents, contractors, third-party service providers, and
                            licensors (the “Kunato Entities”) harmless from any claim or demand made
                            by a third party, and any related liability, damage, loss, and expense
                            (including costs and attorney's fees) due to, arising out of, or in
                            connection with: (a) your use of the Products, (b) your violation of
                            these Terms, (c) your violation of applicable laws or regulations, or
                            (d) Your Content. We reserve the right to control the defense of any
                            matter for which you are required to indemnify us, and you agree to
                            cooperate with our defense of these claims.
                        </p>

                        <p className="mb-4">
                            <h6 className="font-bold mb-1 text-gray-300">
                                12. Disclaimers and Limitation of Liability
                            </h6>
                            These Terms do not affect your statutory rights as a user of the
                            Products. Some jurisdictions may not allow certain exclusions or
                            limitations, so the terms in this section may not fully apply to you.
                            Instead, in such jurisdictions, the exclusions and limitations shall
                            apply only to the extent permitted by the laws of those jurisdictions.
                        </p>
                        <p className="mb-4">
                            THE PRODUCTS ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTIES,
                            REPRESENTATIONS, OR CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED,
                            LEGAL, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES
                            OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND
                            NON-INFRINGEMENT. THE KUNATO ENTITIES DO NOT WARRANT THAT THE PRODUCTS
                            ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR-FREE. KUNATO DOES
                            NOT CONTROL, ENDORSE, OR TAKE RESPONSIBILITY FOR ANY CONTENT AVAILABLE
                            ON OR LINKED TO THE PRODUCTS OR THE ACTIONS OF ANY THIRD PARTY OR USER,
                            INCLUDING MODERATORS. WHILE KUNATO ATTEMPTS TO MAKE YOUR ACCESS TO AND
                            USE OF OUR PRODUCTS SAFE, WE DO NOT REPRESENT OR WARRANT THAT OUR
                            PRODUCTS OR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
                        </p>

                        <p className="mb-4">
                            IN NO EVENT WILL ANY OF THE KUNATO ENTITIES BE LIABLE TO YOU FOR ANY
                            INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
                            DAMAGES, OR LOST PROFITS ARISING FROM OR RELATING TO THESE TERMS OR THE
                            PRODUCTS, INCLUDING THOSE ARISING FROM OR RELATING TO CONTENT MADE
                            AVAILABLE ON THE PRODUCTS THAT IS ALLEGED TO BE DEFAMATORY, OFFENSIVE,
                            OR ILLEGAL. ACCESS TO, AND USE OF, THE PRODUCTS IS AT YOUR OWN
                            DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE
                            TO YOUR DEVICE OR COMPUTER SYSTEM, OR RESULTING LOSS OF DATA. IN NO
                            EVENT WILL THE AGGREGATE LIABILITY OF THE KUNATO ENTITIES EXCEED THE
                            GREATER OF ONE HUNDRED U.S. DOLLARS ($100) OR ANY AMOUNT YOU PAID KUNATO
                            IN THE PREVIOUS SIX MONTHS FOR THE PRODUCTS GIVING RISE TO THE CLAIM.
                            THE LIMITATIONS OF THIS SECTION WILL APPLY TO ANY THEORY OF LIABILITY,
                            INCLUDING THOSE BASED ON WARRANTY, CONTRACT, STATUTE, TORT, NEGLIGENCE,
                            STRICT LIABILITY, OR OTHERWISE, EVEN IF THE KUNATO ENTITIES HAVE BEEN
                            ADVISED OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF ANY REMEDY
                            SET FORTH IN THESE TERMS IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE.
                        </p>

                        <p className="mb-4">
                            <h6 className="font-bold mb-1 text-gray-300">
                                13. Governing Law and Venue
                            </h6>
                            We want you to enjoy Kunato, so if you have an issue or dispute, you
                            agree to raise it and try to resolve it with us informally. You can
                            contact us with feedback and concerns here.
                        </p>
                        <p className="mb-4">
                            To the fullest extent permitted by applicable law, any claims arising
                            out of or relating to these Terms or the Products will be governed by
                            the laws of the State of New York, without regard to its conflict of
                            laws rules; all disputes related to these Terms or the Products will be
                            brought solely in the federal or state courts located in New York, New
                            York, and you and Kunato consent to personal jurisdiction in these
                            courts.
                        </p>
                        <p className="mb-4">
                            If you are a U.S. city, county, or state government entity, then this
                            Section 13 does not apply to you. If you are a U.S. federal government
                            entity, any claims arising out of or relating to these Terms or the
                            Products will be governed by the laws of the United States of America
                            without regard to its conflict of laws rules. To the extent permitted by
                            federal law, the laws of New York (other than its conflict of law rules)
                            will apply in the absence of applicable federal law. All disputes
                            related to these Terms or the Products will be brought solely in the
                            federal or state courts located in New York, New York.
                        </p>

                        <p className="mb-4">
                            <h6 className="font-bold mb-1 text-gray-300">
                                14. Changes to these Terms
                            </h6>
                            We may make changes to these Terms from time to time. If we make
                            changes, we will post the revised Terms and update the Effective Date
                            above. If the changes, in our sole discretion, are material, we may also
                            notify you by sending an email to the address associated with your
                            Account (if you have chosen to provide an email address) or by otherwise
                            providing you with notice through our Products. By continuing to access
                            or use the Products on or after the Effective Date of the revised Terms,
                            you agree to be bound by the revised Terms. If you do not agree to the
                            revised Terms, you must stop accessing and using our Products before the
                            changes become effective.
                        </p>

                        <p className="mb-4">
                            <h6 className="font-bold mb-1 text-gray-300">15. Additional Terms</h6>
                            Since we offer a variety of Products, you may be asked to agree to
                            additional terms, policies, guidelines, or rules before using a specific
                            product or service offered by Kunato (collectively, “Additional Terms”).
                            All Additional Terms are incorporated by this reference into, and made a
                            part of, these Terms, and to the extent any Additional Terms conflict
                            with these Terms, the Additional Terms govern with respect to your use
                            of the corresponding Products.
                        </p>
                        <p className="mb-4">
                            If you use Kunato Paid Products, you must also agree to any additional
                            terms applicable.
                        </p>
                        <p className="mb-4">
                            <h6 className="font-bold mb-1 text-gray-300">16. Termination</h6>
                            You may terminate these Terms at any time and for any reason by deleting
                            your Account and discontinuing use of all Products. If you stop using
                            the Products without deactivating your Account, your Account may be
                            deactivated due to prolonged inactivity.
                        </p>
                        <p className="mb-4">
                            To the fullest extent permitted by applicable law, we may suspend or
                            terminate your Account, status, or ability to access or use the Products
                            at any time for any or no reason, including for violating these Terms or
                            our Content Policy.
                        </p>
                        <p className="mb-4">
                            The following sections will survive any termination of these Terms or of
                            your Account: 5 (Articles and Content), 7 (Things You Cannot Do), 11
                            (Indemnity), 12 (Disclaimers and Limitation of Liability), 13 (Governing
                            Law and Venue), 16 (Termination), and 17 (Miscellaneous).
                        </p>

                        <p className="mb-4">
                            <h6 className="font-bold mb-1 text-gray-300">17. Miscellaneous</h6>
                            These Terms, together with the Privacy Policy and any other agreements
                            expressly incorporated by reference into these Terms, constitute the
                            entire agreement between you and us regarding your access to and use of
                            the Products. Our failure to exercise or enforce any right or provision
                            of these Terms will not operate as a waiver of such right or provision.
                            If any part of these Terms is held to be invalid or unenforceable, the
                            unenforceable part will be given effect to the greatest extent possible,
                            and the remaining parts will remain in full force and effect. You may
                            not assign or transfer any of your rights or obligations under these
                            Terms without our consent. We may freely assign any of our rights and
                            obligations under these Terms.
                        </p>

                        <p className="mb-4">
                            These Terms are a legally-binding agreement between you and Kunato, Inc.
                            If you have any questions about these terms, please contact us at{" "}
                            <a href="mailto:contact@qx.live" className="text-blue2 cursor-pointer">
                                support@kunato.io{" "}
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Terms;
