export const baseUrl = "https://partner.kunato.io/api/v1";
export const authServer = "https://auth.kunato.ai/api/v1";

export const devServer = "https://beta.partner.kunato.io/api/v1";

export const adminServer = "https://admin.kunato.ai/api/v1";

export const getOverviewData = `${baseUrl}/overview`;
export const getAIData = `${baseUrl}/ai-data`;
export const getAIDataViews = `${baseUrl}/ai-data/top-views`;
export const getAIDataRevenue = `${baseUrl}/ai-data/top-revenue-potential`;
export const registerAPI = `${adminServer}/partners/register`;
export const getConverions = `${baseUrl}/conversions`;
export const contactUs = `${baseUrl}/contact-us`;
export const requestAccess = `${baseUrl}/interested`;

export const userSignin = `${authServer}/users/signin`;
export const forgotPassword = `${authServer}/users/forgotPassword`;

export const techData = "https://a.kunato.io/tech_data";

export const articlePriceAPI = "https://ve2.kunato.ai/price";
export const chandlechartAPI = "https://ve2.kunato.ai/ohlc";
