const Close = ({ color }) => {
    let _color = "#eee";
    if (color) {
        _color = color;
    } else if (localStorage.theme) {
        _color = localStorage.theme === "light" ? "#ddd" : "#eee";
    }
    return (
        <svg
            data-v-6a943414=""
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            className="close-icon"
        >
            <path
                data-v-6a943414=""
                d="M6.414 5A1 1 0 105 6.414L10.586 12 5 17.586A1 1 0 106.414 19L12 13.414 17.586 19A1 1 0 1019 17.586L13.414 12 19 6.414A1 1 0 1017.586 5L12 10.586 6.414 5z"
                fill={_color}
            ></path>
        </svg>
    );
};

export default Close;
