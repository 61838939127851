import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import mixpanel from "mixpanel-browser";

import Info from "components/shared/icons/Info";
import { Tooltip } from "components/shared/Tooltip";

const LineChartConv = ({ filter, data }) => {
    // let data = ConversionLineData;
    const [options, setOptions] = useState({});

    const [hover, setHover] = useState(false);

    const mixPanelTrack = () => {
        // mix panel
        mixpanel.track("ReportClicked", {
            view: "Conversions",
            report: "Total Clicked Value Split on time frame",
        });
    };

    const convertTime = (time, add) => {
        let _time = time;
        if (time === 0) {
            _time = "12am";
        } else if (_time < 12) {
            if (add) {
                _time = _time + ":00am";
            } else {
                _time = _time + "am";
            }
        } else {
            if (add) {
                if (_time === 12) {
                    _time = "12:00pm";
                } else {
                    _time = _time - 12 + ":00pm";
                }
            } else {
                if (_time === 12) {
                    _time = "12pm";
                } else {
                    _time = _time - 12 + "pm";
                }
            }
        }
        return _time;
    };

    // Code to set max limit for Y axis
    let max = Math.max(...data.map((o) => o.clickedValue));
    max = parseInt(max * 1.5);

    useEffect(() => {
        let sliceData = data;
        // if (tab === "7D") {
        //     sliceData = sliceData.slice(0, 7);
        // } else if (tab === "15D") {
        //     sliceData = sliceData.slice(0, 15);
        // } else if (tab === "1M") {
        //     sliceData = sliceData.slice(0, 30);
        // }

        // sliceData = sliceData.reverse();

        setOptions({
            title: {
                show: false,
                text: "Valued Articles",
            },
            color: localStorage.theme === "light" ? "#0075FF" : "#00FFFF",
            tooltip: {
                trigger: "item",
                textStyle: {
                    color: localStorage.theme === "light" ? "#000" : "#989898",
                },
                backgroundColor: localStorage.theme === "light" ? "#fff" : "#1D1D1D",
                axisPointer: {
                    type: "cross",
                },
            },
            legend: {
                show: false,
            },
            grid: {
                left: "1%",
                right: "4%",
                bottom: "1%",
                top: "4%",
                containLabel: true,
            },
            toolbox: {
                show: false,
            },
            xAxis: {
                type: "category",
                boundaryGap: false,
                data: sliceData.map((graphPoints) => convertTime(graphPoints.hour)),
            },
            yAxis: {
                type: "value",
                axisLine: {
                    show: true,
                    onZero: true,
                    onZeroAxisIndex: null,
                },
                max: max,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: localStorage.theme === "light" ? "#D1D1D1" : "#393939",
                        type: "dashed",
                        opacity: "0.6",
                    },
                },
            },
            series: [
                {
                    name: "Total Clicked Value",
                    type: "line",
                    data: sliceData.map((graphPoints) => graphPoints.clickedValue.toFixed(2)),
                    showSymbol: false,
                    smooth: true,
                    lineStyle: {
                        color: localStorage.theme === "light" ? "#0075FF" : "#00FFFF",
                        width: 1.5,
                    },

                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: localStorage.theme === "light" ? "#A3CDFF" : "#0A5353",
                            },
                            {
                                offset: 1,
                                color: localStorage.theme === "light" ? "#E3F0FF" : "#0C2929",
                            },
                        ]),
                        // color: localStorage.theme === "light" ? "#C0DDFF" : "#0B3F3F",
                        // opacity: localStorage.theme === "light" ? 0.9 : 0.9,
                        // origin: 200,
                    },

                    emphasis: {
                        focus: "series",
                    },
                },
            ],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, data]);

    if (!options) return <div> Loading....</div>;

    return (
        <div
            onClick={() => mixPanelTrack()}
            className="flex flex-col flex-1 w-full xl:max-w-512 bg-white dark:bg-theme-bgDark rounded-xl px-4 py-3 mr-5 mb-6 xl:mb-0"
        >
            <div className="flex items-center justify-between w-full">
                <div className="flex items-center justify-between w-full mb-2 pl-1 pr-5">
                    <div className="flex items-center">
                        <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                            Total Clicked Value Split on time frame
                        </h5>
                        <p
                            className="relative cursor-pointer"
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            <Info />
                            {hover && (
                                <Tooltip
                                    left="0px"
                                    text="This shows the clicks / user conversions on different time durations of the day."
                                />
                            )}
                        </p>
                    </div>
                </div>
            </div>
            {options && (
                <ReactECharts
                    option={options}
                    style={{
                        display: "block",
                        width: "100%",
                        height: "230px",
                    }}
                />
            )}
        </div>
    );
};

export default LineChartConv;
