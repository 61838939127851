import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import jwtDecode from "jwt-decode";

import Header from "components/shared/Header";
import Sidebar from "components/shared/Sidebar";
import Filters from "./Filters";
import LineGraph from "./LineGraph";
import ContentValue from "./ContentValue";
import Valued from "./Valued";
import Category from "./Category";
import Performance from "./Performance/index";

import customFetch from "components/shared/customFetch";
import { getOverviewData } from "components/shared/utils";
// import { currencyConverter } from "components/shared/currencyConverter";
import Spinner from "components/shared/spinner";

const Overview = () => {
    const currentCurrency = localStorage.getItem("currentCurrency");

    const [tab, setTab] = useState("overview");
    const [filter, setFilter] = useState(7);
    const [currency, setCurrency] = useState(currentCurrency || "inr");
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [currencyRate, setCurrencyRate] = useState("0.012849");
    const [cacheTime, setCacheTime] = useState("");
    const [date, setDate] = useState([]);

    let userInfo;
    let name;

    if (localStorage?.accessToken) {
        userInfo = jwtDecode(localStorage?.accessToken);

        name = userInfo?.name
            .split(" ")
            .map((n) => n[0])
            .join("");

        name = name.substring(0, 3);
    }

    mixpanel.time_event("Overview page - page_view_exit");

    // mix panel
    if (userInfo) {
        mixpanel.track("PageView", {
            view: "Overview",
            username: name,
            email: userInfo?.email,
        });
    } else {
        mixpanel.track("PageView", {
            view: "Overview",
        });
    }

    setInterval(() => {
        mixpanel.track("PagePoll", {
            pollTime: 5,
            view: "Overview",
        });
    }, 5000);

    // Function to convert currency
    const currencyConverter = async () => {
        const response = await fetch(
            `https://a2.qx.live/currency-conversion?base=${currency}&to=usd`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        // Storing data in form of JSON
        const data = await response.json();

        if (data.ok) {
            localStorage.setItem("currentCurrency", currency);
            setCurrencyRate(data.rate);
        }
    };

    // Function to get data points for primary article
    const getData = async () => {
        let _date = date;
        if (localStorage.filterDates) {
            _date = JSON.parse(localStorage.filterDates);
        }
        let url =
            _date.length > 0
                ? getOverviewData + `?&startDate=${_date[0]}&endDate=${_date[1]}`
                : getOverviewData;

        const response = await customFetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        // Storing data in form of JSON
        const data = await response.json();
        if (data.ok) {
            setData(data.data);
            setCacheTime(data.data.createdAt);
        }

        setLoading(false);
    };

    let storage = localStorage;

    useEffect(() => {
        setLoading(true);
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    useEffect(() => {
        currencyConverter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currency]);

    useEffect(() => {
        if (storage.theme === "dark") {
            document.body.style.backgroundColor = "#000";
        } else {
            document.body.style.backgroundColor = "#EEE";
        }
    }, [storage.theme]);

    if (!storage.accessToken) return <Navigate to="/login" />;

    return (
        <div className="bg-theme-gray dark:bg-black flex flex-1 flex-col min-h-screen min-w-full">
            <Header tab={tab} cacheTime={cacheTime} />
            <div className="flex flex-1 flex-row">
                <Sidebar tab={tab} setTab={setTab} />
                {loading ? (
                    <div className="flex flex-1 items-center justify-center w-full min-h-full">
                        <Spinner />
                    </div>
                ) : (
                    <div className="flex flex-1 flex-col items-center w-full">
                        <Filters
                            setFilter={setFilter}
                            cacheTime={cacheTime}
                            currency={currency}
                            setCurrency={setCurrency}
                            date={date}
                            setDate={setDate}
                        />
                        <div className="flex w-11/12 xl:w-full flex-col xl:flex-row items-center 2xl:justify-center mb-4 ">
                            <LineGraph filter={filter} data={data?.userSummary} />
                            <ContentValue
                                filter={filter}
                                currency={currency}
                                data={data}
                                chartData={data?.mostActiveTime}
                                currencyRate={currencyRate}
                            />
                        </div>
                        <div className="flex flex-col xl:flex-row w-11/12 xl:w-full items-center 2xl:justify-center">
                            <Valued
                                currency={currency}
                                data={data?.topValuedArticles}
                                currencyRate={currencyRate}
                            />
                            <Category currency={currency} data={data} currencyRate={currencyRate} />
                            <Performance data={data?.userConversionStats} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Overview;
