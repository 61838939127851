import React, { useState } from "react";
import mixpanel from "mixpanel-browser";

import LineChart from "./LineChart";
// import ArrowUp from "components/shared/icons/ArrowUp";
import Info from "components/shared/icons/Info";

import { Tooltip } from "components/shared/Tooltip";

const LineGraph = ({ filter, data }) => {
    const [hover, setHover] = useState(false);

    const mixPanelTrack = () => {
        // mix panel
        mixpanel.track("ReportClicked", {
            view: "Overview",
            report: "User Summary",
        });
    };

    return (
        <div
            onClick={() => mixPanelTrack()}
            className="flex flex-col items-start h-full w-full bg-white dark:bg-theme-bgDark py-3.5 px-5 rounded-xl xl:mr-4 mb-4 xl:mb-0 xl:max-w-800"
        >
            <div className="flex items-center justify-between w-full mb-2">
                <div className="flex items-center">
                    <h5 className="text-13 md:pl-2 font-semibold text-silver-medium dark:text-silver-medium">
                        User Summary
                    </h5>
                    <p
                        className="relative cursor-pointer"
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                    >
                        <Info />
                        {hover && (
                            <Tooltip
                                left="-80px"
                                text="This shows the complete summary of the user behaviour on your
                            website for the selected time duration . It also shows the
                            comparison between Total users with the Graph interactions and
                            article clicks."
                            />
                        )}
                    </p>
                </div>

                <div className="hidden md:flex items-center pr-6">
                    <div className="flex items-center">
                        <p className=" w-2.5 h-2.5 dark:bg-light-red bg-graph-redLight mr-2"></p>
                        <h5 className="text-13 font-semibold text-gray-600 dark:text-silver-medium">
                            Users
                        </h5>
                    </div>
                    <div className="flex items-center mx-5">
                        <p className=" w-2.5 h-2.5 dark:bg-dark-Blue bg-light-blue mr-2"></p>
                        <h5 className="text-13 font-semibold text-gray-600 dark:text-silver-medium">
                            Graph Interactions
                        </h5>
                    </div>
                    <div className="flex items-center">
                        <p className=" w-2.5 h-2.5 bg-theme-blue dark:bg-light-blue mr-2"></p>

                        <h5 className="text-13 font-semibold text-gray-600 dark:text-silver-medium">
                            Clicks
                        </h5>
                    </div>
                </div>
            </div>
            <div
                style={{ minHeight: "230px" }}
                className="text-center flex items-center justify-center flex-1 w-full h-full"
            >
                <LineChart filter={filter} data={data} />
            </div>
            <div className="flex md:hidden items-center w-full justify-center">
                <div className="flex items-center">
                    <p className=" w-2.5 h-2.5 dark:bg-light-red bg-graph-redLight mr-2"></p>
                    <h5 className="text-13 font-semibold text-gray-600 dark:text-silver-medium">
                        Users
                    </h5>
                </div>
                <div className="flex items-center mx-5">
                    <p className=" w-2.5 h-2.5 dark:bg-dark-Blue bg-light-blue mr-2"></p>
                    <h5 className="text-13 font-semibold text-gray-600 dark:text-silver-medium">
                        Graph Interactions
                    </h5>
                </div>
                <div className="flex items-center">
                    <p className=" w-2.5 h-2.5 bg-theme-blue dark:bg-light-blue mr-2"></p>

                    <h5 className="text-13 font-semibold text-gray-600 dark:text-silver-medium">
                        Clicks
                    </h5>
                </div>
            </div>
            {/* <div className="flex items-center justify-end w-full">
                <h4 className="text-sm font-medium mr-0.5 flex items-center text-green-500">
                    <ArrowUp />
                    <p className="ml-0.5">35.34%</p>
                </h4>
                <p className="text-11 font-medium text-gray-500 dark:text-silver-light">
                    Graph Interaction then other websites
                </p>
            </div> */}
        </div>
    );
};

export default LineGraph;
