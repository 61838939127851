import React, { useState } from "react";
import mixpanel from "mixpanel-browser";

import Info from "components/shared/icons/Info";
import { Tooltip } from "components/shared/Tooltip";

import { currencyDisplay } from "components/shared/currency";
import { converterCurrency } from "components/shared/convertCurrency";
import { numberWithCommas } from "components/shared/NumberCommaAddition";

const ClickedValue = ({ currencyRate, currency, clickedData }) => {
    const [hover, setHover] = useState(false);

    const mixPanelTrack = () => {
        // mix panel
        mixpanel.track("ReportClicked", {
            view: "Conversions",
            report: "Total Clicked Value",
        });
    };

    return (
        <div
            onClick={() => mixPanelTrack()}
            className="flex flex-col bg-white w-full dark:bg-theme-bgDark py-3.5 pl-3 pr-2 mt-1 md:pr-7 rounded-xl mb-5 h-28"
            style={{ width: "300px" }}
        >
            <div className="flex items-center">
                <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                    Total Clicked Value
                </h5>
                <p
                    className="relative cursor-pointer"
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <Info />
                    {hover && (
                        <Tooltip
                            left="-100px"
                            text="This shows a total clicked value for all the users combined for the website."
                        />
                    )}
                </p>
            </div>
            <h4 className="text-2xl font-medium mt-3 mb-4 dark:text-white">
                {currencyDisplay(currency)}
                {numberWithCommas(converterCurrency(clickedData, currencyRate), currency)}
            </h4>
            {/* <h4 className="text-base font-medium mr-0.5 flex items-center dark:text-white">
                        #7
                        <p className="text-11 ml-0.5 font-normal text-green-500">+2</p>
                    </h4>
                    <p className="text-10 font-medium italic text-silver-light dark:text-silver-light">
                        Ranked on the content Value
                    </p> */}
        </div>
    );
};

export default ClickedValue;
