import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

import { currencyDisplay } from "components/shared/currency";
import { converterCurrency } from "components/shared/convertCurrency";
import { numberWithCommas } from "components/shared/NumberCommaAddition";

const BarChart = ({ tab, currency, data, currencyRate }) => {
    const [options, setOptions] = useState({});

    let _data = data.marketCAPByCategory;

    if (tab === "users") {
        _data = data.usersByCategory;
    } else {
        _data = data.marketCAPByCategory;
    }

    let innerWidth = window.innerWidth;

    const colorListLight = ["#009393", "#25CA83", "#00A3FF", "#006B6B", "#929292", "#25CA83"];
    const colorListDark = ["#25CA83", "#9CFFD4", "#3BABFF", "#009393", "#929292", "#1D6BA6"];

    let colorList = localStorage.theme === "light" ? colorListLight : colorListDark;

    _data.map((barPoints, index) => (barPoints.itemStyle = { color: colorList[index] }));

    // Code to set max limit for Y axis
    let maxValues = _data.map((o) => o[tab]);
    const cleanMaxValues = maxValues.filter((element) => {
        return element !== undefined;
    });
    let max = Math.max(...cleanMaxValues);
    max = parseInt(max * 1.2);

    useEffect(() => {
        setTimeout(() => {
            setOptions({
                tooltip: {
                    show: false,
                    trigger: "none",
                },
                grid: {
                    left: "0%",
                    right: "1%",
                    bottom: "1%",
                    top: "5%",
                    containLabel: true,
                },
                legend: {
                    show: false,
                },
                xAxis: {
                    type: "category",
                    data: _data.map((categories) => categories.category),
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        show: true,
                        fontSize: 9,
                        color: localStorage.theme === "light" ? "#000" : "#D9D9D9",
                    },
                },
                yAxis: {
                    type: "value",
                    axisLine: {
                        show: true,
                        onZero: true,
                        onZeroAxisIndex: null,
                    },
                    max: max,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: localStorage.theme === "light" ? "#D1D1D1" : "#393939",
                            type: "dashed",
                            opacity: "0.5",
                        },
                    },
                    axisLabel: {
                        show: true,
                        position: "top",
                        fontSize: 10,
                        formatter: function (d) {
                            return numberWithCommas(
                                Math.round(converterCurrency(d, currencyRate), currency)
                            );
                        },
                        color: localStorage.theme === "light" ? "#000" : "#ddd",
                    },
                },
                series: [
                    {
                        data: _data.map((points) => ({
                            value: parseInt(points[tab]),
                            itemStyle: points.itemStyle,
                        })),

                        type: "bar",
                        barWidth: "40%",
                        showBackground: false,
                        label: {
                            show: true,
                            position: "top",
                            fontSize: innerWidth < 500 ? 9 : 10,
                            formatter: function (d) {
                                if (tab === "users") {
                                    return d.data.value;
                                } else {
                                    return (
                                        currencyDisplay(currency) +
                                        numberWithCommas(
                                            converterCurrency(d.data.value, currencyRate),
                                            currency
                                        )
                                    );
                                }
                            },
                            color: localStorage.theme === "light" ? "#000" : "#D9D9D9",
                        },
                    },
                ],
            });
        }, 150);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab, currency, currencyRate]);

    if (!options) return <div> Loading....</div>;

    return (
        <div className="flex flex-1 items-center justify-center w-full">
            {options && (
                <ReactECharts
                    option={options}
                    style={{
                        display: "block",
                        width: "100%",
                        height: "170px",
                        maxWidth: "468px",
                    }}
                />
            )}
        </div>
    );
};

export default BarChart;
