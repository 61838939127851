import React, { useState } from "react";
import mixpanel from "mixpanel-browser";

import Info from "components/shared/icons/Info";
import TableFor1 from "./TableFor1";
import TableFor2 from "./TableFor2";
import TableFor3 from "./TableFor3";

import { currencyDisplay } from "components/shared/currency";
import { converterCurrency } from "components/shared/convertCurrency";
import { Tooltip } from "components/shared/Tooltip";
import { numberWithCommas } from "components/shared/NumberCommaAddition";

const AuthorData = ({ currency, data, currencyRate }) => {
    const [hover, setHover] = useState(false);
    let _data = data;

    const userInitials = (name) => {
        let _name = name;

        _name = _name
            .split(" ")
            .map((n) => n[0])
            .join("");

        _name = _name.substring(0, 3);

        return _name;
    };

    const mixPanelTrack = () => {
        // mix panel
        mixpanel.track("ReportClicked", {
            view: "AI data",
            report: "Author’s Engagement",
        });
    };

    return (
        <div
            onClick={() => mixPanelTrack()}
            className="flex flex-1 w-11/12 overflow-y-scroll md:w-full flex-col bg-white dark:bg-theme-bgDark rounded-xl pt-3 pb-0"
            style={{ minHeight: "275px", maxHeight: "275px" }}
        >
            <div className="flex items-center mb-1.5 px-4">
                <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                    Author’s Engagement
                </h5>
                <p
                    className="relative cursor-pointer"
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <Info />
                    {hover && (
                        <Tooltip
                            left="-80px"
                            text="This shows the top authors on the website with the number of articles written by them along with the total value of the inventory that they have published."
                        />
                    )}
                </p>
            </div>

            {_data.length === 1 && (
                <TableFor1 data={_data} currency={currency} currencyRate={currencyRate} />
            )}
            {_data.length === 2 && (
                <TableFor2 data={_data} currency={currency} currencyRate={currencyRate} />
            )}
            {_data.length === 3 && (
                <TableFor3 data={_data} currency={currency} currencyRate={currencyRate} />
            )}
            {_data.length > 3 && (
                <table className="mb-3">
                    <thead>
                        <tr className="bg-gray-100 text-blue-600 dark:bg-black dark:text-cyan-400 text-10 font-bold text-center p-2">
                            <th className="pl-4 py-2 text-left">Author</th>
                            <th className="pl-0">Articles</th>
                            <th className="pl-12 text-left">Value</th>
                        </tr>
                    </thead>
                    <tbody className="text-xs font-normal dark:text-white text-center">
                        {_data.map((article, index) => (
                            <tr key={index}>
                                <td className="flex items-center text-center pt-2.5 ml-4">
                                    <h4 className="flex items-center font-medium">
                                        <p
                                            className="border uppercase flex items-center justify-center border-gray-400 rounded-full p-1 mr-2 text-11"
                                            style={{ width: "30px", height: "30px" }}
                                        >
                                            {userInitials(article.author)}
                                        </p>
                                        {article.author}
                                    </h4>
                                </td>
                                <td className="pr-2 pt-2">{article?.numberOfArticles}</td>
                                <td className="pl-12 pt-2 text-left">
                                    {currencyDisplay(currency)}
                                    {numberWithCommas(
                                        converterCurrency(article?.value, currencyRate),
                                        currency
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default AuthorData;
