import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import ThemeToggle from "components/shared/ThemeToggle";

// import photo1 from "assests/images/photo1.png";
// import photo2 from "assests/images/photo2.png";
// import newsPub from "assests/images/newsPub.png";

/**
 * Currency dropdown
 * @prop {Function} closeModal - Function to close modal
 */

const UserModal = ({ closeModal, userInfo, name, editModal }) => {
    const modalRef = useRef();

    const history = useNavigate();

    const handleLogout = () => {
        localStorage.clear();
        history("/login");
        window.location.reload();
    };

    useEffect(() => {
        const clickOutside = (event) => {
            if (!modalRef.current.contains(event.target)) {
                closeModal();
            }
        };
        document.addEventListener("mousedown", clickOutside);
        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            ref={modalRef}
            className="bg-white dark:bg-theme-bgDark shadow-md z-50 pt-6 pb-4 rounded-md r-0 w-181 md:right-5"
            style={{
                position: "absolute",
                top: "45px",
            }}
        >
            <div className="dark:text-white flex flex-col items-center justify-center w-full">
                <h6 className="rounded-full mb-2 h-12 w-12 mr-2 border text-sm flex items-center justify-center text-gray-600 dark:text-gray-400 dark:border-gray-400">
                    {name}
                </h6>
                {/* <img src={newsPub} alt="thumbnail" className="rounded-full h-12 w-12 mr-2 border" /> */}
                <h4 className="text-15 font-medium">{userInfo.name}</h4>
                <p className="text-11 font-light mb-3">{userInfo.email}</p>

                <div className=" w-full items-center justify-center flex flex-col  border-b border-border-borderGray mb-3">
                    <button
                        onClick={() => editModal()}
                        className="text-11 text-light-blue w-full dark:text-dark-Blue mb-4"
                    >
                        Edit Profile
                    </button>
                    <div className="mb-4" style={{ width: "70px" }}>
                        <ThemeToggle />
                    </div>
                </div>

                <button
                    onClick={() => handleLogout()}
                    className="text-xs font-semibold dark:text-light-red text-graph-redLight"
                >
                    Sign Out
                </button>
            </div>
        </div>
    );
};

export default UserModal;
