import React, { useState } from "react";
import mixpanel from "mixpanel-browser";

// import ArrowDown from "components/shared/icons/ArrowDown";
// import ArrowUp from "components/shared/icons/ArrowUp";
import Info from "components/shared/icons/Info";
import BarChart from "./ActiveBarChart/BarChart";

import { currencyDisplay } from "components/shared/currency";
import { converterCurrency } from "components/shared/convertCurrency";
import { Tooltip } from "components/shared/Tooltip";
import { numberWithCommas } from "components/shared/NumberCommaAddition";

const ContentValue = ({ currency, data, chartData, currencyRate }) => {
    const [hover, setHover] = useState(false);
    const [hover1, setHover1] = useState(false);

    const mixPanelTrack = (comp) => {
        // mix panel
        mixpanel.track("ReportClicked", {
            view: "Overview",
            report: comp,
        });
    };

    return (
        <div className="flex flex-col xl:flex-col w-full xl:w-auto items-center justify-center">
            <div className="flex mr-1 xl:mr-5 justify-between items-center w-full xl:w-auto">
                <div
                    className="flex mr-5 flex-col bg-white dark:bg-theme-bgDark py-3.5 pl-3 pr-2 md:pr-5 rounded-xl mb-5 h-28"
                    style={{ width: "184px" }}
                    onClick={() => mixPanelTrack("Total Valued Articles")}
                >
                    <div className="flex items-center">
                        <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                            Valued Articles
                        </h5>
                        <p
                            className="relative cursor-pointer"
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            <Info />
                            {hover && (
                                <Tooltip
                                    left="-80px"
                                    text="This shows the total articles which are valued by Kunato AI engine on your website over the period of time."
                                />
                            )}
                        </p>
                    </div>
                    <h4 className="text-2xl font-medium mt-3 mb-4 dark:text-white">
                        {numberWithCommas(data.totalNumberOfArticles, currency)}
                    </h4>
                    {/*  <h4 className="text-sm font-medium mr-0.5 flex items-center text-green-500">
                        <ArrowUp />
                        <p className="ml-0.5">35.34%</p>
                    </h4>
                    <p className="text-10 font-medium italic text-silver-light dark:text-silver-light">
                        Since previous period
                    </p> */}
                </div>
                <div
                    className="flex flex-col bg-white dark:bg-theme-bgDark py-3.5 pl-3 pr-2 md:pr-4 rounded-xl mb-5 h-28"
                    style={{ width: "184px" }}
                    onClick={() => mixPanelTrack("Total Article Value")}
                >
                    <div className="flex items-center">
                        <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                            Total Estimated Value
                        </h5>
                        <p
                            className="relative cursor-pointer"
                            onMouseEnter={() => setHover1(true)}
                            onMouseLeave={() => setHover1(false)}
                        >
                            <Info />
                            {hover1 && (
                                <Tooltip
                                    left="-200px"
                                    text="Cumulative value of the valued content which is available on your website."
                                />
                            )}
                        </p>
                    </div>
                    <h4 className="text-2xl font-medium mt-3 mb-4 dark:text-white">
                        {currencyDisplay(currency)}
                        {numberWithCommas(
                            converterCurrency(data?.totalMarketCAP, currencyRate),
                            currency
                        )}
                    </h4>
                    {/* <h4 className="text-base font-medium mr-0.5 flex items-center dark:text-white">
                        #7
                        <p className="text-11 ml-0.5 font-normal text-green-500">+2</p>
                    </h4>
                    <p className="text-10 font-medium italic text-silver-light dark:text-silver-light">
                        Ranked on the content Value
                    </p> */}
                </div>
            </div>
            <BarChart chartData={chartData} />
            {/* <div className="flex flex-col">
                <div className="flex flex-col bg-white dark:bg-theme-bgDark py-3.5 pl-3 pr-7 rounded-xl mb-5 h-149">
                    <div className="flex items-center">
                        <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                            Total Content Value
                        </h5>
                        <Info />
                    </div>
                    <h4 className="text-2xl font-medium mt-3 mb-4 dark:text-white">
                        {currencyDisplay(currency)}
                        {filter === 7 && "153,000"}
                        {filter === 15 && "234,342"}
                        {filter === 30 && "453,745"}
                    </h4>
                    <h4 className="text-base font-medium mr-0.5 flex items-center dark:text-white">
                        #7
                        <p className="text-11 ml-0.5 font-normal text-green-500">+2</p>
                    </h4>
                    <p className="text-10 font-medium italic text-silver-light dark:text-silver-light">
                        Ranked on the content Value
                    </p>
                </div>
                <div className="flex flex-col bg-white dark:bg-theme-bgDark py-3.5 pl-3 pr-7 rounded-xl h-149">
                    <div className="flex items-center">
                        <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                            Website Floor Price
                        </h5>
                        <Info />
                    </div>
                    <h4 className="text-2xl font-medium mt-3 mb-4 dark:text-white">
                        {currencyDisplay(currency)}
                        {filter === 7 && "0.6"}
                        {filter === 15 && "3.4"}
                        {filter === 30 && "5.1"}
                    </h4>
                    <h4 className="text-sm font-medium mr-0.5 flex items-center text-red-500">
                        <ArrowDown />
                        <p className="ml-0.5">14.34%</p>
                    </h4>
                    <p className="text-10 font-medium italic text-silver-light dark:text-silver-light">
                        Compared to other publications
                    </p>
                </div>
            </div> */}
        </div>
    );
};

export default ContentValue;
