import React, { useEffect, useState, useRef } from "react";
import Calendar from "react-calendar";

/**
 * Date time picker modal
 * @prop {Function} closeModal - Function to close modal
 * @prop {Function} setScheduleDate - Function to set schedule data
 * @prop {Function} setFilter - Function to set filter date
 */

const DateModal = ({
    closeModal,
    setScheduleDate,
    setFilter,
    setDateValue,
    dateValue,
    reverseString,
}) => {
    const [value, setValue] = useState(dateValue || "");
    // const [error, setError] = useState(false);
    const [selectedDates, setSelectedDates] = useState([]);

    const [error, setError] = useState(false);

    let _date = null;

    if (localStorage.filterDates) {
        _date = JSON.parse(localStorage.filterDates);
    }
    let today = new Date();
    let yesterday = new Date();

    yesterday.setDate(today.getDate() - 1);

    // Function to handle change and throw error is date is not in future
    const handleChange = (e) => {
        let startDate = new Date(e[0]);
        let endDate = new Date(e[1]);

        if (startDate) {
            const Difference_In_Time = endDate.getTime() - startDate.getTime();

            // To calculate the no. of days between two dates
            const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

            if (Difference_In_Days > 30) {
                setError(true);
            } else {
                setError(false);
            }
        }

        if (startDate.getDate() === 1) {
            startDate = `${startDate.getFullYear()}-${
                startDate.getUTCMonth() + 2
            }-${startDate.getDate()}`;
        } else {
            startDate = `${startDate.getFullYear()}-${
                startDate.getUTCMonth() + 1
            }-${startDate.getDate()}`;
        }
        endDate.setDate(endDate.getDate() + 1);
        endDate = `${endDate.getFullYear()}-${endDate.getUTCMonth() + 1}-${endDate.getDate()}`;

        setSelectedDates([startDate, endDate]);
        localStorage.setItem("selectedDates", JSON.stringify(e));
        setValue(e);
    };

    // Function to get quick date
    const setQuickDate = (days) => {
        let todayDate = new Date().getTime();
        let endDate = new Date();
        const timeDelta = 24 * 60 * 60 * 1000 * days; // 1 days
        const futureDay = todayDate - timeDelta;

        let newDate = new Date(futureDay);
        setValue(newDate);
        newDate = `${newDate.getFullYear()}-${newDate.getUTCMonth() + 1}-${newDate.getDate()}`; // setError(false);
        endDate = `${endDate.getFullYear()}-${endDate.getUTCMonth() + 1}-${endDate.getDate()}`; // setError(false);

        localStorage.setItem("filterDates", JSON.stringify([newDate, endDate]));
        localStorage.setItem("selectedDates", JSON.stringify([newDate, endDate]));

        setScheduleDate([newDate, endDate]);
        setFilter(days);
        closeModal();
    };

    const modalRef = useRef();

    const handleSubmit = () => {
        localStorage.setItem("filterDates", JSON.stringify(selectedDates));
        setDateValue(value);
        setScheduleDate(selectedDates);
    };

    // Logic added to remove scroll when modal is open
    useEffect(() => {
        let selectedDates = JSON.parse(localStorage.getItem("selectedDates"));

        if (selectedDates) {
            const d = new Date(selectedDates[0]);
            const d1 = new Date(selectedDates[1]);
            setValue([d, d1]);
        }
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    useEffect(() => {
        const clickOutside = (event) => {
            if (!modalRef.current.contains(event.target)) {
                closeModal();
            }
        };
        document.addEventListener("mousedown", clickOutside);
        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                width: "100%",
                height: "100%",
                transform: " translate(-50%, -50%)",
                zIndex: 1000,
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                overflowY: "auto",
            }}
        >
            <div
                className="bg-theme-gray dark:bg-black rounded-2xl px-18 py-4"
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "100%",
                    maxWidth: "426px",
                    transform: " translate(-50%, -50%)",
                }}
                ref={modalRef}
            >
                <h1 className="text-13 font-semibold mb-2  text-center dark:text-silver-lighter">
                    Generate Report
                </h1>
                <p className="text-xs font-normal text-gray-500 text-center dark:text-silver-medium mb-1.5">
                    Select the time period for which you want to filter your report.
                </p>
                {error && (
                    <div className="bg-red-100 text-red-800 mx-8 px-2 text-sm rounded">
                        <p>Cannnot select more than 30 day at one time.</p>
                    </div>
                )}

                <div className="mb-5 p-6 rounded-2xl bg-white dark:bg-theme-bgDark mx-6">
                    <div className="border-b border-cyan-700 dark:border-cyan-400 mb-6 pb-6">
                        <h6 className="text-10 font-normal text-gray-600 mb-4 text-center dark:text-silver-medium">
                            Quick Selection
                        </h6>
                        <div className="flex items-center justify-around">
                            <button
                                onClick={() => setQuickDate(7)}
                                className="text-11 font-normal text-gray-600 border
                            border-gray-200 py-2 px-4 rounded-2xl cursor-pointer dark:text-silver-medium
                             hover:bg-cyan-700 hover:text-white dark:hover:border-cyan-400 dark:hover:text-cyan-400 dark:hover:bg-black"
                            >
                                Last 7 days
                            </button>
                            {/* <button
                                onClick={() => setQuickDate(15)}
                                className="text-11 font-normal text-gray-600 border
                            border-gray-200 py-2 px-4 rounded-2xl cursor-pointer dark:text-silver-medium  hover:bg-cyan-700 hover:text-white dark:hover:border-cyan-400 dark:hover:text-cyan-400 dark:hover:bg-black"
                            >
                                Last 15 days
                            </button>
                            <button
                                onClick={() => setQuickDate(30)}
                                className="text-11 font-normal text-gray-600 border
                            border-gray-200 py-2 px-4 rounded-2xl cursor-pointer dark:text-silver-medium  hover:bg-cyan-700 hover:text-white dark:hover:border-cyan-400 dark:hover:text-cyan-400 dark:hover:bg-black"
                            >
                                Last 1 month
                            </button> */}
                        </div>
                    </div>
                    <div className="bg-white dark:bg-theme-bgDark">
                        <Calendar
                            minDate={new Date(2022, 8, 15)}
                            selectRange={true}
                            maxDate={yesterday}
                            onChange={(e) => handleChange(e)}
                            value={value}
                            tileClassName="single-tile"
                            className={
                                localStorage.theme === "light" ? "calender-light" : "calender-dark"
                            }
                        />
                    </div>
                </div>

                {_date && (
                    <div className="flex items-center justify-center -mt-3 mb-4 text-xs dark:text-white">
                        {reverseString(_date[0], false)?.split("-")?.join("/")} -{" "}
                        {reverseString(_date[1], true)?.split("-")?.join("/")}
                    </div>
                )}
                <div className="w-full flex items-center justify-center">
                    <button
                        disabled={error}
                        className={
                            error
                                ? "dark:text-black cursor-not-allowed text-11 font-semibold rounded-lg bg-cyan-700 text-white dark:bg-cyan-400 px-7 py-1.5"
                                : "dark:text-black text-11 font-semibold rounded-lg bg-cyan-700 text-white dark:bg-cyan-400 px-7 py-1.5"
                        }
                        onClick={() => handleSubmit()}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DateModal;
