import { useEffect, useRef, useState } from "react";

// import Emitter from "../event";

import SvgIcons from "./svgIcons";

import "./popupStyle.css";

let options = {
    theme: "dark",
    demand: true,
    popup: true,
    currency: "inr",
};

const Component = ({ url, idKey, price }) => {
    const [articlePrice, setArticlePrice] = useState(price);
    const [priceDirection, setPriceDirection] = useState(Math.random() < 0.3 ? "down" : "up");
    const [showModal, setShowModal] = useState(false);
    const [popupLeft, setPopupLeft] = useState(0);
    const [animationDirection, setAnimationDirection] = useState("");
    const [popupTop, setPopupTop] = useState(0);
    const [rerender, setRerender] = useState(false);
    const [rippleColor, setRipple] = useState("");

    const handlePriceChange = (price) => {
        const currentPrice = articlePrice;
        const newPrice = price;
        if (newPrice) {
            if (currentPrice !== newPrice) {
                setArticlePrice(newPrice);
                if (currentPrice < newPrice) {
                    setPriceDirection("up");
                    setAnimationDirection("up");
                } else {
                    setPriceDirection("down");
                    setAnimationDirection("down");
                }
                setTimeout(() => {
                    setAnimationDirection("");
                }, 800);
            }
        }
    };

    useEffect(() => {
        handlePriceChange(price);
        // Emitter.on("prices", handlePriceChangeWrapper);
        return () => {
            // Emitter.off("prices", handlePriceChangeWrapper);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [articlePrice, price]);

    //Fucntion to add class to price
    const priceClass = () => {
        let _class = "qx-widget-article-price";
        if (animationDirection === "up") {
            _class += " qx-widget-priceUp";
        } else if (animationDirection === "down") {
            _class += " qx-widget-priceDown";
        }

        // to add class based on price direction
        if (priceDirection === "up") {
            _class += " qx-heading-priceup";
        } else if (priceDirection === "down") {
            _class += " qx-heading-pricedown";
        }

        return _class;
    };

    const onMouseEnter = () => {
        setShowModal(true);
        // set timestamp
        // lastEntered.current = new Date().getTime();
    };

    const onMouseLeave = (e) => {
        setRerender(true);
        if (!document.getElementById(`qx-widget-${idKey}`).contains(e.relatedTarget)) {
            setShowModal(false);
        }
        if (!document.getElementById(`qx-widget-${idKey}`).contains(e.relatedTarget)) {
            const priceContainers = document.querySelectorAll("[qontento-widget]");
            for (let i = 0; i < priceContainers.length; i++) {
                if (priceContainers[i].contains(e.relatedTarget)) {
                    setRerender(false);
                    break;
                }
            }
        }
    };

    useEffect(() => {
        const elm = document.getElementById(`qx-widget-${idKey}`);
        elm.addEventListener("mouseenter", onMouseEnter);
        return () => {
            elm.removeEventListener("mouseenter", onMouseEnter);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const elm = document.getElementById(`qx-widget-${idKey}`);

        elm.addEventListener("mouseleave", onMouseLeave);

        return () => {
            elm.removeEventListener("mouseleave", onMouseLeave);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    useEffect(() => {
        setTimeout(() => {
            const articlePriceH5 = document
                ?.getElementById(`qx-widget-${idKey}`)
                ?.querySelector("h5");
        }, 100);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    useEffect(() => {
        setRipple(priceDirection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let _articlePrice = `${articlePrice}`.slice(0, `${articlePrice}`.indexOf(".") + 3);

    return (
        <div id={`qx-widget-${idKey}`} style={{ position: "relative" }}>
            <span className={`${rippleColor} ripple`}>
                <h5 className={priceClass()}>
                    {options.currency === "inr" && "₹"}
                    {options.currency === "usd" && "$"}
                    {_articlePrice}
                    <SvgIcons priceDirection={priceDirection} />
                </h5>
            </span>
        </div>
    );
};

export default Component;
