import React from "react";

import Header from "components/shared/Header/index";
import Footer from "components/shared/Footer";

import bg from "assests/images/bg2.png";
import { Link } from "react-router-dom";

const Disclaimers = () => {
  return (
    <div
      className="flex flex-1 min-h-full w-full flex-col text-white"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
      }}
    >
      <Header />
      <div className="flex flex-1 flex-col items-center  my-20">
        <h1 className="mb-2 text-2xl font-bold lg:text-5xl text-blue2">
          Disclaimers
        </h1>
        <p className="mb-12 text-center text-sm text-gray-300 w-full max-w-505">
          (Last Updated on 15/09/2023)
        </p>
        <div className="w-full text-base max-w-800 text-gray2 mb-14 px-4 lg:px-0">
          <h6 className="font-semibold mb-4 text-gray-200">
            Welcome to Kunato!
          </h6>
          <div>
            <p className="mb-4">
              By using Kunato, you're acknowledging and accepting these
              disclaimers. Please also review our{" "}
              <Link className="text-blue2" to="/terms">
                Terms of Service{" "}
              </Link>{" "}
              , Content Policy and{" "}
              <Link className="text-blue2" to="/privacy">
                Privacy Policy{" "}
              </Link>{" "}
              , as they are crucial components of the overall understanding and
              acceptance of our terms.
            </p>
            <p className="mb-4">
              <p className="font-bold mb-1 text-gray-300">
                Acknowledgment for Publishers and Users
              </p>
              When you use Kunato products and services like Search or Value,
              Forecast, Paywindow, Publisher Dashboard, and more, you
              acknowledge that we don't publish content; publishers and
              advertisers are solely responsible for ensuring their content
              complies with laws, Terms, and Kunato guidelines. Kunato prices
              content posted by publishers on their platforms, but does not
              verify the accuracy or authenticity of such content. You're aware
              that we use a proprietary, AI-driven pricing system, and while we
              aim for accuracy and fairness, no system can guarantee perfect
              precision.
            </p>
            <p className="mb-4">
              When you use Kunato products and services like Search or Value,
              Forecast, Paywindow, Publisher Dashboard, and more, you
              acknowledge that we don't publish content; publishers and
              advertisers are solely responsible for ensuring their content
              complies with laws, Terms, and Kunato guidelines. Kunato prices
              content posted by publishers on their platforms, but does not
              verify the accuracy or authenticity of such content. You're aware
              that we use a proprietary, AI-driven pricing system, and while we
              aim for accuracy and fairness, no system can guarantee perfect
              precision.
            </p>
            <p className="mb-4 font-bold">
              <p className="font-bold mb-1 text-gray-300">
                No Warranty and Liability Limitations
              </p>
              KUNATO'S PRODUCTS ARE PROVIDED "AS IS" AND "AS AVAILABLE," WITHOUT
              WARRANTIES, REPRESENTATIONS, OR CONDITIONS OF ANY KIND, EXPRESS OR
              IMPLIED, LEGAL OR STATUTORY. THIS MEANS NO GUARANTEES OF
              MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, OR
              NON-INFRINGEMENT. KUNATO DOESN'T WARRANT THAT THE PRODUCTS ARE
              ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR-FREE. WE DON'T
              CONTROL, ENDORSE, OR TAKE RESPONSIBILITY FOR CONTENT ON OR LINKED
              TO OUR PRODUCTS, OR THE ACTIONS OF ANY THIRD PARTY OR USER,
              INCLUDING MODERATORS. WHILE WE DO OUR BEST TO MAKE ACCESS TO AND
              USE OF OUR PRODUCTS SAFE, WE CAN'T PROMISE THAT OUR PRODUCTS OR
              SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
            </p>

            <p className="mb-4 font-bold">
              KUNATO WON'T BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY,
              INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, OR LOST PROFITS ARISING
              FROM OR RELATING TO THESE TERMS OR THE PRODUCTS, INCLUDING
              ALLEGATIONS OF DEFAMATORY, OFFENSIVE, OR ILLEGAL CONTENT. USING
              OUR PRODUCTS IS AT YOUR OWN DISCRETION AND RISK, AND YOU'RE
              RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR
              ANY LOSS OF DATA THAT MAY RESULT. IN NO EVENT WILL KUNATO’S
              LIABILITY EXCEED THE GREATER OF $100 OR ANY AMOUNT YOU PAID KUNATO
              IN THE PREVIOUS SIX MONTHS FOR THE PRODUCTS GIVING RISE TO THE
              CLAIM. THIS SECTION APPLIES TO ANY THEORY OF LIABILITY, INCLUDING
              WARRANTY, CONTRACT, STATUTE, TORT, NEGLIGENCE, STRICT LIABILITY,
              AND MORE, EVEN IF KUNATO ENTITIES HAVE BEEN ADVISED OF THE
              POSSIBILITY OF DAMAGE AND EVEN IF ANY REMEDY IN THESE TERMS FAILS
              ITS ESSENTIAL PURPOSE.
            </p>

            <p className="mb-4">
              <p className="font-bold mb-1 text-gray-300">
                Quantitative Market Value Price
              </p>
              Kunato uses AI-driven Quantitative Market Value (QMV) system. The
              QMV price will always show up on our products. Publishers can set
              their own content prices, and if they do, those prices will be
              displayed next to Kunato's QMV price. While we strive for
              accuracy, we cannot guarantee the precision of the QMV system or
              the correctness of publisher-assigned prices. Users and publishers
              are responsible for exercising their own judgment when consuming
              or publishing content.
            </p>
            <p className="mb-4">
              <p className="font-bold mb-1 text-gray-300">
                Transparency Features
              </p>
              We may place pre-access icons, warnings, and publisher ratings to
              foster transparency and encourage responsible content consumption
              and creation. But remember, we can't guarantee their accuracy, nor
              will we be held responsible for any consequences stemming from
              their presence or absence and use or non-use as it is the
              publisher’s sole responsibility.
            </p>

            <p className="mb-4">
              <p className="font-bold mb-1 text-gray-300">
                Content Accuracy and Completeness
              </p>
              Kunato's products and services aim to provide useful information
              to users, but we cannot guarantee the accuracy, completeness, or
              reliability of any content. Users, publishers, and advertisers are
              responsible for verifying the accuracy and completeness of the
              content they consume or publish. We disclaim any liability for any
              consequences arising from the use or publication of inaccurate or
              incomplete content.
            </p>
            <p className="mb-4">
              <p className="font-bold mb-1 text-gray-300">
                No Business or Professional Advice
              </p>
              Please note that the information, articles, and content provided
              by publishers and priced by Kunato, as well as Kunato products and
              services, are not intended as, and should not be construed as,
              business, financial, legal, or professional advice. Users are
              advised to consult with qualified professionals for any specific
              concerns. The same applies for content provided by the
              advertisers.
            </p>

            <p className="mb-4">
              <p className="font-bold mb-1 text-gray-300">
                No Solicitation to Purchase or Invest
              </p>
              Content priced by Kunato is not intended as an offer or
              solicitation to buy or invest in any products, services, or
              securities. Users and publishers have the discretion to decide
              what content to consume or publish, and should conduct their own
              assessment before making any decisions.
            </p>
            <p className="mb-4">
              <p className="font-bold mb-1 text-gray-300">
                Not for Trading, Illegal Gambling, or Other Illicit Purposes
              </p>
              Kunato's platform, products and services are not intended for
              trading, illegal gambling, or any other unlawful activities. Users
              are advised to comply with all applicable laws and regulations
              when using the platform and consuming its content. Kunato
              disclaims any liability for any consequences arising from the use
              of its products or publisher’s content for such purposes.
            </p>
            <p className="mb-4">
              <p className="font-bold mb-1 text-gray-300">
                Deepfakes and Misinformation
              </p>
              Kunato is committed to fostering an environment free of deepfakes,
              misinformation, and inaccuracies within its products and services.
              However, we cannot guarantee that all content published by content
              creators in their respective platforms and websites is free from
              such issues. Users, publishers, and advertisers are encouraged to
              report any suspected deepfakes or misleading information. We
              disclaim any liability for any consequences arising from the use
              or publication of such content.
            </p>
            <p className="mb-4">
              <p className="font-bold mb-1 text-gray-300">
                Hate Speech and Discrimination
              </p>
              Kunato does not condone or tolerate hate speech, discrimination,
              or any content promoting violence or harm based on race,
              ethnicity, religion, gender, sexual orientation, or any other
              protected characteristic. Users, publishers, and advertisers are
              responsible for ensuring that their content and interactions with
              our products and services comply with applicable laws and our
              community guidelines. We disclaim any liability for any
              consequences arising from the use or publication of content that
              violates these principles.
            </p>
            <p className="mb-4">
              <p className="font-bold mb-1 text-gray-300">
                Intellectual Property Rights
              </p>
              Users, publishers, and advertisers must respect the intellectual
              property rights of others when using our products and services.
              Uploading, posting, or sharing content that infringes on any
              copyright, trademark, patent, or other proprietary rights is
              strictly prohibited. Kunato disclaims any liability for any
              consequences arising from the use or publication of content that
              violates intellectual property rights.
            </p>
            <p className="mb-4">
              <p className="font-bold mb-1 text-gray-300">
                Privacy and Personal Information
              </p>
              Kunato is committed to protecting the privacy of its users.
              However, users, publishers, and advertisers are responsible for
              ensuring that they do not share personal or sensitive information
              about themselves or others without proper consent. We disclaim any
              liability for any consequences arising from the disclosure or use
              of personal information through our products and services.
            </p>
            <p className="mb-4">
              <p className="font-bold mb-1 text-gray-300">
                Third-Party Links and Resources
              </p>
              Kunato's products and services may contain links to third-party
              websites or resources. We do not endorse, control, or assume
              responsibility for the content, privacy policies, or practices of
              any third-party websites or resources. Users, publishers, and
              advertisers access and use such websites or resources at their own
              risk, and we disclaim any liability for any consequences arising
              from their use.
            </p>
            <p className="mb-4">
              <p className="font-bold mb-1 text-gray-300">
                System Availability and Interruptions
              </p>
              Kunato strives to maintain the availability and performance of its
              products and services. However, we cannot guarantee that they will
              always be available, error-free, or uninterrupted. We disclaim any
              liability for any consequences arising from system outages,
              interruptions, or performance issues
            </p>
            <p className="mb-4">
              <p className="font-bold mb-1 text-gray-300">
                Account Termination
              </p>
              Kunato reserves the right to stop valuation of content that
              violates our policies, guidelines, or applicable laws at our sole
              discretion. We also reserve the right to terminate or suspend
              user, publisher and advertiser accounts for any reason, including
              but not limited to, violations of our policies, guidelines, or
              applicable laws. We disclaim any liability for any consequences
              arising from these instances.
            </p>
            <p className="mb-4">
              <p className="font-bold mb-1 text-gray-300">
                {" "}
                Changes to Products and Services
              </p>
              Kunato may, at its sole discretion, update, modify, or discontinue
              any of its products and services or any features thereof, without
              prior notice. We disclaim any liability for any consequences
              arising from such changes.
            </p>
            <p className="mb-4">
              <p className="font-bold mb-1 text-gray-300"> Indemnification</p>
              By using Kunato's products and services, you agree to indemnify,
              defend, and hold harmless Kunato and its affiliates, officers,
              directors, employees, and agents from and against any and all
              claims, liabilities, damages, losses, or expenses, including
              reasonable attorneys' fees and costs, arising out of or in any way
              connected with your access to, use of, or access and publication
              of content through our products and services.
            </p>
            <p className="mb-4">
              <p className="font-bold mb-1 text-gray-300">
                {" "}
                Statutory Rights and Applicable Law
              </p>
              To the fullest extent permitted by applicable law, any claims
              arising out of or relating to Kunato Products and Services,
              including those stated under Disclaimers, Terms of Service, and
              Privacy Policy, will be governed by the laws of the State of New
              York, without regard to conflict of laws rules; all disputes
              related to these Terms or the Products will be brought solely in
              the federal or state courts located in New York, United States,
              and you and Kunato consent to personal jurisdiction in these
              courts. In certain jurisdictions, some exclusions or limitations
              may not apply, which means some terms here might not be entirely
              applicable to you. In those cases, exclusions and limitations will
              be valid only to the extent allowed by the laws governing those
              jurisdictions
            </p>

            <p className="mb-4">
              By using Kunato's products and services, you acknowledge and agree
              to these disclaimers and assume full responsibility for your
              actions and decisions related to the consumption or publication of
              content. If you have questions about these disclaimers, please
              contact us{" "}
              <a
                href="mailto:contact@qx.live"
                className="text-blue2 cursor-pointer"
              >
                support@kunato.io{" "}
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Disclaimers;
