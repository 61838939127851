import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import MobileSidebar from "./MobileSidebar";

/**
 * Currency dropdown
 * @prop {Function} closeModal - Function to close modal
 */

const MobileUserModal = ({ closeModal, userInfo, name, editModal, tab, dateObj }) => {
    const modalRef = useRef();

    const history = useNavigate();

    const handleLogout = () => {
        localStorage.clear();
        history("/login");
        window.location.reload();
    };

    useEffect(() => {
        const clickOutside = (event) => {
            if (!modalRef.current.contains(event.target)) {
                closeModal();
            }
        };
        document.addEventListener("mousedown", clickOutside);
        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className=" bg-newWhite-op4 dark:bg-newBlack-op4"
            style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                width: "100%",
                height: "100%",
                transform: " translate(-50%, -50%)",
                zIndex: 1000,
                overflowY: "auto",
                outline: "none",
            }}
        >
            <div
                ref={modalRef}
                className="bg-white dark:bg-theme-bgDark shadow-md z-50 pt-4 pb-3 rounded-md r-0 w-11/12 max-w-335 overflow-y-auto"
                style={{
                    position: "absolute",
                    top: "1px",
                    right: 0,
                }}
            >
                <div className="dark:text-white flex flex-col items-center justify-center w-full">
                    <div className="flex items-center">
                        <h6 className="rounded-full mb-2 h-14 w-14 border text-xl font-medium flex items-center justify-center text-gray-600 dark:text-gray-400 dark:border-gray-400 mr-5">
                            {name}
                        </h6>
                        <div>
                            {/* <img src={newsPub} alt="thumbnail" className="rounded-full h-12 w-12 mr-2 border" /> */}
                            <h4 className="text-xl font-medium">{userInfo.name}</h4>
                            <p className="text-15 font-light mb-3">{userInfo.email}</p>
                        </div>
                    </div>
                    <button
                        onClick={() => editModal()}
                        className="text-15 text-light-blue dark:text-dark-Blue pb-5 mb-5 border-b border-border-borderGray px-28"
                    >
                        Edit Profile
                    </button>

                    <MobileSidebar tab={tab} />

                    {/* <div className="text-xs text-silver-light dark:text-silver-dark mt-6 mb-5 pb-5 border-b border-border-borderGray px-12 text-center">
                        Last updated: {dateObj}
                    </div> */}

                    <button
                        onClick={() => handleLogout()}
                        className="text-base font-semibold dark:text-light-red text-graph-redLight pb-2"
                    >
                        Sign Out
                    </button>
                </div>
            </div>{" "}
        </div>
    );
};

export default MobileUserModal;
