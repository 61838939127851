import { articlePriceAPI } from "../utils";

class APIService {
    // Function to get prices of the url
    getPrices = async (currency = "inr", url) => {
        // const prices = {};

        const response = await fetch(articlePriceAPI, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // /make sure to serialize your JSON body
            body: JSON.stringify({
                currency: currency,
                urls: [url],
            }),
        });

        // Storing data in form of JSON
        let data = await response.json();
        let articlePrice = Object.values(data)[0]; // someVal
        return articlePrice;
    };
}

const Services = new APIService();

export default Services;
