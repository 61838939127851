import React from "react";
import { Link } from "react-router-dom";
import NewKlogo from "../icons/NewKlogo";

import "./style.scss";

const ScrollHeader = () => {
    return (
        <div className="flex w-full items-center justify-between py-3 px-4 lg:py-3 lg:px-16 text-2xl font-bold bg-blue8">
            <div>
                <Link to="/">
                    <h2>
                        <NewKlogo />
                    </h2>
                </Link>
            </div>
            <button
                onClick={() => window.scrollTo({ top: 20, behavior: "smooth" })}
                className="get-started-button bg-blue8 border border-blue2 text-base font-bold rounded-lg px-5 py-2 hover:text-blue7 hover:bg-blue2"
            >
                Get Started
            </button>
        </div>
    );
};

export default ScrollHeader;
