import React from "react";
// import { Link } from "react-router-dom";
import NewKlogo from "../icons/NewKlogo";

// import BarIcon from "components/shared/icons/BarIcon";
// import MobileModal from "./MobileModal";

const Header = () => {
    // const [modal, setModal] = useState(false);
    return (
        <div className="flex w-full items-center justify-between py-6 px-4 lg:py-10 lg:px-16 text-2xl font-bold bg-black">
            <div>
                <a href="/">
                    <h2>
                        <NewKlogo />
                    </h2>
                </a>
            </div>
            {/* <div className="hidden lg:flex items-center list-none font-bold text-base text-gray-200">
                <li className="pl-12">
                    <a href="https://kunato.ai/" target="_blank" rel="noopener noreferrer">
                        Kunato.Ai
                    </a>
                </li>
            </div>
            <div className="lg:hidden cursor-pointer" onClick={() => setModal(!modal)}>
                <BarIcon />
            </div>
            {modal && <MobileModal closeModal={() => setModal(false)} />} */}
        </div>
    );
};

export default Header;
