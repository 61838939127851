import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import BarGraph from "./icons/BarGraph";
import Chip from "./icons/Chip";
import Reload from "./icons/Reload";
import Users from "./icons/Users";
import Location from "./icons/Location";
// import Tool from "./icons/Tool";
import Star from "./icons/Star";
import jwtDecode from "jwt-decode";

const Sidebar = () => {
    const [tab, setTab] = useState("");

    let accessToken = localStorage.getItem("accessToken");
    let user;
    if (accessToken) {
        user = jwtDecode(accessToken);
    }

    const location = useLocation();
    const navigate = useNavigate();

    const handleTab = (_tab) => {
        localStorage.removeItem("filterDates");
        localStorage.removeItem("selectedDates");
        setTab(_tab);
        navigate(`/${_tab}`);
    };

    const setClass = (_tab) => {
        let _class =
            "flex h-full items-center px-6 pt-4 pb-3 text-xs font-bold text-gray-700 dark:text-white cursor-pointer bg-white dark:bg-black";

        if (tab === _tab) {
            _class =
                "flex h-full items-center pb-3 px-6 py-4 text-xs font-bold mb-0.5 cursor-pointer bg-theme-blue text-white";
        }

        return _class;
    };

    useEffect(() => {
        const path = location.pathname.split("/")[1];
        setTab(path);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className="bg-white dark:bg-black min-h-full
         hidden lg:flex flex-col flex-1 w-full items-center justify-between mr-5 2xl:mr-0 max-w-169 overflow-y-auto"
        >
            <div className="list-none flex flex-col w-full">
                <li className={setClass("overview")} onClick={() => handleTab("overview")}>
                    <BarGraph color={tab === "overview" && "#fff"} />
                    <h6 className="ml-4">Overview</h6>
                </li>

                <li className={setClass("ai")} onClick={() => handleTab("ai")}>
                    <Chip color={tab === "ai" && "#fff"} />
                    <h6 className="ml-4">AI Data</h6>
                </li>

                {user?.meta?.betaAccess === 1 && (
                    <li
                        className={setClass("conversions")}
                        onClick={() => handleTab("conversions")}
                    >
                        <Reload color={tab === "conversions" && "#fff"} />
                        <div className="ml-4">
                            <h6>Conversions</h6>
                        </div>
                    </li>
                )}
                {user?.meta?.betaAccess === 1 && (
                    <li className={setClass("paywindow")} onClick={() => handleTab("paywindow")}>
                        <p style={{ marginLeft: "3px" }}>
                            <Star color={"#fff"} />
                        </p>
                        <div className="flex flex-col ml-4">
                            <h6>Paywindow</h6>
                        </div>
                    </li>
                )}
                {!(user?.meta?.betaAccess === 1) && (
                    <li className="flex px-6 pt-4 pb-3 text-xs font-bold text-silver-light dark:text-gray-600 bg-gray-100 dark:bg-theme-bgDark cursor-not-allowed">
                        <Reload color="#646464" />
                        <div className="flex flex-col ml-4">
                            <h6>Conversions</h6>
                            <p className="text-9 font-light text-light-blue">(Coming soon)</p>
                        </div>
                    </li>
                )}

                <li className="flex px-6 pt-4 pb-3 text-xs font-bold text-silver-light dark:text-gray-600 bg-gray-100 dark:bg-theme-bgDark cursor-not-allowed">
                    <Users />
                    <div className="flex flex-col ml-4">
                        <h6>Users</h6>
                        <p className="text-9 font-light text-light-blue">(Coming soon)</p>
                    </div>
                </li>
                <li className="flex px-6 pt-4 pb-3 text-xs font-bold text-silver-light dark:text-gray-600 bg-gray-100 dark:bg-theme-bgDark cursor-not-allowed">
                    <Location />
                    <div className="flex flex-col ml-4">
                        <h6>Heatmaps</h6>
                        <p className="text-9 font-light text-light-blue">(Coming soon)</p>
                    </div>
                </li>

                <li className="flex px-6 pt-4 pb-3 text-xs font-bold text-silver-light dark:text-gray-600 bg-gray-100 dark:bg-theme-bgDark cursor-not-allowed">
                    <p style={{ marginLeft: "3px" }}>
                        <Star />
                    </p>
                    <div className="flex flex-col ml-4">
                        <h6>Premium</h6>
                        <p className="text-9 font-light text-light-blue">(Coming soon)</p>
                    </div>
                </li>
            </div>

            {/* <div className="text-xs font-bold text-gray-700 dark:text-gray-700 border-b border-gray-700 flex items-center justify-between mx-6 pb-6 mb-1.5">
                    <Tool />
                    Graph Settings
                </div> */}
            {/* <div className="text-center mb-3">
                    <h6 className="text-13 font-bold text-blue-900">Access Valid</h6>
                    <div className="flex items-center justify-between px-5">
                        <h6 className="text-11 font-bold text-gray-400 dark:text-silver-light">
                            Start Date
                        </h6>
                        <p className="text-11 font-light text-gray-500 dark:text-gray-400">
                            01/01/2022
                        </p>
                    </div>
                    <div className="flex items-center justify-between px-5 mb-0.5">
                        <h6 className="text-11 font-bold text-gray-400 dark:text-silver-light">
                            End Date
                        </h6>
                        <p className="text-11 font-light text-gray-500 dark:text-gray-400">
                            01/01/2023
                        </p>
                    </div>
                    <div className="flex items-center justify-start px-5">
                        <h6 className="text-11 font-bold text-gray-400 dark:text-silver-light mr-2">
                            Active Days
                        </h6>
                        <p className="text-11 font-light text-gray-500 dark:text-white">64</p>
                    </div>
                </div> */}
        </div>
    );
};

export default Sidebar;
