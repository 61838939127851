import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import BarGraph from "components/shared/icons/BarGraph";
import Chip from "components/shared/icons/Chip";
import Reload from "components/shared/icons/Reload";
import Users from "components/shared/icons/Users";
import Location from "components/shared/icons/Location";
// import Tool from "./icons/Tool";
import Star from "components/shared/icons/Star";
import ThemeToggle from "components/shared/ThemeToggle";
import Theme from "components/shared/icons/Theme";

const MobileSidebar = () => {
    const [tab, setTab] = useState("");

    const location = useLocation();

    const setClass = (_tab) => {
        let _class =
            "flex h-full items-center px-6 pt-4 pb-3 text-base font-bold text-gray-700 dark:text-white cursor-pointer bg-white dark:bg-theme-bgDark";

        if (tab === _tab) {
            _class =
                "flex h-full items-center pb-3 px-6 py-4 text-base font-bold mb-0.5 cursor-pointer bg-theme-blue text-white";
        }

        return _class;
    };

    useEffect(() => {
        const path = location.pathname.split("/")[1];
        setTab(path);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className="bg-white dark:bg-black xl:min-h-full
        flex flex-col flex-1 w-full items-center justify-center xl:justify-between 2xl:mr-0 xl:max-w-169 max-w-full overflow-y-auto"
        >
            <div className="list-none flex flex-col w-full">
                <Link to="/overview">
                    <li className={setClass("overview")}>
                        <BarGraph color={tab === "overview" && "#fff"} width={"20"} height={"20"} />
                        <h6 className="ml-9">Overview</h6>
                    </li>
                </Link>
                <Link to="/ai">
                    <li className={setClass("ai")}>
                        <p className="mt-1">
                            <Chip color={tab === "ai" && "#fff"} width={"20"} height={"20"} />
                        </p>
                        <h6 className="ml-9">AI Data</h6>
                    </li>
                </Link>
                {/* <Link to="/conversions">
                    <li className={setClass("conversions")}>
                        <p className="mt-1">
                            <Reload
                                width={"20"}
                                height={"20"}
                                color={tab === "conversions" && "#fff"}
                            />
                        </p>
                        <h6 className="ml-9">Conversions</h6>
                    </li>
                </Link> */}

                <li className="flex min-w-130 px-6 pt-4 pb-3 text-base font-bold text-silver-dark dark:text-silver-dark bg-gray-100 dark:bg-theme-bgDark cursor-not-allowed">
                    <p className="mt-1">
                        <Reload width={"20"} height={"20"} color={"#646464"} />
                    </p>
                    <div className="flex flex-col ml-9">
                        <h6>Conversions</h6>
                        <p className="text-9 font-light text-light-blue">(Coming soon)</p>
                    </div>
                </li>
                <li className="flex min-w-130 px-6 pt-4 pb-3 text-base font-bold text-silver-dark dark:text-silver-dark bg-gray-100 dark:bg-theme-bgDark cursor-not-allowed">
                    <p className="mt-1">
                        <Users width={"20"} height={"20"} />
                    </p>
                    <div className="flex flex-col ml-9">
                        <h6>Users</h6>
                        <p className="text-9 font-light text-light-blue">(Coming soon)</p>
                    </div>
                </li>
                <li className="flex min-w-130 px-6 pt-4 pb-3 text-base font-bold text-silver-dark dark:text-silver-dark bg-gray-100 dark:bg-theme-bgDark cursor-not-allowed">
                    <p className="mt-1">
                        <Location width={"20"} height={"20"} />
                    </p>
                    <div className="flex flex-col ml-9">
                        <h6>Heatmaps</h6>
                        <p className="text-9 font-light text-light-blue">(Coming soon)</p>
                    </div>
                </li>
                <li className="flex min-w-130 px-6 pt-4 pb-3 text-base font-bold text-silver-dark dark:text-silver-dark bg-gray-100 dark:bg-theme-bgDark cursor-not-allowed">
                    <p style={{ marginLeft: "3px" }}>
                        <p className="mt-1">
                            <Star width={"20"} height={"20"} />
                        </p>
                    </p>
                    <div className="flex flex-col ml-9">
                        <h6>Premium</h6>
                        <p className="text-9 font-light text-light-blue">(Coming soon)</p>
                    </div>
                </li>
                <li className="flex items-center justify-between px-6 py-7">
                    <h4 className="flex items-center justify-between">
                        <Theme />
                        <p className="ml-9 font-bold text-silver-dark dark:text-silver-dark">
                            Theme
                        </p>
                    </h4>
                    <div className="flex flex-col">
                        <ThemeToggle />
                    </div>
                </li>
            </div>
        </div>
    );
};

export default MobileSidebar;
