import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import jwtDecode from "jwt-decode";

import Header from "components/shared/Header";
import Sidebar from "components/shared/Sidebar";
import Filters from "components/Overview/Filters";

import BuyingPower from "./BuyingPower";
import ArticleInteractions from "./Interactions";
import LineGraphConversion from "./LineGraphConversion";
import ClickedValue from "./ClickedValue";
import AverageBarChart from "./AverageBuyingPower";

import { getConverions } from "components/shared/utils";
import customFetch from "components/shared/customFetch";
import Spinner from "components/shared/spinner";

const Conversion = () => {
    const currentCurrency = localStorage.getItem("currentCurrency");

    const [filter, setFilter] = useState(7);
    const [currency, setCurrency] = useState(currentCurrency || "inr");
    const [currencyRate, setCurrencyRate] = useState("0.012849");
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(false);
    const [cacheTime, setCacheTime] = useState("");
    const [date, setDate] = useState([]);

    let storage = localStorage;

    let userInfo;
    let name;

    if (localStorage?.accessToken) {
        userInfo = jwtDecode(localStorage?.accessToken);

        name = userInfo?.name
            .split(" ")
            .map((n) => n[0])
            .join("");

        name = name.substring(0, 3);
    }

    // mix panel
    mixpanel.time_event("Conversions page - page_view_exit");

    // mix panel
    if (userInfo) {
        mixpanel.track("PageView", {
            view: "Conversions",
            username: name,
            email: userInfo?.email,
        });
    } else {
        mixpanel.track("PageView", {
            view: "Conversions",
        });
    }

    setInterval(() => {
        mixpanel.track("PagePoll", {
            pollTime: 5,
            view: "Conversions",
        });
    }, 5000);

    useEffect(() => {
        if (storage.theme === "dark") {
            document.body.style.backgroundColor = "#000";
        } else {
            document.body.style.backgroundColor = "#EEE";
        }
    }, [storage.theme]);

    // Function to convert currency
    const currencyConverter = async () => {
        const response = await fetch(
            `https://a2.qx.live/currency-conversion?base=${currency}&to=usd`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        // Storing data in form of JSON
        const data = await response.json();

        if (data.ok) {
            localStorage.setItem("currentCurrency", currency);
            setCurrencyRate(data.rate);
        }
    };

    // Function to get data points for primary article
    const getData = async () => {
        let _date = date;
        if (localStorage.filterDates) {
            _date = JSON.parse(localStorage.filterDates);
        }
        let url =
            _date.length > 0
                ? getConverions + `?&startDate=${_date[0]}&endDate=${_date[1]}`
                : getConverions;
        const response = await customFetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        // Storing data in form of JSON
        const data = await response.json();
        if (data.ok) {
            setData(data.data.conversions);
            setCacheTime(data.data.createdAt);
        }
        setLoading(false);
    };

    // console.log("converisons data is", data);

    useEffect(() => {
        setLoading(true);
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    useEffect(() => {
        currencyConverter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currency]);

    if (!storage.accessToken) return <Navigate to="/login" />;

    return (
        <div className="bg-theme-gray dark:bg-black flex flex-1 flex-col min-h-screen min-w-full">
            <Header cacheTime={cacheTime} />
            <div className="flex flex-1 flex-row">
                <Sidebar />
                {loading ? (
                    <div className="flex flex-1 items-center justify-center w-full min-h-full">
                        <Spinner />
                    </div>
                ) : (
                    <div className="flex flex-1 flex-col items-center w-full">
                        <Filters
                            setFilter={setFilter}
                            cacheTime={cacheTime}
                            currency={currency}
                            setCurrency={setCurrency}
                            date={date}
                            setDate={setDate}
                        />
                        <div className="flex w-full flex-col xl:flex-row items-center 2xl:justify-center mb-6 md:mb-0">
                            <div
                                className="flex flex-col w-11/12 md:w-full items-start mb-4 xl:mb-0"
                                style={{ maxWidth: "820px" }}
                            >
                                <BuyingPower
                                    currencyRate={currencyRate}
                                    currency={currency}
                                    clickedData={data?.clickedValueData}
                                />
                                <div className="flex w-full flex-col xl:flex-row">
                                    <LineGraphConversion
                                        data={data?.hourlyDistributionOfClickedValue}
                                    />
                                    <ArticleInteractions
                                        data={data?.categoryBasedConversion?.categoryData}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center lg:items-start flex-col justify-center w-full md:max-w-304  ">
                                <ClickedValue
                                    currencyRate={currencyRate}
                                    currency={currency}
                                    clickedData={data?.totalClickedValue}
                                />
                                <AverageBarChart
                                    currencyRate={currencyRate}
                                    currency={currency}
                                    data={data?.averageOverallBuyingPower}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Conversion;
