import React, { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

// import Footer from "components/shared/Footer";
import Header from "components/shared/Header/index";
import Form from "./Form";
import Terms from "./Terms";

import bg from "assests/images/bg2.png";
import RegisterModal from "./RegisterModal";
import { registerAPI } from "components/shared/utils";

import "./styles.css";

const RegisterForm = () => {
    const [terms, setTerms] = useState(false);
    const [info, setInfo] = useState(false);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        name: "",
        contactNumber: "",
        designation: "",
        email: "",
        companyName: "",
        websiteUrls: [""],
        companyAddress: "",
    });
    const [countryCode, setCountryCode] = useState("91");
    const [error, setError] = useState(null);
    const [nameValid, setNameValid] = useState(false);
    const [numberValid, setNumberValid] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [designationValid, setDesignationValid] = useState(false);
    const [urlValid, setUrlValid] = useState([false]);
    const [addressValid, setAddressValid] = useState(false);
    const [overallValidity, setOverallValidity] = useState(false);
    const CheckOverallValidity = () => {
        if (
            form.name.length < 2 ||
            form.contactNumber.length < 9 ||
            form.email.length < 2 ||
            form.designation.length < 2 ||
            form.websiteUrls[0].length < 2 ||
            form.companyAddress.length < 2 ||
            nameValid ||
            numberValid ||
            emailValid ||
            designationValid ||
            addressValid ||
            urlValid.reduce((prev, curr) => prev || curr)
        ) {
            setOverallValidity(false);
        } else {
            setOverallValidity(true);
        }
    };

    const location = useLocation();

    const paramKey = queryString.parse(location.search).sr;

    // mix panel
    if (paramKey) {
        mixpanel.track("PageView", {
            view: "Register",
            sales_rep: paramKey,
        });
    } else {
        mixpanel.track("PageView", {
            view: "Register",
            sales_rep: "None",
        });
    }

    // Function to submit form and call API
    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        CheckOverallValidity();
        if (!terms || !info) return;
        if (!overallValidity) {
            setError("Please complete all required fields.");
            window.scroll(0, 0);
        } else {
            setLoading(true);
            let _newForm = form;
            _newForm.countryCode = "+" + countryCode;

            let _registerAPI = registerAPI;
            if (paramKey) {
                _registerAPI = registerAPI + `?sr=${paramKey}`;
            }

            //     // Storing response
            const response = await fetch(_registerAPI, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                // /make sure to serialize your JSON body
                body: JSON.stringify(_newForm),
            });
            // Storing data in form of JSON
            const data = await response.json();

            if (data.ok) {
                setModal(true);
                // mix panel
                if (paramKey) {
                    mixpanel.track("Register submit successful", {
                        sales_rep: paramKey,
                        name: _newForm.name,
                        email: _newForm.email,
                    });
                } else {
                    mixpanel.track("Register submit successful", {
                        sales_rep: "None",
                        name: _newForm.name,
                        email: _newForm.email,
                    });
                }
                setForm({
                    name: "",
                    contactNumber: "",
                    designation: "",
                    email: "",
                    companyName: "",
                    websiteUrls: [""],
                    companyAddress: "",
                });
                setCountryCode("91");
                setLoading(false);
            } else {
                setError(data.error.message);
                setLoading(false);
                window.scroll(0, 0);
            }
        }
    };

    useEffect(() => {
        CheckOverallValidity();
    }, [nameValid, designationValid, urlValid, emailValid, numberValid, addressValid, form]);

    return (
        <div
            className="flex flex-col flex-1 min-h-screen text-white bg-black"
            style={{
                backgroundImage: `url(${bg})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
            }}
        >
            <Header />
            <div className="flex flex-1 flex-col items-center pt-8 lg:pt-20">
                <div className="flex items-center flex-col mb-9">
                    <h1 className="font-bold text-3xl lg:text-5xl mb-3">Partner Registration</h1>
                    <p className="mb-6 lg:mb-8 text-base text-white">
                        Please fill all your details for identification
                    </p>
                    <p className="text-11 text-gray2 max-w-xs lg:max-w-lg text-center">
                        Register with Kunato and become a part of the content revolution. Get access
                        to real-time content pricing, an insights dashboard, and a supporting
                        community to help you take your content creation experience to the next
                        level.
                    </p>
                </div>

                {error && (
                    <div
                        style={{ backgroundColor: "#1D0B13" }}
                        className="flex items-center mb-4 rounded font-semibold text-base border border-red-500 text-red-500 py-2.5 px-4"
                    >
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M15 20V15M15 10H15.0125M27.5 15C27.5 21.9036 21.9036 27.5 15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15Z"
                                stroke="#F34860"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <span className="ml-3">{error}</span>
                    </div>
                )}

                <Form
                    form={form}
                    setForm={setForm}
                    handleSubmit={handleSubmit}
                    nameValid={nameValid}
                    emailValid={emailValid}
                    numberValid={numberValid}
                    designationValid={designationValid}
                    addressValid={addressValid}
                    urlValid={urlValid}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    setNameValid={setNameValid}
                    setAddressValid={setAddressValid}
                    setNumberValid={setNumberValid}
                    setEmailValid={setEmailValid}
                    setDesignationValid={setDesignationValid}
                    setUrlValid={setUrlValid}
                />
                <Terms />
                <div className=" w-full max-w-716 text-xs text-gray2 mb-14 px-4 lg:px-0">
                    <div className="flex items-center mb-2">
                        <input
                            type="checkbox"
                            className="bg-blue2 cursor-pointer register-input"
                            onChange={() => setTerms(!terms)}
                        />
                        <p className="ml-2">
                            I have read, understood and hereby agree to the Terms in respect of this
                            product.
                        </p>
                    </div>
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            className="bg-blue2 cursor-pointer register-input"
                            onChange={() => setInfo(!info)}
                        />
                        <p className="ml-2">
                            I do hereby declare that all information furnished in this form is true
                            to the best of my knowledge and belief.
                        </p>
                    </div>
                </div>
                <div className="flex items-center justify-center w-full max-w-716 text-sm font-medium mb-8">
                    <button
                        className={
                            !terms || !info || loading || !overallValidity
                                ? "bg-gray-500 text-gray2 py-1.5 px-5 rounded"
                                : "bg-blue2 text-black py-1.5 px-5 rounded"
                        }
                        disabled={!terms || !info || loading || !overallValidity}
                        onClick={(e) => handleSubmit(e)}
                    >
                        Register
                    </button>
                </div>
            </div>
            {/* <Footer /> */}
            {modal && <RegisterModal closeModal={() => setModal(false)} />}
        </div>
    );
};

export default RegisterForm;
