import React from "react";

/**
 * User type modal
 * @prop {Function} closeModal - Function to close modal
 */

const UserTypeModal = ({ setForm, form }) => {
    const handleClick = (type) => {
        let _form = form;
        _form.monthlyTraffic = type;
        setForm(_form);
    };

    const leftChevron = "<";
    const rightChevron = ">";

    return (
        <div
            className="shadow-md z-50 pt-4 pb-3 rounded-md r-0 w-full max-w-310 overflow-y-auto min-h-full"
            style={{
                position: "absolute",
                top: "45px",
                left: 0,
                backgroundColor: "#193148",
            }}
        >
            <div className="text-white flex flex-col items-start font-medium justify-center w-full list-none">
                <li
                    onClick={() => handleClick("<99,999")}
                    className="cursor-pointer py-2 pl-5 hover:bg-blue7 w-full"
                >
                    {leftChevron} 99,999
                </li>
                <li
                    onClick={() => handleClick("100,000 - 999,999")}
                    className="cursor-pointer py-2 pl-5 hover:bg-blue7 w-full"
                >
                    100,000 - 999,999
                </li>
                <li
                    onClick={() => handleClick("1,000,000 - 9,999,999")}
                    className="cursor-pointer py-2 pl-5 hover:bg-blue7 w-full"
                >
                    1,000,000 - 9,999,999
                </li>
                <li
                    onClick={() => handleClick("> 10,000,000")}
                    className="cursor-pointer py-2 pl-5 hover:bg-blue7 w-full"
                >
                    {rightChevron} 10,000,000
                </li>
            </div>
        </div>
    );
};

export default UserTypeModal;
