import React from "react";

import bg from "assests/images/home1.png";
import bg1 from "assests/images/formHeader.png";
import AccessForm from "./Form";

const HomeForm = () => {
    return (
        <div
            className="w-full h-full flex flex-col lg:flex-row flex-1 items-center justify-center"
            style={{
                backgroundImage: `url(${bg})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "scroll",
                backgroundSize: "cover",
                backgroundPosition: "center",
                minHeight: "650px",
            }}
        >
            <div className="text-center pt-10 lg:pt-0">
                <h1 className="md:text-6xl text-4xl font-bold max-w-2xl lg:mr-20 px-10 lg:px-0 mb-14 lg:mb-0">
                    Kunato gives content its <br className="lg:hidden" />{" "}
                    <span className="text-blue2">True Market Value</span>
                </h1>
            </div>
            <div className="w-11/12 lg:w-full max-w-425 flex flex-col">
                <div
                    className="text-3xl relative w-full flex items-center justify-center text-center rounded-tr-2xl rounded-tl-2xl overflow-hidden"
                    style={{ backgroundColor: "#011A32" }}
                >
                    <div className="w-full absolute top-0 left-0 h-24">
                        <img src={bg1} alt="img" className="h-full w-full" />
                    </div>
                    <h1 className="font-bold w-full leading-7 py-7 z-10 h-24 text-3xl">
                        Exclusive Access
                    </h1>
                </div>
                <AccessForm />
            </div>
        </div>
    );
};

export default HomeForm;
