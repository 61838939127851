import { useState, useEffect } from "react";
import mixpanel from "mixpanel-browser";

import Info from "components/shared/icons/Info";
import { currencyDisplay } from "components/shared/currency";
import { converterCurrency } from "components/shared/convertCurrency";
import { dateConverter } from "components/shared/dateConverter";
import { Tooltip } from "components/shared/Tooltip";

import NoImage from "assests/images/No-image.png";
import Spinner from "components/shared/spinner";
import GraphModal from "./GraphModal.js";

const Articles = ({ currency, data, currencyRate, fetchingArticleData, getArticleData }) => {
    let _color = localStorage.theme === "light" ? "#000" : "#fff";
    const [tab, setTab] = useState("value");
    const [_data, setData] = useState(data);
    const [hover, setHover] = useState(false);

    const [graphModal, setGraphModal] = useState(false);
    const [articleInfo, setArticleInfo] = useState({});

    const mixPanelTrack = (value) => {
        if (value === "value") {
            mixpanel.track("ReportClicked", {
                view: "AI data",
                report: "Articles - Highest Value",
            });
        } else if (value === "views") {
            mixpanel.track("ReportClicked", {
                view: "AI data",
                report: "Articles - Top Views",
            });
        } else if (value === "revenue") {
            mixpanel.track("ReportClicked", {
                view: "AI data",
                report: "Articles - Top Revenue Potential",
            });
        } else {
            mixpanel.track("ReportClicked", {
                view: "AI data",
                report: "Articles",
            });
        }
    };

    const setClass = (type) => {
        let _class = "cursor-pointer px-2";
        if (type === tab) {
            _class = "bg-light-blue rounded-xl p-1 text-white font-bold px-2 cursor-pointer";
        }

        return _class;
    };

    useEffect(() => {
        if (tab === "value") {
            getArticleData(tab);
        } else if (tab === "views") {
            getArticleData(tab);
        } else if (tab === "revenue") {
            getArticleData(tab);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab]);

    useEffect(() => {
        setData(data);
    }, [data]);

    return (
        <div
            className="flex flex-col relative overflow-scroll lg:h-340 w-full bg-white dark:bg-theme-bgDark rounded-xl pb-3 px-4 mr-4 mb-4"
            style={{ maxWidth: "820px" }}
            onClick={() => mixPanelTrack()}
        >
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between pb-4 sticky top-0 left-0 bg-white dark:bg-theme-bgDark pt-3 z-30">
                <div
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    className="flex min-w-full md:min-w-min overflow-scroll items-center mb-2 md:mb-0 bg-white dark:bg-theme-bgDark z-30"
                >
                    <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                        Articles
                    </h5>
                    <p className="relative cursor-pointer">
                        <Info />
                    </p>
                    {hover && (
                        <span className="absolute top-4 left-4">
                            <Tooltip
                                left="0px"
                                text="This shows the top articles and their details on your website across different filters for a selected time period."
                            />
                        </span>
                    )}
                </div>
                <div className="flex min-w-full md:min-w-min items-center list-none text-11 text-silver-dark dark:text-silver-medium bg-white dark:bg-theme-bgDark z-30">
                    <li
                        onClick={() => {
                            setTab("value");
                            mixPanelTrack("value");
                        }}
                        className={setClass("value")}
                    >
                        Highest Value
                    </li>
                    <li
                        onClick={() => {
                            setTab("views");
                            mixPanelTrack("views");
                        }}
                        className={setClass("views")}
                    >
                        Top Views
                    </li>
                    <li
                        onClick={() => {
                            setTab("revenue");
                            mixPanelTrack("revenue");
                        }}
                        className={setClass("revenue")}
                    >
                        Top Revenue Potential
                    </li>
                </div>
            </div>

            {fetchingArticleData ? (
                <div className="flex flex-1 items-center justify-center w-full min-h-full">
                    <Spinner text={"Fetching data..."} />
                </div>
            ) : (
                <table className="overflow-y-scroll inline-table relative w-full">
                    <thead className="w-full sticky z-20" style={{ top: "52px" }}>
                        <tr className="bg-gray-100 relative text-blue-600 dark:bg-black dark:text-cyan-400 text-xs font-bold text-center p-2">
                            <th className="pl-2 py-2 sticky left-0 bg-gray-100 dark:bg-black">
                                Rank
                            </th>
                            <th className="pl-3 sticky left-9 bg-gray-100 dark:bg-black">
                                Articles
                            </th>
                            <th className="pr-3 bg-gray-100 dark:bg-black">Published on</th>
                            <th className="pr-3 bg-gray-100 dark:bg-black">Author</th>
                            <th className="pr-2 capitalize bg-gray-100 dark:bg-black">{tab}</th>
                        </tr>
                    </thead>
                    <tbody className="w-full text-xs font-normal dark:text-white text-center ">
                        {_data?.map((article, index) => (
                            <tr key={index} className="relative">
                                <td
                                    className="py-2 pt-4 pl-1 sticky bg-white dark:bg-theme-bgDark left-0 text-base font-bold dark:text-white"
                                    style={index < 3 ? { color: "#0075FF" } : { color: _color }}
                                >
                                    {index + 1}
                                </td>

                                <td className="flex items-center text-left pt-4 pb-2 ml-3 sticky left-14 bg-white dark:bg-theme-bgDark">
                                    <button
                                        onClick={() => {
                                            setArticleInfo(article);
                                            setGraphModal(true);
                                        }}
                                        // target="_blank"
                                        // rel="noopener noreferrer"
                                        className="flex items-center"
                                    >
                                        {/* <div
                                        className="dark:border border-silver-light w-11 h-6"
                                        style={{
                                            backgroundImage: `url(${article.thumbnail || NoImage})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                            marginRight: "13px",
                                        }}
                                    ></div> */}
                                        <img
                                            src={article.thumbnail || NoImage}
                                            alt="img-article"
                                            className="dark:border border-silver-light w-11 min-w-44 h-6 mr-3"
                                        />
                                        <h4 className="truncate text-xs font-bold w-full max-w-150 md:max-w-380">
                                            {article.title}
                                        </h4>
                                    </button>
                                </td>

                                <td className="pr-2 pt-4"> {dateConverter(article.publishedAt)}</td>
                                <td className="pr-2 pt-4">{article.author}</td>
                                <td className="pr-2 pt-4 text-base font-bold w-24">
                                    {tab === "value" && (
                                        <>
                                            {currencyDisplay(currency)}
                                            {converterCurrency(article?.price, currencyRate)}
                                        </>
                                    )}
                                    {tab === "views" && article?.views}
                                    {tab === "revenue" && (
                                        <>
                                            {currencyDisplay(currency)}
                                            {converterCurrency(
                                                article?.revenuePotential,
                                                currencyRate
                                            )}
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            {graphModal && (
                <GraphModal
                    currencyRate={currencyRate}
                    currency={currency}
                    article={articleInfo}
                    closeModal={() => {
                        setArticleInfo({});
                        setGraphModal(false);
                    }}
                />
            )}
            <div className="flex items-center justify-end w-full h-2">
                {/* <h4 className="text-sm font-medium mr-0.5 flex items-center text-green-500">
                    <ArrowUp />
                    <p className="ml-0.5">5.34%</p>
                </h4>
                <p className="text-10 font-medium text-gray-500 italic dark:text-silver-light">
                    valuable than average article valuation
                </p> */}
            </div>
        </div>
    );
};

export default Articles;
