import React, { useEffect, useRef, useState } from "react";

import CandleGraph from "./Graph";

import Services from "components/shared/API/Services";

import { chandlechartAPI } from "components/shared/utils";
import NoImage from "assests/images/No-image.png";
import ArticlePrice from "./ArticlePrice";

/**
 * Graph modal for AI page
 * @prop {Function} closeModal - Function to close modal

 */

const GraphModal = ({ closeModal, article, currency, currencyRate }) => {
    const [loading, setLoading] = useState(false);
    const [graphData, setGraphData] = useState([]);
    const [timeFilter, setTimeFilter] = useState("1d");
    const [articlePrice, setArticlePrice] = useState(article?.price);
    const modalRef = useRef();

    // Function to get prcies of articles
    const getPrices = async () => {
        const price = await Services.getPrices("inr", article.url);
        setArticlePrice(price);
    };

    const getGraphData = async () => {
        setLoading(true);
        const price = await Services.getPrices("inr", article.url);
        setArticlePrice(price);

        setTimeout(() => {
            setLoading(false);
        }, 100);

        setGraphData([]);
        // Storing response
        const response = await fetch(chandlechartAPI, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // /make sure to serialize your JSON body
            body: JSON.stringify({
                currency: "inr",
                url: article.url,
                duration: timeFilter,
            }),
        });
        // Storing data in form of JSON
        var data = await response.json();
        if (data.ok) {
            let _data = data;
            _data = _data.data;
            setGraphData(_data);
        }
    };

    useEffect(() => {
        getGraphData();
        getPrices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeFilter]);

    // Logic added to remove scroll when modal is open
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    useEffect(() => {
        const clickOutside = (event) => {
            if (!modalRef.current.contains(event.target)) {
                closeModal();
            }
        };
        document.addEventListener("mousedown", clickOutside);
        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            getPrices();
        }, 5000);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                width: "100%",
                height: "100%",
                transform: " translate(-50%, -50%)",
                zIndex: 1000,
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                overflowY: "auto",
            }}
        >
            <div
                ref={modalRef}
                className="bg-theme-gray dark:bg-black rounded-2xl"
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "100%",
                    maxWidth: "891px",
                    transform: " translate(-50%, -50%)",
                }}
            >
                <div className="pl-10 pr-8 py-11 relative">
                    <img
                        src={article?.thumbnail || NoImage}
                        alt="img-article"
                        className="dark:border border-silver-light min-w-[122px] w-[122px] h-[77px] mb-3"
                    />
                    <a href={article.url} target="_blank" rel="noopener noreferrer">
                        <h6 className="text-xl mb-3 font-bold text-left dark:text-white overflow-hidden truncate">
                            {article?.title}
                        </h6>
                    </a>
                    {loading ? (
                        <div className="dark:text-white text-xl font-semibold h-[334px] flex items-center justify-center">
                            Loading...
                        </div>
                    ) : (
                        <>
                            <ArticlePrice
                                price={articlePrice}
                                currency={currency}
                                currencyRate={currencyRate}
                            />
                            <CandleGraph
                                graphData={graphData}
                                timeFilter={timeFilter}
                                setTimeFilter={setTimeFilter}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default GraphModal;
