const ArrowUp = () => {
    return (
        <svg
            width="19"
            height="17"
            viewBox="0 0 9 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginLeft: "4px" }}
        >
            <path d="M4.5 0L8.39711 6.75H0.602886L4.5 0Z" fill="#25CA83" />
        </svg>
    );
};

export default ArrowUp;
