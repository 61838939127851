import { useState, useEffect } from "react";

import ReactECharts from "echarts-for-react";
import mixpanel from "mixpanel-browser";

import Info from "components/shared/icons/Info";
import { currencyDisplay } from "components/shared/currency";
import { Tooltip } from "components/shared/Tooltip";
import { converterCurrency } from "components/shared/convertCurrency";
import { numberWithCommas } from "components/shared/NumberCommaAddition";

const AverageBarChart = ({ currency, data, currencyRate }) => {
    const [_data, setData] = useState(data);
    const [hover, setHover] = useState(false);

    const mixPanelTrack = () => {
        // mix panel
        mixpanel.track("ReportClicked", {
            view: "AI data",
            report: "Average Article Price",
        });
    };

    // Code to set max limit for Y axis
    let max = Math.max(_data);
    max = parseInt(max * 1.3);

    const options = {
        tooltip: {
            show: false,
            trigger: "none",
        },
        grid: {
            left: "0%",
            right: "0%",
            bottom: "3%",
            top: "5%",
            containLabel: true,
        },
        legend: {
            show: false,
        },
        xAxis: {
            type: "category",
            data: ["1"],
            axisLabel: {
                show: false,
            },
            axisTick: {
                show: false,
            },
        },
        yAxis: {
            type: "value",
            axisLine: {
                show: true,
                onZero: true,
                onZeroAxisIndex: null,
            },
            max: max,
            splitLine: {
                show: true,
                lineStyle: {
                    color: localStorage.theme === "light" ? "#D1D1D1" : "#393939",
                    type: "dashed",
                    opacity: "0.6",
                },
            },
            axisLabel: {
                show: true,
                position: "top",
                fontSize: 10,
                formatter: function (d) {
                    return currencyDisplay(currency) + converterCurrency(d, currencyRate);
                },
                color: localStorage.theme === "light" ? "#000" : "#909090",
            },
        },
        series: [
            {
                label: {
                    show: true,
                    position: "top",
                    fontSize: 12,
                    color: localStorage.theme === "light" ? "#000" : "#fff",
                    formatter: function (d) {
                        return (
                            currencyDisplay(currency) +
                            numberWithCommas(converterCurrency(d.data, currencyRate), currency)
                        );
                    },
                },
                data: [_data],
                type: "bar",
                barWidth: 95,
                color: "#0075FF",
            },
        ],
    };

    // const setClass = (type) => {
    //     let _class = "cursor-pointer p-1 font-bold leading-3";
    //     if (type === tab) {
    //         _class =
    //             "text-light-blue border-b-2 leading-3 border-light-blue p-1 text-white font-bold cursor-pointer";
    //     }

    //     return _class;
    // };

    useEffect(() => {
        setData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!options) return <div> Loading....</div>;

    return (
        <div
            onClick={() => mixPanelTrack()}
            className="flex flex-1 w-11/12 md:w-full px-5 flex-col items-center justify-center bg-white dark:bg-theme-bgDark rounded-xl pb-7 mb-4"
        >
            <div className="flex items-center justify-between w-full pl-0 pt-4 pb-2 mb-2">
                <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                        <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                            Average Article Price
                        </h5>
                        <p
                            className="relative cursor-pointer"
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            <Info />
                            {hover && (
                                <Tooltip
                                    left="-80px"
                                    text="This shows the average price of all the articles priced on your website with different time frame filters to help you compare."
                                />
                            )}
                        </p>
                    </div>
                    {/* <div className="flex items-center list-none text-11 text-silver-dark">
                        <li onClick={() => setTab("7D")} className={setClass("7D")}>
                            7D
                        </li>
                        <li
                            onClick={() => setTab("15D")}
                            className={setClass("15D")}
                            style={{ margin: "0 14px" }}
                        >
                            15D
                        </li>
                        <li onClick={() => setTab("1M")} className={setClass("1M")}>
                            1M
                        </li>
                    </div> */}
                </div>
            </div>
            {options && (
                <ReactECharts
                    option={options}
                    style={{
                        display: "block",
                        width: "100%",
                        maxWidth: "295px",
                        height: "256px",
                    }}
                />
            )}
        </div>
    );
};

export default AverageBarChart;
