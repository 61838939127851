import { useState } from "react";
import mixpanel from "mixpanel-browser";

import Info from "components/shared/icons/Info";
import { currencyDisplay } from "components/shared/currency";
import { converterCurrency } from "components/shared/convertCurrency";
// import ArrowUp from "components/shared/icons/ArrowUp";
import { Tooltip } from "components/shared/Tooltip";

import NoImage from "assests/images/No-image.png";
// import Rank1 from "components/shared/icons/Rank1";
// import Rank2 from "components/shared/icons/Rank2";
// import Rank3 from "components/shared/icons/Rank3";

const Valued = ({ currency, data, currencyRate }) => {
    const [hover, setHover] = useState(false);

    const mixPanelTrack = () => {
        // mix panel
        mixpanel.track("ReportClicked", {
            view: "Overview",
            report: "Most Valuable Articles",
        });
    };

    return (
        <div
            onClick={() => mixPanelTrack()}
            className="flex flex-col bg-white dark:bg-theme-bgDark rounded-xl py-5 px-4 pb-5 w-full xl:max-w-351"
        >
            <div className="flex items-center mb-4">
                <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                    Most Valuable Articles
                </h5>
                <p
                    className="relative cursor-pointer"
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <Info />
                    {hover && (
                        <Tooltip
                            left="-80px"
                            text="This shows the top 3 highest priced content on your website over a period of time."
                        />
                    )}
                </p>
            </div>

            <table className="mb-2">
                <thead>
                    <tr className="bg-gray-100 text-blue-600 dark:bg-black dark:text-cyan-400 text-10 font-bold text-center p-2">
                        <th className="pl-2 py-2">Rank</th>
                        <th className="pl-3">Articles</th>
                        <th className="pr-2">Value</th>
                    </tr>
                </thead>
                <tbody className="text-9 font-bold dark:text-white text-center">
                    {data.map((article, index) => (
                        <tr key={index}>
                            <td className="pt-4 pl-2 text-xl font-extrabold dark:text-white">
                                {index + 1}
                            </td>

                            <td className="flex items-center text-left pt-4 ml-3">
                                <a
                                    href={article.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center"
                                >
                                    <div
                                        className="dark:border border-silver-light"
                                        style={{
                                            backgroundImage: `url(${article.thumbnail || NoImage})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                            width: "44px",
                                            height: "25px",
                                            marginRight: "13px",
                                        }}
                                    ></div>
                                    {/* <img
                                        src={article.thumbnail || NoImage}
                                        alt={"article " + (index + 1)}
                                        style={{
                                            margin: "0",
                                            width: "44px",
                                            height: "25px",
                                            marginRight: "13px",
                                        }}
                                    /> */}
                                    <h4 className="truncate" style={{ width: "140px" }}>
                                        {article.title}
                                    </h4>
                                </a>
                            </td>
                            <td className="pr-2 pt-4">
                                {currencyDisplay(currency)}
                                {converterCurrency(article?.price, currencyRate)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="flex items-center justify-end w-full h-2">
                {/* <h4 className="text-sm font-medium mr-0.5 flex items-center text-green-500">
                    <ArrowUp />
                    <p className="ml-0.5">5.34%</p>
                </h4>
                <p className="text-10 font-medium text-gray-500 italic dark:text-silver-light">
                    valuable than average article valuation
                </p> */}
            </div>
        </div>
    );
};

export default Valued;
