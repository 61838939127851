const RememberCheck = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.66675 4.16669C1.66675 2.78598 2.78604 1.66669 4.16675 1.66669H15.8334C17.2141 1.66669 18.3334 2.78598 18.3334 4.16669V15.8334C18.3334 17.2141 17.2141 18.3334 15.8334 18.3334H4.16675C2.78604 18.3334 1.66675 17.2141 1.66675 15.8334V4.16669Z"
                fill="#fff"
                stroke="#fff"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.0952 6.91083C14.4206 7.23626 14.4206 7.7639 14.0952 8.08934L8.92259 13.506C8.59715 13.8314 8.06951 13.8314 7.74408 13.506L5.24408 11.006C4.91864 10.6806 4.91864 10.1529 5.24408 9.8275C5.56951 9.50206 6.09715 9.50206 6.42259 9.8275L8.33333 11.7382L12.9167 6.91083C13.2421 6.58539 13.7697 6.58539 14.0952 6.91083Z"
                fill="black"
            />
        </svg>
    );
};

export default RememberCheck;
