const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

// Function to convert date into eg- june 10

export const dateConverter = (date) => {
    let _date = new Date(date);
    _date = `${monthNames[_date.getMonth()]} ${_date.getDate()}`;

    return _date;
};
