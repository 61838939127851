import React, { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import { motion, AnimatePresence } from "framer-motion";
import { Link, Navigate } from "react-router-dom";

import Form from "./Form";

import LoginStep1 from "./Slider/LoginStep1";
import LoginStep5 from "./Slider/LoginStep5";
import LoginStep4 from "./Slider/LoginStep4";
import LoginStep3 from "./Slider/LoginStep3";
import LoginStep2 from "./Slider/LoginStep2";

import NewKlogo from "components/shared/icons/NewKlogo";

const LoginPage = () => {
  mixpanel.track("PageView", {
    view: "Login",
  });
  const [step, setStep] = useState(1);

  const setClass = (_step) => {
    let _class = "font-extrabold text-6xl text-gray-700";
    if (_step === step) {
      _class = "font-extrabold text-6xl text-white";
    }
    return _class;
  };

  const token = localStorage.getItem("accessToken");

  const delay = 5500;

  // animation logic
  const containerVariants = {
    hidden: {
      opacity: 0,
      // x: "100vw",
    },
    visible: {
      opacity: 1,
      // x: 0,
      // transition: { ease: "linear", delay: 0.1, duration: 1.1 },
    },
    exit: {
      // x: "-200vw",
      // transition: { ease: "linear", duration: 2.3 },
    },
  };

  useEffect(() => {
    setTimeout(() => {
      setStep(step + 1);
      if (step === 5) {
        setStep(1);
      }
    }, delay);
  }, [step]);

  if (token) return <Navigate to="/overview" />;

  return (
    <div className="flex flex-1 w-full min-h-screen flex-col bg-black text-white relative">
      <Link to="/" className="pt-6 lg:pt-10 pl-6 lg:pl-16 absolute z-40">
        <NewKlogo />
      </Link>

      <div className="flex flex-1 flex-col w-full h-full lg:flex-row items-center justify-center lg:justify-between">
        <div className="hidden lg:flex relative w-full min-h-screen flex-1">
          <AnimatePresence exitBeforeEnter initial={false}>
            {step === 1 && (
              <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="w-full"
              >
                <LoginStep1 />
              </motion.div>
            )}{" "}
          </AnimatePresence>{" "}
          <AnimatePresence exitBeforeEnter>
            {step === 2 && (
              <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="w-full"
              >
                <LoginStep2 />
              </motion.div>
            )}{" "}
          </AnimatePresence>{" "}
          <AnimatePresence exitBeforeEnter>
            {step === 3 && (
              <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="w-full"
              >
                <LoginStep3 />
              </motion.div>
            )}{" "}
          </AnimatePresence>{" "}
          <AnimatePresence exitBeforeEnter>
            {step === 4 && (
              <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="w-full"
              >
                <LoginStep4 />
              </motion.div>
            )}{" "}
          </AnimatePresence>{" "}
          <AnimatePresence exitBeforeEnter>
            {step === 5 && (
              <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="w-full"
              >
                <LoginStep5 />
              </motion.div>
            )}
          </AnimatePresence>{" "}
          <div className="flex w-full absolute bottom-10 items-center justify-center  list-none z-20">
            <li className={setClass(1)}>.</li>
            <li className={setClass(2)}>.</li>
            <li className={setClass(3)}>.</li>
            <li className={setClass(4)}>.</li>
            <li className={setClass(5)}>.</li>
          </div>
        </div>

        <div
          className="flex flex-1 pt-24 lg:pt-0 items-start lg:items-center justify-center w-full min-h-screen z-20"
          style={{
            background:
              "linear-gradient(103.61deg, #001627 0.99%, #000000 97.81%)",
          }}
        >
          <Form />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
