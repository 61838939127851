export const countriesWithCodes = [
  { name: "India", code: "91", numberOfDigits: 10 },
  { name: "United kingdom", code: "44", numberOfDigits: 10 },
  { name: "United States", code: "1", numberOfDigits: 10 },
  { name: "Israel", code: "972", numberOfDigits: 9 },
  { name: "Afghanistan", code: "93", numberOfDigits: 9 },
  { name: "Aland Islands", code: "358", numberOfDigits: 10 },
  { name: "Albania", code: "355", numberOfDigits: 9 },
  { name: "Algeria", code: "213", numberOfDigits: 9 },
  { name: "American Samoa", code: "1684", numberOfDigits: 7 },
  { name: "Andorra", code: "376", numberOfDigits: 6 },
  { name: "Angola", code: "244", numberOfDigits: 9 },
  { name: "Anguilla", code: "1264", numberOfDigits: 7 },
  { name: "Antarctica", code: "672", numberOfDigits: 9 },
  { name: "Antigua and Barbuda", code: "1268", numberOfDigits: 7 },
  { name: "Argentina", code: "54", numberOfDigits: 8 },
  { name: "Armenia", code: "374", numberOfDigits: 8 },
  { name: "Aruba", code: "297", numberOfDigits: 7 },
  { name: "Australia", code: "61", numberOfDigits: 9 },
  { name: "Austria", code: "43", numberOfDigits: 10 },
  { name: "Azerbaijan", code: "994", numberOfDigits: 9 },
  { name: "Bahamas", code: "1242", numberOfDigits: 7 },
  { name: "Bahrain", code: "973", numberOfDigits: 8 },
  { name: "Bangladesh", code: "880", numberOfDigits: 10 },
  { name: "Barbados", code: "1246", numberOfDigits: 7 },
  { name: "Belarus", code: "375", numberOfDigits: 9 },
  { name: "Belgium", code: "32", numberOfDigits: 9 },
  { name: "Belize", code: "501", numberOfDigits: 7 },
  { name: "Benin", code: "229", numberOfDigits: 6 },
  { name: "Bermuda", code: "1441", numberOfDigits: 7 },
  { name: "Bhutan", code: "975", numberOfDigits: 8 },
  { name: "Bolivia", code: "591", numberOfDigits: 8 },
  { name: "Bosnia and Herzegovina", code: "387", numberOfDigits: 8 },
  { name: "Botswana", code: "267", numberOfDigits: 8 },
  { name: "Brazil", code: "55", numberOfDigits: 11 },
  { name: "British Indian Ocean Territory", code: "246", numberOfDigits: 7 },
  { name: "Brunei Darussalam", code: "673", numberOfDigits: 7 },
  { name: "Bulgaria", code: "359", numberOfDigits: 8 },
  { name: "Burkina Faso", code: "226", numberOfDigits: 8 },
  { name: "Burundi", code: "257", numberOfDigits: 8 },
  { name: "Cambodia", code: "855", numberOfDigits: 8 },
  { name: "Cameroon", code: "237", numberOfDigits: 9 },
  { name: "Canada", code: "1", numberOfDigits: 10 },
  { name: "Cape Verde", code: "238", numberOfDigits: 7 },
  { name: "Cayman Islands", code: "1345", numberOfDigits: 9 },
  { name: "Central African Republic", code: "236", numberOfDigits: 8 },
  { name: "Chad", code: "235", numberOfDigits: 8 },
  { name: "Chile", code: "56", numberOfDigits: 9 },
  { name: "China", code: "86", numberOfDigits: 11 },
  { name: "Christmas Island", code: "61", numberOfDigits: 9 },
  { name: "Cocos Islands", code: "61", numberOfDigits: 9 },
  { name: "Colombia", code: "57", numberOfDigits: 10 },
  { name: "Comoros", code: "269", numberOfDigits: 7 },
  { name: "Congo", code: "242", numberOfDigits: 8 },
  { name: "Congo", code: "243", numberOfDigits: 9 },
  { name: "Cook Islands", code: "682", numberOfDigits: 5 },
  { name: "Costa Rica", code: "506", numberOfDigits: 8 },
  { name: "Cote d'Ivoire", code: "225", numberOfDigits: 7 },
  { name: "Croatia", code: "385", numberOfDigits: 9 },
  { name: "Cuba", code: "53", numberOfDigits: 8 },
  { name: "Curacao", code: "599", numberOfDigits: 7 },
  { name: "Cyprus", code: "357", numberOfDigits: 8 },
  { name: "Czech Republic", code: "420", numberOfDigits: 9 },
  { name: "Denmark", code: "45", numberOfDigits: 8 },
  { name: "Djibouti", code: "253", numberOfDigits: 8 },
  { name: "Dominica", code: "1767", numberOfDigits: 7 },
  { name: "Dominican Republic", code: "1809", numberOfDigits: 7 },
  { name: "Ecuador", code: "593", numberOfDigits: 9 },
  { name: "Egypt", code: "20", numberOfDigits: 10 },
  { name: "El Salvador", code: "503", numberOfDigits: 8 },
  { name: "Equatorial Guinea", code: "240", numberOfDigits: 9 },
  { name: "Eritrea", code: "291", numberOfDigits: 7 },
  { name: "Estonia", code: "372", numberOfDigits: 8 },
  { name: "Ethiopia", code: "251", numberOfDigits: 9 },
  { name: "Falkland Islands (Malvinas)", code: "500", numberOfDigits: 5 },
  { name: "Faroe Islands", code: "298", numberOfDigits: 5 },
  { name: "Fiji", code: "679", numberOfDigits: 7 },
  { name: "Finland", code: "358", numberOfDigits: 9 },
  { name: "France", code: "33", numberOfDigits: 9 },
  { name: "French Guiana", code: "594", numberOfDigits: 9 },
  { name: "French Polynesia", code: "689", numberOfDigits: 8 },
  { name: "Gabon", code: "241", numberOfDigits: 7 },
  { name: "Gambia", code: "220", numberOfDigits: 7 },
  { name: "Georgia", code: "995", numberOfDigits: 9 },
  { name: "Germany", code: "49", numberOfDigits: 10 },
  { name: "Ghana", code: "233", numberOfDigits: 9 },
  { name: "Gibraltar", code: "350", numberOfDigits: 8 },
  { name: "Greece", code: "30", numberOfDigits: 10 },
  { name: "Greenland", code: "299", numberOfDigits: 6 },
  { name: "Grenada", code: "1473", numberOfDigits: 7 },
  { name: "Guadeloupe", code: "590", numberOfDigits: 9 },
  { name: "Guam", code: "1671", numberOfDigits: 10 },
  { name: "Guatemala", code: "502", numberOfDigits: 8 },
  { name: "Guernsey", code: "44", numberOfDigits: 10 },
  { name: "Guinea", code: "224", numberOfDigits: 9 },
  { name: "Guinea-Bissau", code: "245", numberOfDigits: 9 },
  { name: "Guyana", code: "592", numberOfDigits: 7 },
  { name: "Haiti", code: "509", numberOfDigits: 8 },
  { name: "Holy See (Vatican City State)", code: "39", numberOfDigits: 10 },
  { name: "Honduras", code: "504", numberOfDigits: 8 },
  { name: "Hong Kong", code: "852", numberOfDigits: 8 },
  { name: "Hungary", code: "36", numberOfDigits: 9 },
  { name: "Iceland", code: "354", numberOfDigits: 7 },
  { name: "Indonesia", code: "62", numberOfDigits: 9 },
  { name: "Iran", code: "98", numberOfDigits: 10 },
  { name: "Iraq", code: "964", numberOfDigits: 10 },
  { name: "Ireland", code: "353", numberOfDigits: 9 },
  { name: "Isle of Man", code: "44", numberOfDigits: 10 },
  { name: "Italy", code: "39", numberOfDigits: 9 },
  { name: "Ivory Coast", code: "225", numberOfDigits: 8 },
  { name: "Jamaica", code: "1876", numberOfDigits: 7 },
  { name: "Japan", code: "81", numberOfDigits: 10 },
  { name: "Jersey", code: "44", numberOfDigits: 10 },
  { name: "Jordan", code: "962", numberOfDigits: 8 },
  { name: "Kazakhstan", code: "7", numberOfDigits: 10 },
  { name: "Kenya", code: "254", numberOfDigits: 9 },
  { name: "Kosovo", code: "383", numberOfDigits: 8 },
  { name: "Kiribati", code: "686", numberOfDigits: 8 },
  { name: "Kuwait", code: "965", numberOfDigits: 8 },
  { name: "Kyrgyzstan", code: "996", numberOfDigits: 9 },
  { name: "Laos", code: "856", numberOfDigits: 7 },
  { name: "Latvia", code: "371", numberOfDigits: 8 },
  { name: "Lebanon", code: "961", numberOfDigits: 7 },
  { name: "Lesotho", code: "266", numberOfDigits: 8 },
  { name: "Liberia", code: "231", numberOfDigits: 7 },
  { name: "Libyan Arab Jamahiriya", code: "218", numberOfDigits: 10 },
  { name: "Liechtenstein", code: "423", numberOfDigits: 9 },
  { name: "Lithuania", code: "370", numberOfDigits: 8 },
  { name: "Luxembourg", code: "352", numberOfDigits: 9 },
  { name: "Macao", code: "853", numberOfDigits: 8 },
  { name: "Macedonia", code: "389", numberOfDigits: 8 },
  { name: "Madagascar", code: "261", numberOfDigits: 7 },
  { name: "Malawi", code: "265", numberOfDigits: 8 },
  { name: "Malaysia", code: "60", numberOfDigits: 7 },
  { name: "Maldives", code: "960", numberOfDigits: 7 },
  { name: "Mali", code: "223", numberOfDigits: 8 },
  { name: "Malta", code: "356", numberOfDigits: 8 },
  { name: "Marshall Islands", code: "692", numberOfDigits: 7 },
  { name: "Martinique", code: "596", numberOfDigits: 9 },
  { name: "Mauritania", code: "222", numberOfDigits: 8 },
  { name: "Mauritius", code: "230", numberOfDigits: 8 },
  { name: "Mayotte", code: "262", numberOfDigits: 6 },
  { name: "Mexico", code: "52", numberOfDigits: 10 },
  { name: "Micronesia", code: "691", numberOfDigits: 7 },
  { name: "Moldova", code: "373", numberOfDigits: 8 },
  { name: "Monaco", code: "377", numberOfDigits: 8 },
  { name: "Mongolia", code: "976", numberOfDigits: 8 },
  { name: "Montenegro", code: "382", numberOfDigits: 8 },
  { name: "Montserrat", code: "1664", numberOfDigits: 7 },
  { name: "Morocco", code: "212", numberOfDigits: 9 },
  { name: "Mozambique", code: "258", numberOfDigits: 7 },
  { name: "Myanmar", code: "95", numberOfDigits: 7 },
  { name: "Namibia", code: "264", numberOfDigits: 9 },
  { name: "Nauru", code: "674", numberOfDigits: 7 },
  { name: "Nepal", code: "977", numberOfDigits: 10 },
  { name: "Netherlands", code: "31", numberOfDigits: 9 },
  { name: "Netherlands Antilles", code: "599", numberOfDigits: 5 },
  { name: "New Caledonia", code: "687", numberOfDigits: 6 },
  { name: "New Zealand", code: "64", numberOfDigits: 6 },
  { name: "Nicaragua", code: "505", numberOfDigits: 8 },
  { name: "Niger", code: "227", numberOfDigits: 8 },
  { name: "Nigeria", code: "234", numberOfDigits: 8 },
  { name: "Niue", code: "683", numberOfDigits: 4 },
  { name: "Norfolk Island", code: "672", numberOfDigits: 6 },
  { name: "North Korea", code: "850", numberOfDigits: 7 },
  { name: "Northern Mariana Islands", code: "1670", numberOfDigits: 7 },
  { name: "Norway", code: "47", numberOfDigits: 8 },
  { name: "Oman", code: "968", numberOfDigits: 8 },
  { name: "Pakistan", code: "92", numberOfDigits: 10 },
  { name: "Palau", code: "680", numberOfDigits: 7 },
  { name: "Palestinian Territory", code: "970", numberOfDigits: 9 },
  { name: "Panama", code: "507", numberOfDigits: 8 },
  { name: "Papua New Guinea", code: "675", numberOfDigits: 8 },
  { name: "Paraguay", code: "595", numberOfDigits: 9 },
  { name: "Peru", code: "51", numberOfDigits: 9 },
  { name: "Philippines", code: "63", numberOfDigits: 10 },
  { name: "Pitcairn", code: "64", numberOfDigits: 5 },
  { name: "Poland", code: "48", numberOfDigits: 9 },
  { name: "Portugal", code: "351", numberOfDigits: 9 },
  { name: "Puerto Rico", code: "1", numberOfDigits: 10 },
  { name: "Qatar", code: "974", numberOfDigits: 8 },
  { name: "Romania", code: "40", numberOfDigits: 9 },
  { name: "Russia", code: "7", numberOfDigits: 10 },
  { name: "Rwanda", code: "250", numberOfDigits: 9 },
  { name: "Reunion", code: "262", numberOfDigits: 9 },
  { name: "Saint Barthelemy", code: "590", numberOfDigits: 9 },
  { name: "Saint Helena and Ascension", code: "290", numberOfDigits: 5 },
  { name: "Saint Kitts and Nevis", code: "1869", numberOfDigits: 7 },
  { name: "Saint Lucia", code: "1758", numberOfDigits: 7 },
  { name: "Saint Martin", code: "590", numberOfDigits: 9 },
  { name: "Sint Maarten", code: "1721", numberOfDigits: 10 },
  { name: "Saint Pierre and Miquelon", code: "508", numberOfDigits: 6 },
  { name: "Saint Vincent and Grenadines", code: "1784", numberOfDigits: 7 },
  { name: "Samoa", code: "685", numberOfDigits: 5 },
  { name: "San Marino", code: "378", numberOfDigits: 8 },
  { name: "Sao Tome and Principe", code: "239", numberOfDigits: 7 },
  { name: "Saudi Arabia", code: "966", numberOfDigits: 9 },
  { name: "Senegal", code: "221", numberOfDigits: 9 },
  { name: "Serbia", code: "381", numberOfDigits: 9 },
  { name: "Seychelles", code: "248", numberOfDigits: 6 },
  { name: "Sierra Leone", code: "232", numberOfDigits: 6 },
  { name: "Singapore", code: "65", numberOfDigits: 8 },
  { name: "Slovakia", code: "421", numberOfDigits: 9 },
  { name: "Slovenia", code: "386", numberOfDigits: 8 },
  { name: "Solomon Islands", code: "677", numberOfDigits: 7 },
  { name: "Somalia", code: "252", numberOfDigits: 7 },
  { name: "South Africa", code: "27", numberOfDigits: 9 },
  { name: "South Georgia Islands", code: "500", numberOfDigits: 5 },
  { name: "South Korea", code: "82", numberOfDigits: 10 },
  { name: "South Sudan", code: "211", numberOfDigits: 9 },
  { name: "Spain", code: "34", numberOfDigits: 9 },
  { name: "Sri Lanka", code: "94", numberOfDigits: 7 },
  { name: "Sudan", code: "249", numberOfDigits: 9 },
  { name: "Suriname", code: "597", numberOfDigits: 7 },
  { name: "Svalbard and Jan Mayen", code: "47", numberOfDigits: 8 },
  { name: "Swaziland", code: "268", numberOfDigits: 8 },
  { name: "Sweden", code: "46", numberOfDigits: 7 },
  { name: "Switzerland", code: "41", numberOfDigits: 9 },
  { name: "Syrian Arab Republic", code: "963", numberOfDigits: 9 },
  { name: "Taiwan", code: "886", numberOfDigits: 9 },
  { name: "Tajikistan", code: "992", numberOfDigits: 9 },
  { name: "Tanzania", code: "255", numberOfDigits: 9 },
  { name: "Thailand", code: "66", numberOfDigits: 9 },
  { name: "Timor-Leste", code: "670", numberOfDigits: 8 },
  { name: "Togo", code: "228", numberOfDigits: 8 },
  { name: "Tokelau", code: "690", numberOfDigits: 4 },
  { name: "Tonga", code: "676", numberOfDigits: 7 },
  { name: "Trinidad and Tobago", code: "1868", numberOfDigits: 7 },
  { name: "Tunisia", code: "216", numberOfDigits: 8 },
  { name: "Turkey", code: "90", numberOfDigits: 10 },
  { name: "Turkmenistan", code: "993", numberOfDigits: 8 },
  { name: "Turks and Caicos Islands", code: "1649", numberOfDigits: 7 },
  { name: "Tuvalu", code: "688", numberOfDigits: 6 },
  { name: "UAE", code: "971", numberOfDigits: 9 },
  { name: "Uganda", code: "256", numberOfDigits: 9 },
  { name: "Ukraine", code: "380", numberOfDigits: 9 },
  { name: "Uruguay", code: "598", numberOfDigits: 8 },
  { name: "Uzbekistan", code: "998", numberOfDigits: 9 },
  { name: "Vanuatu", code: "678", numberOfDigits: 7 },
  { name: "Venezuela", code: "58", numberOfDigits: 7 },
  { name: "Vietnam", code: "84", numberOfDigits: 9 },
  { name: "Virgin Islands, British", code: "1284", numberOfDigits: 6 },
  { name: "Virgin Islands, U.S.", code: "1340", numberOfDigits: 7 },
  { name: "Wallis and Futuna", code: "681", numberOfDigits: 6 },
  { name: "Western Sahara", code: "212", numberOfDigits: 9 },
  { name: "Yemen", code: "967", numberOfDigits: 9 },
  { name: "Zambia", code: "260", numberOfDigits: 9 },
  { name: "Zimbabwe", code: "263", numberOfDigits: 9 },
];
