const ArrowDown = () => {
    return (
        <svg
            width="19"
            height="17"
            viewBox="0 0 9 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginLeft: "4px" }}
        >
            <path d="M4.5 7L8.39711 0.25H0.602886L4.5 7Z" fill="#F34860" />
        </svg>
    );
};

export default ArrowDown;
