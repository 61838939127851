import { useState } from "react";
import mixpanel from "mixpanel-browser";

import Info from "components/shared/icons/Info";
import { Tooltip } from "components/shared/Tooltip";
import { currencyDisplay } from "components/shared/currency";
import { converterCurrency } from "components/shared/convertCurrency";
import { numberWithCommas } from "components/shared/NumberCommaAddition";
// import { buyingData } from "./powerData";

const BuyingPower = ({ currencyRate, currency, clickedData }) => {
    const [hover, setHover] = useState(false);

    const mixPanelTrack = () => {
        // mix panel
        mixpanel.track("ReportClicked", {
            view: "Conversions",
            report: "Clicked Value",
        });
    };

    // Code to max limits
    // let maxValue = Math.max(...buyingData.map((o) => o.value));
    // let maxUsers = Math.max(...buyingData.map((o) => o.users));

    //Function to calculate width
    // const getWidth = (points, type) => {
    //     let styles = {
    //         width: "0%",
    //     };

    //     let percentage;
    //     if (type === "users") {
    //         percentage = (points / maxUsers) * 100;
    //     } else {
    //         percentage = (points / maxValue) * 100;
    //     }

    //     styles.width = percentage + "%";
    //     return styles;
    // };

    return (
        <div
            className="flex flex-col relative overflow-scroll h-320 w-full bg-white dark:bg-theme-bgDark rounded-xl pb-3 px-4 mr-4 mb-4"
            style={{ maxWidth: "800px" }}
            onClick={() => mixPanelTrack()}
        >
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between pb-4 sticky top-0 left-0 bg-white dark:bg-theme-bgDark pt-3 z-30">
                <div
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    className="flex min-w-full md:min-w-min overflow-scroll items-center mb-2 md:mb-0 bg-white dark:bg-theme-bgDark z-30"
                >
                    <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                        Clicked Value
                    </h5>
                    <p className="relative cursor-pointer">
                        <Info />
                    </p>
                    {hover && (
                        <span className="absolute top-4 left-4">
                            <Tooltip
                                left="0px"
                                text="This shows the conversions across different countries and Clicked values, Users and Average clicked values across those."
                            />
                        </span>
                    )}
                </div>
            </div>

            <table className="overflow-y-scroll inline-table w-full">
                <thead className="w-full sticky z-20" style={{ top: "47.5px" }}>
                    <tr className="bg-gray-100  text-blue-600 dark:bg-black dark:text-cyan-400 text-xs font-bold text-center p-2">
                        <th className="pl-2 py-2 text-left bg-gray-100 dark:bg-black">Country</th>
                        <th className="text-left bg-gray-100 dark:bg-black">Clicked Value</th>
                        <th className="text-left bg-gray-100 dark:bg-black">Users</th>
                        <th className="text-left bg-gray-100 dark:bg-black">Avg. Buying Power</th>
                    </tr>
                </thead>
                <tbody className="w-full text-sm font-normal dark:text-white text-center ">
                    {clickedData.map((data, index) => (
                        <tr className="relative" key={index}>
                            <td className="flex font-bold items-center text-left pt-4 pb-2 ml-3 bg-white dark:bg-theme-bgDark">
                                {data.country}
                            </td>

                            <td className="pt-4 text-left pl-1">
                                <div className="flex items-center">
                                    <p className="min-w-50">
                                        {currencyDisplay(currency)}
                                        {numberWithCommas(
                                            converterCurrency(data?.clickedValue, currencyRate),
                                            currency
                                        )}
                                    </p>
                                    {/* <div className="w-20 h-4">
                                        <p
                                            className="bg-theme-blue h-full"
                                            style={
                                                maxValue === Number(data.value)
                                                    ? {
                                                          width: "100%",
                                                      }
                                                    : getWidth(data.value, "value")
                                            }
                                        ></p>
                                    </div>*/}
                                </div>
                            </td>
                            <td className="pr-2 pt-4 text-left">
                                <div className="flex items-center">
                                    <p className="min-w-50">
                                        {numberWithCommas(data.clickedUsers, currency)}
                                    </p>
                                </div>
                            </td>
                            <td className="pt-4 text-left w-40">
                                {currencyDisplay(currency)}
                                {numberWithCommas(
                                    converterCurrency(data?.averageClickedValue, currencyRate),
                                    currency
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="flex items-center justify-end w-full h-2">
                {/* <h4 className="text-sm font-medium mr-0.5 flex items-center text-green-500">
            <ArrowUp />
            <p className="ml-0.5">5.34%</p>
        </h4>
        <p className="text-10 font-medium text-gray-500 italic dark:text-silver-light">
            valuable than average article valuation
        </p> */}
            </div>
        </div>
    );
};

export default BuyingPower;
