import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import mixpanel from "mixpanel-browser";

import Info from "components/shared/icons/Info";
import { Tooltip } from "components/shared/Tooltip";

const LineChartAI = ({ filter, data }) => {
    const [options, setOptions] = useState({});
    const [hover, setHover] = useState(false);

    const mixPanelTrack = () => {
        // mix panel
        mixpanel.track("ReportClicked", {
            view: "AI data",
            report: "Valued Articles",
        });
    };

    const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    // const setClass = (type) => {
    //     let _class = "cursor-pointer p-1 font-bold leading-3";
    //     if (type === tab) {
    //         _class =
    //             "text-light-blue border-b-2 border-light-blue p-1 text-white font-bold cursor-pointer leading-3";
    //     }

    //     return _class;
    // };

    const dateConverter = (date) => {
        let _date = new Date(date);
        _date = `${monthNames[_date.getMonth()]} ${_date.getDate()}`;

        return _date;
    };

    // Code to set max limit for Y axis
    let max = Math.max(...data.map((o) => o.valuedArticles));
    max = parseInt(max * 1.5);

    useEffect(() => {
        let sliceData = data;
        // if (tab === "7D") {
        //     sliceData = sliceData.slice(0, 7);
        // } else if (tab === "15D") {
        //     sliceData = sliceData.slice(0, 15);
        // } else if (tab === "1M") {
        //     sliceData = sliceData.slice(0, 30);
        // }

        // sliceData = sliceData.reverse();

        setOptions({
            title: {
                show: false,
                text: "Valued Articles",
            },
            color: localStorage.theme === "light" ? "#0075FF" : "#00FFFF",
            tooltip: {
                trigger: "item",
                textStyle: {
                    color: localStorage.theme === "light" ? "#000" : "#989898",
                },
                backgroundColor: localStorage.theme === "light" ? "#fff" : "#1D1D1D",
                axisPointer: {
                    type: "cross",
                },
            },
            legend: {
                show: false,
            },
            grid: {
                left: "1%",
                right: "3%",
                bottom: "1%",
                top: "4%",
                containLabel: true,
            },
            toolbox: {
                show: false,
            },
            xAxis: {
                type: "category",
                boundaryGap: false,
                data: sliceData.map((graphPoints) => dateConverter(graphPoints.date)),
            },
            yAxis: {
                type: "value",
                axisLine: {
                    show: true,
                    onZero: true,
                    onZeroAxisIndex: null,
                },
                max: max,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: localStorage.theme === "light" ? "#D1D1D1" : "#393939",
                        type: "dashed",
                        opacity: "0.6",
                    },
                },
            },
            series: [
                {
                    name: "Valued Articles",
                    type: "line",
                    data: sliceData.map((graphPoints) => graphPoints.valuedArticles),
                    showSymbol: false,
                    smooth: true,
                    lineStyle: {
                        color: localStorage.theme === "light" ? "#0075FF" : "#00FFFF",
                        width: 1.5,
                    },

                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: localStorage.theme === "light" ? "#A3CDFF" : "#0A5353",
                            },
                            {
                                offset: 1,
                                color: localStorage.theme === "light" ? "#E3F0FF" : "#0C2929",
                            },
                        ]),
                        // color: localStorage.theme === "light" ? "#C0DDFF" : "#0B3F3F",
                        // opacity: localStorage.theme === "light" ? 0.9 : 0.9,
                        // origin: 200,
                    },

                    emphasis: {
                        focus: "series",
                    },
                },
            ],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, data]);

    if (!options) return <div> Loading....</div>;

    return (
        <div
            className="flex flex-col flex-1 w-full bg-white dark:bg-theme-bgDark rounded-xl px-4 py-3"
            style={{ maxWidth: "820px" }}
            onClick={() => mixPanelTrack()}
        >
            <div className="flex items-center justify-between w-full">
                <div className="flex items-center justify-between w-full mb-2 pl-1 pr-5">
                    <div className="flex items-center">
                        <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                            Valued Articles
                        </h5>
                        <p
                            className="relative cursor-pointer"
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            <Info />
                            {hover && (
                                <Tooltip
                                    left="0px"
                                    text="This shows the total number of articles being valued by 'Kunato Valuation' on your website on a regular basis. This is also dependent on the number of articles being published every day."
                                />
                            )}
                        </p>
                    </div>
                    {/* <div className="flex items-center list-none text-11 text-silver-dark">
                        <li onClick={() => setTab("7D")} className={setClass("7D")}>
                            7D
                        </li>
                        <li
                            onClick={() => setTab("15D")}
                            className={setClass("15D")}
                            style={{ margin: "0 14px" }}
                        >
                            15D
                        </li>
                        <li onClick={() => setTab("1M")} className={setClass("1M")}>
                            1M
                        </li>
                    </div> */}
                </div>

                <div className="flex items-center"></div>
            </div>
            {options && (
                <ReactECharts
                    option={options}
                    style={{
                        display: " block",
                        width: "100%",
                        height: "220px",
                        maxWidth: "788px",
                    }}
                />
            )}
        </div>
    );
};

export default LineChartAI;
