import React from "react";

import bg1 from "assests/images/homesection7/hm.png";
import pub from "assests/images/homesection7/hm1.png";
import Component from "../Prices";

const SlideStep1 = ({ articlePrices }) => {
    const url = "https://www.hellomumbainews.com/current-news/hello-mumbai-says-hello-to-pricing/";

    const price = articlePrices[url];

    return (
        <div className="flex w-full items-center justify-between mb-4 min-w-165 max-w-169 lg:min-w-270 lg:max-w-270 overflow-hidden rounded-xl">
            <a
                href="https://www.hellomumbainews.com/current-news/hello-mumbai-says-hello-to-pricing/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col bg-white max-w-270 items-center justify-start text-center
                pb-16 h-72"
                style={{ borderRadius: "17px" }}
            >
                <div
                    className="mb-3 min-h-147 w-165 lg:w-270 flex items-end justify-end relative"
                    style={{
                        backgroundImage: `url(${bg1})`,
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "scroll",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                >
                    <div
                        className="w-full h-full absolute"
                        style={{
                            backgroundColor: "rgba(0,0,0,0.2)",
                        }}
                    ></div>
                    <div
                        className="w-full h-1/2 absolute"
                        style={{
                            background: "linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)",
                        }}
                    ></div>
                    {price && <Component price={price} url={url} idKey={url} />}
                </div>

                <h5 className="text-black text-sm font-bold mb-8 lg:mb-12">
                    Hello Mumbai Says Hello to Pricing
                </h5>
                <img src={pub} alt="img1" className="lg:w-32 w-28 mb-3" />
            </a>
        </div>
    );
};

export default SlideStep1;
