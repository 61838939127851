import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

const PieChart = ({ data }) => {
    const [options, setOptions] = useState({});

    useEffect(() => {
        setTimeout(() => {
            setOptions({
                // backgroundColor: "#2c343c",
                tooltip: {
                    show: true,
                    trigger: "item",
                },
                grid: {
                    left: "0%",
                    right: "0%",
                    bottom: "3%",
                    top: "2%",
                    containLabel: true,
                },
                legend: {
                    show: false,
                },

                series: [
                    {
                        type: "pie",
                        radius: "86%",
                        data: [
                            {
                                value: data?.totalPageViews,
                                name: "Users",
                                itemStyle: {
                                    color: localStorage.theme === "light" ? "#1D6BA6" : "#009393",
                                },
                            },
                            {
                                value: data?.clicks,
                                name: "Clicks",
                                itemStyle: {
                                    color: localStorage.theme === "light" ? "#25CA77" : "#00FFFF",
                                },
                            },
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 0,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                        label: {
                            show: true,
                            formatter: function (d) {
                                return d.name;
                            },
                            fontSize: "10",
                            color: localStorage.theme === "light" ? "#000" : "#fff",
                        },
                        labelLine: {
                            show: true,
                            lineStyle: {
                                color: localStorage.theme === "light" ? "#000" : "#fff",
                            },
                            smooth: 0.2,
                            length: 10,
                            length2: 20,
                        },
                    },
                ],
            });
        }, 150);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!options) return <div> Loading....</div>;

    return (
        <div className="flex flex-1 items-center justify-center">
            {options && (
                <ReactECharts
                    option={options}
                    style={{
                        display: "block",
                        width: "365px",
                        height: "180px",
                    }}
                />
            )}
        </div>
    );
};

export default PieChart;
