import { Tooltip } from "components/shared/Tooltip";
import Info from "components/shared/icons/Info";
import ReactECharts from "echarts-for-react";
import { useEffect, useState } from "react";

const AdsPieChart = ({ data }) => {
    const [hover, setHover] = useState(false);
    const [options, setOptions] = useState({});

    useEffect(() => {
        setOptions({
            tooltip: {
                trigger: "item",
            },

            series: [
                {
                    name: "Ad Status",
                    type: "pie",
                    radius: "90%",
                    data: [
                        { value: data?.total_ads_not_started, name: "Not loaded" },
                        { value: data?.total_ads_completed, name: "Finshed" },
                        { value: data?.total_ads_dropped, name: "Dropped" },
                    ],
                    color: ["#ffee57", "#0085ff", "#25ca83"],
                    label: {
                        show: true,
                        position: "inside",
                        fontSize: 12,
                        fontWeight: 600,
                        color: "#fff",
                        textShadowColor: "#000",
                        textShadowBlur: 3,
                        overflow: "truncate",
                    },
                    minShowLabelAngle: 40,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)",
                        },
                    },
                },
            ],
        });
    }, [data?.total_ads_completed, data?.total_ads_dropped, data?.total_ads_not_started]);

    return (
        <div className="flex flex-1 w-full px-4 xl:px-3 flex-col items-center justify-center bg-white dark:bg-theme-bgDark rounded-xl pb-2 xl:max-w-[380px]">
            <div className="flex items-center justify-between w-full pt-4 pb-2">
                <div className="flex items-center ">
                    <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                        Ads Breakdown
                    </h5>
                    <p
                        className="relative cursor-pointer"
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                    >
                        <Info />
                        {hover && (
                            <Tooltip
                                left="-80px"
                                text="Breaks down ad metrics into 'Dropped,' 'Not loaded,' and 'Finished,' offering insights into ad delivery and performance."
                            />
                        )}
                    </p>
                </div>

                {/* <h6 className="dark:text-green-dark text-gray-500 text-10 font-bold">
                {convertTime(activeStartTime, true)} - {convertTime(activeEndTime, true)}
            </h6> */}
            </div>
            {options && (
                <ReactECharts
                    option={options}
                    style={{
                        display: "block",
                        width: "100%",
                        height: "275px",
                        maxWidth: "366px",
                    }}
                />
            )}
            <div className="text-white font-bold">Total Ads Displayed: {data?.total_ads}</div>
        </div>
    );
};
export default AdsPieChart;
