const HomeFeature3 = () => {
    return (
        <svg
            width="47"
            height="47"
            viewBox="0 0 47 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="23.5" cy="23.5" r="23.5" fill="black" />
            <path
                d="M13 15V20.25H14.3125C14.3125 18.8062 15.4937 17.625 16.9375 17.625H20.875V32.0625C20.875 32.7975 20.2975 33.375 19.5625 33.375H18.25V36H28.75V33.375H27.4375C26.7025 33.375 26.125 32.7975 26.125 32.0625V17.625H30.0625C31.5063 17.625 32.6875 18.8062 32.6875 20.25H34V15H13Z"
                fill="#00FFFF"
            />
        </svg>
    );
};

export default HomeFeature3;
