import { Tooltip } from "components/shared/Tooltip";
import Info from "components/shared/icons/Info";
import { useState } from "react";

const ConversionRate = ({ data, currency }) => {
    const [hover, setHover] = useState(false);
    return (
        <div
            className="flex flex-col bg-white dark:bg-theme-bgDark py-3.5 pl-3 pr-2 md:pr-5 rounded-xl h-28"
            style={{ width: "184px" }}
        >
            <div className="flex items-center">
                <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                    Conversion Rate
                </h5>
                <p
                    className="relative cursor-pointer"
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <Info />
                    {hover && (
                        <Tooltip
                            left="-80px"
                            text="Measures the percentage of users who make a purchase after viewing content, gauging the effectiveness of the sales funnel."
                        />
                    )}
                </p>
            </div>
            <h4
                className={
                    data > 50
                        ? "text-2xl font-medium mt-3 mb-4 dark:text-[#25CA83]"
                        : "text-2xl font-medium mt-3 mb-4 dark:text-[#f34860]"
                }
            >
                {data?.toFixed(2)}%
            </h4>
        </div>
    );
};
export default ConversionRate;
