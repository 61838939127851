import { useState } from "react";

import CountryCodeModal from "./CountryCodeModal";
import Close from "components/shared/icons/Close";
import ChevronDown from "components/shared/icons/ChevronDown";
import { countriesWithCodes } from "./countryCodes";

const Form = ({
    form,
    setForm,
    handleSubmit,
    nameValid,
    emailValid,
    numberValid,
    designationValid,
    addressValid,
    urlValid,
    countryCode,
    setCountryCode,
    setNameValid,
    setAddressValid,
    setNumberValid,
    setEmailValid,
    setDesignationValid,
    setUrlValid,
}) => {
    const [codeModal, setCodeModal] = useState(false);
    let _form = form;

    const setClass = (valid) => {
        let cls = "w-full bg-black border border-blue-900 rounded p-3 focus:outline-none";

        if (valid) {
            cls = "w-full bg-black border border-red-400 rounded p-3 focus:outline-none";
        }
        return cls;
    };

    // Function to handle form input change
    const handleChange = (field, event) => {
        const regNumber = /^[0-9\b]+$/;
        let _form;
        const value = event.target.value;
        _form = { ...form };
        if (field === "contactNumber") {
            if (value === "" || regNumber.test(value)) {
                _form[field] = value;
            }
        } else {
            _form[field] = value;
        }
        setForm(_form);
    };

    // Function to handle website url input change
    const handleUrls = (index, event) => {
        setUrlValid((prevState) => {
            const temp = [...prevState];
            temp[index] = false;
            return temp;
        });
        let _form;
        _form = { ...form };
        _form.websiteUrls[index] = event.target.value;
        setForm(_form);
    };

    //Function to add website utl input field
    const handleAdd = () => {
        const urlElms = document.querySelectorAll("input[placeholder='Website URL']");
        if (urlElms[urlElms.length - 1]?.value.length < 4) {
            if (urlElms.length > 2) {
                urlElms[urlElms.length - 1]?.focus();
            } else {
                urlElms[0]?.focus();
            }
        } else {
            let _form;
            _form = { ...form };
            _form.websiteUrls.push("");
            setForm(_form);
            setTimeout(() => {
                const elms = document.querySelectorAll("input[placeholder='Website URL']");
                elms[elms.length - 1]?.focus();
            }, 100);
        }
    };

    //Function to remove website utl input field
    const handleRemove = (index) => {
        let _form;
        _form = { ...form };
        // delete _form.websiteUrls[index];
        _form.websiteUrls.splice(index, 1);
        setUrlValid((prevState) => {
            const temp = [...prevState];
            temp.splice(index, 1);
            return temp;
        });
        setForm(_form);
    };

    return (
        <div className="flex flex-col items-start w-full max-w-716 px-4 lg:px-0 mb-12">
            {/* <h6 className="text-base font-bold mb-1.5"> Partner Details</h6> */}

            <form className="w-full" onSubmit={(e) => handleSubmit(e)}>
                <div className="flex flex-col items-center justify-between lg:flex-row w-full">
                    <div className="w-full mb-6 lg:max-w-343">
                        <input
                            type="text"
                            placeholder="Full Name"
                            value={form.name}
                            onChange={(e) => handleChange("name", e)}
                            className={setClass(nameValid)}
                            onFocus={() => setNameValid(false)}
                            onBlur={(e) => {
                                e.target.value = e.target.value.trim();
                                handleChange("name", e);
                                if (e.target.value.length < 3) {
                                    setNameValid(true);
                                }
                            }}
                            required
                        />
                        {nameValid && (
                            <div className="absolute text-xs text-red-500 font-semibold">
                                Name too short
                            </div>
                        )}
                    </div>
                    <div className="w-full mb-6 lg:max-w-343 flex items-center relative">
                        <div
                            onClick={() => setCodeModal(!codeModal)}
                            className="flex text-white cursor-pointer items-center justify-between w-20 relative mr-1.5 bg-black border border-blue-900 rounded p-3 focus:outline-none"
                        >
                            +{countryCode}
                            <ChevronDown color={"#78797C"} />
                            {codeModal && (
                                <CountryCodeModal
                                    closeModal={() => setCodeModal(false)}
                                    setCountryCode={setCountryCode}
                                />
                            )}
                        </div>
                        <input
                            type="text"
                            placeholder="Contact Number"
                            id="contactNumber"
                            value={form.contactNumber}
                            onChange={(e) => handleChange("contactNumber", e)}
                            className={setClass(numberValid)}
                            onFocus={() => setNumberValid(false)}
                            maxLength={15}
                            onBlur={(e) => {
                                const currentCountry = countriesWithCodes.find(
                                    (item) => item.code === countryCode
                                );

                                if (
                                    e.target.value.length < currentCountry.numberOfDigits ||
                                    e.target.value.length > 15
                                ) {
                                    setNumberValid(true);
                                }
                            }}
                            required
                        />
                        {numberValid && (
                            <div className="absolute -bottom-4 text-xs text-red-500 font-semibold">
                                Invalid Number
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex flex-col items-center justify-between lg:flex-row w-full">
                    <div className="w-full mb-6 lg:max-w-343">
                        <input
                            type="text"
                            placeholder="Designation"
                            value={form.designation}
                            onChange={(e) => handleChange("designation", e)}
                            className={setClass(designationValid)}
                            onFocus={() => setDesignationValid(false)}
                            onBlur={(e) => {
                                e.target.value = e.target.value.trim();
                                handleChange("designation", e);
                                if (e.target.value.length < 3) {
                                    setDesignationValid(true);
                                }
                            }}
                            required
                        />
                        {designationValid && (
                            <div className="absolute text-xs text-red-500 font-semibold">
                                Designation too short
                            </div>
                        )}
                    </div>
                    <div className="w-full mb-6 lg:max-w-343">
                        <input
                            type="email"
                            placeholder="Official Email "
                            value={form.email}
                            onChange={(e) => handleChange("email", e)}
                            className={setClass(emailValid)}
                            onFocus={() => setEmailValid(false)}
                            onBlur={(e) => {
                                e.target.value = e.target.value.trim();
                                handleChange("email", e);
                                const regex =
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                if (!regex.test(e.target.value)) {
                                    setEmailValid(true);
                                }
                            }}
                            required
                        />
                        {emailValid && (
                            <div className="absolute text-xs text-red-500 font-semibold">
                                Invalid Email
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex flex-col items-center justify-between lg:flex-row w-full">
                    <input
                        type="text"
                        placeholder="Company Name (Optional)"
                        value={form.companyName}
                        onChange={(e) => handleChange("companyName", e)}
                        onBlur={(e) => {
                            e.target.value = e.target.value.trim();
                            handleChange("companyName", e);
                        }}
                        className="w-full mb-8 lg:max-w-343 bg-black border border-blue-900 rounded p-3 focus:outline-none"
                    />
                    <div className="mb-8 relative w-full lg:max-w-343">
                        <input
                            type="text"
                            placeholder="Website URL"
                            value={form.websiteUrls[0]}
                            onChange={(e) => handleUrls(0, e)}
                            className={setClass(urlValid[0])}
                            onBlur={(e) => {
                                e.target.value = e.target.value.trim();
                                handleUrls(0, e);
                                try {
                                    const url = new URL(e.target.value);
                                } catch (err) {
                                    setUrlValid((prevState) => {
                                        const temp = [...prevState];
                                        temp[0] = true;
                                        return temp;
                                    });
                                }
                            }}
                            required
                        />
                        {urlValid[0] && (
                            <div className="absolute text-xs text-red-500 font-semibold">
                                Enter proper url including http(s)
                            </div>
                        )}
                        <p
                            className="text-sm text-blue2 font-medium absolute top-14 right-2 cursor-pointer"
                            onClick={() => handleAdd()}
                        >
                            +Add More Websites
                        </p>
                    </div>
                </div>
                {_form.websiteUrls.map((urls, index) => (
                    <div
                        className={
                            index === 0
                                ? "hidden"
                                : "flex flex-col items-start justify-between lg:flex-row w-full relative"
                        }
                        key={index}
                    >
                        <input
                            type="text"
                            placeholder="Website URL"
                            value={urls}
                            onChange={(e) => handleUrls(index, e)}
                            className="w-full mb-6 bg-black border border-blue-900 rounded p-3 focus:outline-none"
                            onBlur={(e) => {
                                e.target.value = e.target.value.trim();
                                handleUrls(index, e);
                                try {
                                    const url = new URL(e.target.value);
                                } catch (err) {
                                    setUrlValid((prevState) => {
                                        const temp = [...prevState];
                                        temp[index] = true;
                                        return temp;
                                    });
                                }
                            }}
                            required
                        />
                        {urlValid[index] && (
                            <div className="absolute bottom-2 text-xs text-red-500 font-semibold">
                                Enter proper url including http(s)
                            </div>
                        )}
                        <div
                            className="ml-2 mt-1 p-2.5
                             cursor-pointer"
                            style={{ backgroundColor: "#004483" }}
                            onClick={() => handleRemove(index)}
                        >
                            <Close />
                        </div>
                    </div>
                ))}

                <div className="flex flex-col items-center justify-between lg:flex-row w-full">
                    <div className="w-full mb-6">
                        <input
                            type="text"
                            placeholder="Company Address"
                            value={form.companyAddress}
                            onChange={(e) => handleChange("companyAddress", e)}
                            className={setClass(addressValid)}
                            onFocus={() => setAddressValid(false)}
                            onBlur={(e) => {
                                e.target.value = e.target.value.trim();
                                handleChange("companyAddress", e);
                                if (e.target.value.length < 3) {
                                    setAddressValid(true);
                                }
                            }}
                            required
                        />
                        {addressValid && (
                            <div className="absolute text-xs text-red-500 font-semibold">
                                Address too short
                            </div>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Form;
