import { Tooltip } from "components/shared/Tooltip";
import Info from "components/shared/icons/Info";
import ReactECharts from "echarts-for-react";
import { useEffect, useState } from "react";

const SmadsBreakdown = ({ data }) => {
    const [hover, setHover] = useState(false);
    const [options, setOptions] = useState({});

    useEffect(() => {
        setOptions({
            tooltip: {
                trigger: "item",
            },

            series: [
                {
                    name: "Unlocked From",
                    type: "funnel",
                    width: "90%",
                    height: "90%",
                    min: 0,
                    left: "5%",
                    top: "5%",
                    minSize: "0%",
                    maxSize: "100%",
                    max: data?.total_smads_solved * 3,
                    sort: "none",
                    gap: 0,
                    data: [
                        { value: data?.total_smads, name: "Total" },
                        { value: data?.total_smads_solved, name: "Solved" },
                    ],
                    color: ["#25CA83", "#436CFF"],
                    label: {
                        show: true,
                        position: "inside",
                        fontSize: 12,
                        fontWeight: 600,
                        color: "#fff",
                        overflow: "truncate",
                    },

                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)",
                        },
                    },
                },
            ],
        });
    }, [data?.total_smads, data?.total_smads_solved]);

    return (
        <div className="flex flex-1 w-full px-4 xl:px-3 xl:mr-4 flex-col items-center justify-center bg-white dark:bg-theme-bgDark rounded-xl pb-2 xl:max-w-[380px]">
            <div className="flex items-center justify-between w-full pt-4 pb-2">
                <div className="flex items-center ">
                    <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                        SMADs Breakdown
                    </h5>
                    <p
                        className="relative cursor-pointer"
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                    >
                        <Info />
                        {hover && (
                            <Tooltip
                                left="-80px"
                                text="Provides the total number of internal captcha ads shown and how many were completed, measuring their effectiveness."
                            />
                        )}
                    </p>
                </div>

                {/* <h6 className="dark:text-green-dark text-gray-500 text-10 font-bold">
                {convertTime(activeStartTime, true)} - {convertTime(activeEndTime, true)}
            </h6> */}
            </div>
            {options && (
                <ReactECharts
                    option={options}
                    style={{
                        display: "block",
                        width: "100%",
                        height: "275px",
                        maxWidth: "366px",
                    }}
                />
            )}
            <div className="text-white font-bold">
                Success Rate: {((data?.total_smads_solved / data?.total_smads) * 100).toFixed(2)}%
            </div>
        </div>
    );
};
export default SmadsBreakdown;
