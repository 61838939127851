import ReactECharts from "echarts-for-react";

const CandleGraph = ({ graphData, timeFilter, setTimeFilter }) => {
    const setClass = (_time) => {
        let _class = "text-lg font-semibold px-1 mx-1";

        if (timeFilter === _time) {
            _class = "text-lg font-semibold bg-[#CCCCCC] text-black px-1 mx-1";
        }

        return _class;
    };

    const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    const dateConverter = (date) => {
        let _date = new Date(date);
        _date = `${monthNames[_date.getMonth()]} ${_date.getDate()}`;

        return _date;
    };

    const options = {
        grid: {
            show: false,
            z: 0,
            left: "3%",
            top: 10,
            right: "1%",
            bottom: 20,
            containLabel: false,
            backgroundColor: "rgba(0, 0, 0, 0)",
            borderWidth: 1,
            borderColor: " #ccc",
        },
        tooltip: {
            show: false,
            trigger: "item",
        },
        xAxis: {
            data: graphData.map((graphPoints) => dateConverter(graphPoints.time)),
        },
        yAxis: {
            axisLine: {
                show: true,
                onZero: true,
                onZeroAxisIndex: null,
            },
            axisLabel: {
                fontSize: 11,
                formatter: "{value}",
            },
            splitLine: {
                show: false,
            },
        },
        series: [
            {
                type: "candlestick",
                data: graphData.map((data) => [data.close, data.open, data.low, data.high]),
                // data: [
                //     [20, 30, 20, 38],
                //     [40, 35, 30, 50],
                //     [31, 38, 33, 44],
                //     [38, 15, 5, 42],

                // ],
            },
        ],
    };

    if (!options) return <div> Loading....</div>;

    return (
        <div className="flex flex-1 flex-col border border-gray-300 dark:border-[#222323] py-4 px-2.5 rounded-md">
            <div className="flex items-center justify-between w-full text-black dark:text-white mb-6">
                <h6 className="text-xl font-bold">Performance</h6>
                <div className="flex items-center">
                    <button onClick={() => setTimeFilter("6h")} className={setClass("6h")}>
                        6H
                    </button>
                    <button onClick={() => setTimeFilter("1d")} className={setClass("1d")}>
                        1D
                    </button>
                    <button onClick={() => setTimeFilter("30d")} className={setClass("30d")}>
                        1M
                    </button>
                </div>
            </div>
            {options && (
                <ReactECharts
                    option={options}
                    style={{
                        display: "block",
                        width: "100%",
                        minWidth: "368px",
                        height: "202px",
                    }}
                />
            )}
        </div>
    );
};

export default CandleGraph;
