import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

const LineChart = ({ filter, data }) => {
    const [options, setOptions] = useState({});

    // const generate = (type) => {
    //     let arr = [];
    //     let max;
    //     let min;

    //     if (type === "user") {
    //         max = 6000;
    //         min = 3000;
    //     } else if (type === "gi") {
    //         max = 3000;
    //         min = 1200;
    //     } else if (type === "clicks") {
    //         max = 1200;
    //         min = 800;
    //     }

    //     for (let i = 0, t = filter; i < t; i++) {
    //         arr.push(Math.round(Math.random() * (max - min + 1)) + min);
    //     }
    //     return arr;
    // };

    const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    const dateConverter = (date) => {
        let _date = new Date(date);
        _date = `${monthNames[_date.getMonth()]} ${_date.getDate()}`;

        return _date;
    };

    // Code to set max limit for Y axis
    let max = Math.max(...data.map((o) => o.clicks));
    let max2 = Math.max(...data.map((o) => o.graphInteractions));
    if (max2 > max) {
        max = max2;
    }
    max = parseInt(max * 1.45);

    useEffect(() => {
        setTimeout(() => {
            setOptions({
                title: {
                    show: false,
                    text: "User Graph Interactions",
                },
                color:
                    localStorage.theme === "light"
                        ? ["#F34860", "#0075FF", "#02AFAF"]
                        : ["#FF8596", "#02AFAF", "#0075FF"],
                tooltip: {
                    trigger: "item",
                    textStyle: {
                        color: localStorage.theme === "light" ? "#000" : "#989898",
                        fontSize: 12,
                    },
                    backgroundColor: localStorage.theme === "light" ? "#fff" : "#1D1D1D",
                    axisPointer: {
                        type: "cross",
                    },
                },
                legend: {
                    show: false,
                },
                grid: {
                    left: "2%",
                    right: "3%",
                    bottom: "1%",
                    top: "4%",
                    containLabel: true,
                },
                toolbox: {
                    show: false,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: data.map((graphPoints) => dateConverter(graphPoints.date)),
                },
                yAxis: {
                    type: "value",
                    axisLine: {
                        show: true,
                        onZero: true,
                        onZeroAxisIndex: null,
                    },
                    max: max,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: localStorage.theme === "light" ? "#D1D1D1" : "#393939",
                            type: "dashed",
                            opacity: "0.6",
                        },
                    },
                },
                series: [
                    {
                        name: "Users",
                        type: "line",
                        data: data.map((graphPoints) => graphPoints.users),
                        showSymbol: false,
                        smooth: true,
                        lineStyle: {
                            color: localStorage.theme === "light" ? "#F34860" : "#FF8596",
                            width: 1.5,
                            opacity: localStorage.theme === "light" ? 1 : 0.8,
                        },
                        emphasis: {
                            focus: "series",
                        },
                    },
                    {
                        name: "Graph Interactions",
                        type: "line",
                        data: data.map((graphPoints) => graphPoints.graphInteractions),
                        showSymbol: false,
                        smooth: true,
                        lineStyle: {
                            color: localStorage.theme === "light" ? "#0075FF" : "#00FFFF",
                            width: 1.5,
                        },
                        emphasis: {
                            focus: "series",
                        },
                    },
                    {
                        name: "Clicks",
                        type: "line",
                        data: data.map((graphPoints) => graphPoints.clicks),
                        showSymbol: false,
                        smooth: true,
                        lineStyle: {
                            color: localStorage.theme === "light" ? "#1D6BA6" : "#0075FF",
                            width: 1.5,
                        },
                        areaStyle: {
                            color: localStorage.theme === "light" ? "#DFDFDF" : "#05283C",
                            opacity: localStorage.theme === "light" ? 0.9 : 0.9,
                        },

                        emphasis: {
                            focus: "series",
                        },
                    },
                ],
            });
        }, 150);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    if (!options) return <div> Loading....</div>;

    return (
        <div className="flex flex-1">
            {options && (
                <ReactECharts
                    option={options}
                    style={{
                        display: " block",
                        width: "100%",
                        minHeight: "207px",
                        maxWidth: "760px",
                    }}
                />
            )}
        </div>
    );
};

export default LineChart;
