const UnCheck = () => {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.00008 1.1665C3.78008 1.1665 1.16675 3.77984 1.16675 6.99984C1.16675 10.2198 3.78008 12.8332 7.00008 12.8332C10.2201 12.8332 12.8334 10.2198 12.8334 6.99984C12.8334 3.77984 10.2201 1.1665 7.00008 1.1665ZM7.00008 11.6665C4.42758 11.6665 2.33341 9.57234 2.33341 6.99984C2.33341 4.42734 4.42758 2.33317 7.00008 2.33317C9.57258 2.33317 11.6667 4.42734 11.6667 6.99984C11.6667 9.57234 9.57258 11.6665 7.00008 11.6665ZM9.67758 4.4215L5.83341 8.26567L4.32258 6.76067L3.50008 7.58317L5.83341 9.9165L10.5001 5.24984L9.67758 4.4215Z"
                fill="#565656"
            />
        </svg>
    );
};

export default UnCheck;
