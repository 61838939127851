import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import Header from "components/shared/Header/index";
import Footer from "components/shared/Footer";
import ScrollHeader from "components/shared/Header/ScrollHeader";
import HomeForm from "./Form";
import Section1 from "./section1";
// import Section2 from "./section2";
import Section3 from "./section3";
// import Section4 from "./section4";
import Section5 from "./section5";
import Section6 from "./section6";
import Features from "./section4/Features";
// import Slider from "./section6/Slider";

const HomePage = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    // animation logic
    const containerVariants = {
        hidden: {
            opacity: 0,
            y: -100,
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: { ease: "linear", delay: 0, duration: 0.3 },
        },
        exit: {
            opacity: 0,
            y: -100,
            transition: { ease: "linear", duration: 0.3 },
        },
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="flex flex-col flex-1 min-h-screen text-white bg-black relative">
            <div className="flex w-full ">
                <Header />
            </div>
            <AnimatePresence>
                {scrollPosition > 610 && (
                    <motion.div
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        className="flex w-full sticky top-0 z-50"
                    >
                        <ScrollHeader />
                    </motion.div>
                )}
            </AnimatePresence>

            <HomeForm />
            <Section1 />
            {/* <Section2 /> */}
            <Section3 />
            {/* <Section4 /> */}
            <Features />
            <Section5 />
            <Section6 />
            {/* <Slider /> */}
            <Footer />
        </div>
    );
};

export default HomePage;
