import React, { useState, useRef, useEffect } from "react";

import ChevronDown from "components/shared/icons/ChevronDown";
import DateModal from "./FilterModals/DateModal";
import CurrencyFilter from "./FilterModals/CurrencyFilter";

import usd from "assests/images/usd.png";
import inr from "assests/images/inr.png";
import eur from "assests/images/eur.png";
import gbp from "assests/images/gbp.png";
import jpy from "assests/images/jpy.png";
import cad from "assests/images/cad.png";
import aud from "assests/images/aud.png";
import aed from "assests/images/aed.png";
import ils from "assests/images/ils.png";
import cny from "assests/images/cny.png";
import brl from "assests/images/brl.png";

// import Download from "components/shared/icons/Download";

const Filters = ({ setFilter, currency, setCurrency, cacheTime, date, setDate }) => {
    const [dateModal, setDateModal] = useState(false);
    const [currencyFilter, setCurrencyFilter] = useState(false);
    const [dateValue, setDateValue] = useState("");

    let _date = date;

    if (localStorage.filterDates) {
        _date = JSON.parse(localStorage.filterDates);
    }

    // Function to reverse date format
    const reverseString = (string, add) => {
        if (!string) return string;
        if (add) {
            let temp = new Date(string);
            temp.setDate(temp.getDate() - 1);
            string = `${temp.getUTCFullYear()}-${temp.getUTCMonth() + 1}-${temp.getDate()}`;
        }

        let _string = string?.split("-");

        _string = _string?.reverse();

        //add zero for month
        if (_string[1].length === 1) {
            let month = 0 + _string[1];
            _string[1] = month;
        }

        // if (add) {
        //     let d = _string[0] - 1;
        //     if (d.length === 1) {
        //         _string[0] = `0${d}`;
        //     } else {
        //         _string[0] = d;
        //     }
        // }

        //add zero for day
        if (_string[0].length === 1) {
            let day = 0 + _string[0];
            _string[0] = day;
        }

        _string = _string?.join("-");
        return _string;
    };

    // const monthNames = [
    //     "January",
    //     "February",
    //     "March",
    //     "April",
    //     "May",
    //     "June",
    //     "July",
    //     "August",
    //     "September",
    //     "October",
    //     "November",
    //     "December",
    // ];

    // let dateObj = new Date(cacheTime);

    // dateObj = `${dateObj.getDate()} ${
    //     monthNames[dateObj.getUTCMonth()]
    // } ${dateObj.getFullYear()} ${dateObj.toLocaleTimeString()}`;

    const modalRef = useRef();

    useEffect(() => {
        const clickOutside = (event) => {
            if (!modalRef.current.contains(event.target)) {
                setCurrencyFilter(false);
            }
        };
        document.addEventListener("mousedown", clickOutside);
        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="flex z-40 w-11/12 xl:w-full 2xl:max-w-1200 flex-row-reverse md:flex-row items-center justify-between mt-2 mb-2.5 xl:pr-10 2xl:ml-0 2xl:pr-5">
            <div className="flex items-center">
                <div onClick={() => setDateModal(!dateModal)}>
                    <h5 className="text-11 font-normal flex items-center bg-white dark:bg-theme-bgDark dark:text-silver-light rounded-full px-3 py-1 cursor-pointer">
                        <p className="mr-4">
                            {_date.length > 0
                                ? `${reverseString(_date[0], false)
                                      ?.split("-")
                                      ?.join("/")} - ${reverseString(_date[1], true)
                                      ?.split("-")
                                      ?.join("/")}`
                                : "Start Date - Last Date"}
                        </p>
                        <ChevronDown />
                    </h5>
                </div>

                <div
                    className="relative ml-5"
                    style={{ width: "85px" }}
                    onClick={() => setCurrencyFilter(!currencyFilter)}
                    ref={modalRef}
                >
                    <h5 className="text-11 font-normal flex items-center bg-white dark:bg-theme-bgDark dark:text-silver-light rounded-full px-3 py-1 cursor-pointer">
                        <p className="mr-1 uppercase flex items-center pr-3">
                            {currency === "usd" && <img className="mr-2.5" src={usd} alt="usd" />}
                            {currency === "inr" && <img className="mr-2.5" src={inr} alt="inr" />}
                            {currency === "eur" && <img className="mr-2.5" src={eur} alt="eur" />}
                            {currency === "gbp" && <img className="mr-2.5" src={gbp} alt="gbp" />}
                            {currency === "jpy" && <img className="mr-2.5" src={jpy} alt="jpy" />}
                            {currency === "cad" && <img className="mr-2.5" src={cad} alt="cad" />}
                            {currency === "aud" && <img className="mr-2.5" src={aud} alt="aud" />}
                            {currency === "aed" && <img className="mr-2.5" src={aed} alt="aed" />}
                            {currency === "ils" && <img className="mr-2.5" src={ils} alt="ils" />}
                            {currency === "cny" && <img className="mr-2.5" src={cny} alt="cny" />}
                            {currency === "brl" && <img className="mr-2.5" src={brl} alt="brl" />}

                            {currency}
                        </p>
                        <ChevronDown />
                    </h5>
                    {currencyFilter && (
                        <CurrencyFilter
                            setCurrency={setCurrency}
                            closeModal={() => setCurrencyFilter(false)}
                        />
                    )}
                </div>

                {/* <div className="mr-5">
                    <h5 className="text-11 font-normal flex items-center bg-white dark:bg-theme-bgDark dark:text-silver-light rounded-full px-3.5 py-1 cursor-pointer">
                        <Download /> <p className="ml-2">Download Report</p>
                    </h5>
                </div> */}
            </div>
            {/* <h6 className="hidden md:flex flex-col text-xs text-right font-normal text-gray-500 italic dark:text-silver-light">
                <p>Last updated</p>
                {dateObj}
            </h6> */}
            {dateModal && (
                <DateModal
                    closeModal={() => {
                        console.log("here");
                        setDateModal(false);
                    }}
                    setScheduleDate={setDate}
                    setFilter={setFilter}
                    setDateValue={setDateValue}
                    dateValue={dateValue}
                    reverseString={reverseString}
                />
            )}
        </div>
    );
};

export default Filters;
