import React, { useState } from "react";
import mixpanel from "mixpanel-browser";

import Info from "components/shared/icons/Info";
// import Thumbnail from "assests/images/thumbnail.png";
// import CandlestickChart from "./CandleStick";
// import DemandBarChart from "./BarChart";
// import ChevronDown from "components/shared/icons/ChevronDown";
import PieChart from "./PieChart";
import { Tooltip } from "components/shared/Tooltip";
// import ChevronDownWhite from "components/shared/icons/ChevronDownWhite";

const Performance = ({ data }) => {
    const [hover, setHover] = useState(false);

    const mixPanelTrack = () => {
        // mix panel
        mixpanel.track("ReportClicked", {
            view: "Overview",
            report: "User Conversion",
        });
    };

    return (
        <div
            onClick={() => mixPanelTrack()}
            className="flex flex-col bg-white dark:bg-theme-bgDark rounded-xl py-4 px-4 pb-3 w-full xl:max-w-324 mb-4 xl:mb-0"
        >
            <div className="flex items-center justify-between mb-6">
                <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                        <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                            User Conversion
                        </h5>
                        <p
                            className="relative cursor-pointer"
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            <Info />
                            {hover && (
                                <Tooltip
                                    left="-80px"
                                    text="The User conversion shows the ratio between clicks and total users on different pages of the website."
                                />
                            )}
                        </p>
                    </div>
                    {/* <div className="relative" style={{ width: "115px" }}>
                        <h5 className="text-11 font-normal flex items-center justify-between text-white bg-theme-blue dark:bg-black dark:text-silver-light rounded-full px-3 cursor-pointer">
                            <p className="mr-5  flex items-center">Home</p>
                            {localStorage.theme === "light" ? (
                                <ChevronDownWhite />
                            ) : (
                                <ChevronDown />
                            )}
                        </h5>
                    </div> */}
                </div>
            </div>
            <PieChart data={data} />
            {/* <DemandBarChart /> */}
            {/* <CandlestickChart currency={currency} /> */}
        </div>
    );
};

export default Performance;
