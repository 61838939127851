import Check from "components/shared/icons/Check";
import UnCheck from "components/shared/icons/UnCheck";
import React, { useEffect, useState } from "react";

const PasswordChecker = ({ password }) => {
    const [length, setLength] = useState(false);
    const [lowerCase, setLowerCase] = useState(false);
    const [upperCase, setUpperCase] = useState(false);
    const [special, setSpecial] = useState(false);
    const [numeric, setNumeric] = useState(false);

    useEffect(() => {
        if (/[A-Z]/.test(password)) {
            setUpperCase(true);
        } else {
            setUpperCase(false);
        }

        if (/[a-z]/.test(password)) {
            setLowerCase(true);
        } else {
            setLowerCase(false);
        }

        if (/[0-9]/.test(password)) {
            setNumeric(true);
        } else {
            setNumeric(false);
        }

        if (password.length > 7) {
            setLength(true);
        } else {
            setLength(false);
        }

        if (/[[!@#$%^&*]/.test(password)) {
            setSpecial(true);
        } else {
            setSpecial(false);
        }
    }, [password]);

    return (
        <div>
            <h5 className="text-xs text-dark dark:text-white mt-3 mb-1">
                Your password must contain
            </h5>
            <div className="flex items-center">
                {length ? <Check /> : <UnCheck />}
                <p className="ml-2.5 text-11">At least 8 characters</p>
            </div>
            <div className="flex items-center">
                {lowerCase ? <Check /> : <UnCheck />}
                <p className="ml-2.5 text-11">At least 1 lowercase letter</p>
            </div>
            <div className="flex items-center">
                {upperCase ? <Check /> : <UnCheck />}
                <p className="ml-2.5 text-11">At least 1 uppercase letter</p>
            </div>
            <div className="flex items-center">
                {special ? <Check /> : <UnCheck />}
                <p className="ml-2.5 text-11">At least 1 special character</p>
            </div>
            <div className="flex items-center">
                {numeric ? <Check /> : <UnCheck />}
                <p className="ml-2.5 text-11">At least 1 numeric character</p>
            </div>
        </div>
    );
};

export default PasswordChecker;
