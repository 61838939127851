import { useState } from "react";

import Info from "components/shared/icons/Info";
import { dateConverter } from "components/shared/dateConverter";
import { Tooltip } from "components/shared/Tooltip";

import NoImage from "assests/images/No-image.png";
import Spinner from "components/shared/spinner";
import CategoryBarGraph from "./categoryGraph";

const TopLeft = ({ data, currency, currencyRate, fetchingArticleData }) => {
    let _color = localStorage.theme === "light" ? "#000" : "#fff";
    const [tab, setTab] = useState("articles");

    const [hover, setHover] = useState(false);

    // const [graphModal, setGraphModal] = useState(false);
    // const [articleInfo, setArticleInfo] = useState({});

    const setClass = (type) => {
        let _class = "cursor-pointer px-2 text-[#0075ff] bg-[#282828] rounded-xl p-1";
        if (type === tab) {
            _class = "bg-[#0075ff] rounded-xl p-1 text-white font-bold px-2 cursor-pointer";
        }

        return _class;
    };

    return (
        <div className="flex flex-col relative overflow-auto lg:h-[26.5rem] w-full bg-white dark:bg-theme-bgDark rounded-xl pb-3 px-6">
            <div className="flex flex-col items-start gap-4 justify-between pb-4 sticky top-0 left-0 bg-white dark:bg-theme-bgDark pt-3 z-30">
                <div
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    className="flex min-w-full md:min-w-min items-center mb-2 md:mb-0 bg-white dark:bg-theme-bgDark z-30 pt-3"
                >
                    <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                        Top Performing
                    </h5>
                    <p className="relative cursor-pointer">
                        <Info />
                    </p>
                    {hover && (
                        <span className="absolute top-4 left-4">
                            <Tooltip
                                left="0px"
                                text="Highlights the most-viewed articles and categories, offering insights into what attracts your audience."
                            />
                        </span>
                    )}
                </div>
                <div className="flex min-w-full md:min-w-min items-center list-none text-11 text-silver-dark dark:text-silver-medium bg-white dark:bg-theme-bgDark gap-4">
                    <li
                        onClick={() => {
                            setTab("articles");
                        }}
                        className={setClass("articles")}
                    >
                        Articles
                    </li>
                    <li
                        onClick={() => {
                            setTab("categories");
                        }}
                        className={setClass("categories")}
                    >
                        Categories
                    </li>
                </div>
            </div>
            {fetchingArticleData ? (
                <div className="flex flex-1 items-center justify-center w-full min-h-full">
                    <Spinner text={"Fetching data..."} />
                </div>
            ) : tab === "articles" ? (
                <table className="overflow-y-scroll inline-table relative w-full">
                    <thead className="w-full sticky z-20" style={{ top: "100px" }}>
                        <tr className="relative text-blue-600 dark:text-[#717171] text-xs font-bold text-center p-2">
                            <th className="pl-2 py-2 sticky left-0 bg-gray-100 dark:bg-theme-bgDark">
                                Rank
                            </th>
                            <th className="pl-3 sticky left-9 bg-gray-100 dark:bg-theme-bgDark">
                                Articles
                            </th>
                            <th className="pr-3 bg-gray-100 dark:bg-theme-bgDark">Published on</th>
                            <th className="pr-3 bg-gray-100 dark:bg-theme-bgDark">Views</th>
                            <th className="pr-2 capitalize bg-gray-100 dark:bg-theme-bgDark">
                                Unlocks
                            </th>
                        </tr>
                    </thead>
                    <tbody className="w-full text-xs font-normal dark:text-[#cfcfcf] text-center ">
                        {data?.topPerformingArticles?.map((article, index) => (
                            <tr key={index} className="relative">
                                <td
                                    className="py-2 pt-4 pl-1 sticky bg-white dark:bg-theme-bgDark left-0 text-base font-bold dark:text-white"
                                    style={index < 3 ? { color: "#0075FF" } : { color: _color }}
                                >
                                    {index + 1}
                                </td>

                                <td className="flex items-center text-left pt-4 pb-2 ml-3 sticky left-14 bg-white dark:bg-theme-bgDark">
                                    <button
                                        // onClick={() => {
                                        //     setArticleInfo(article);
                                        //     setGraphModal(true);
                                        // }}
                                        // target="_blank"
                                        // rel="noopener noreferrer"
                                        className="flex items-center"
                                    >
                                        <img
                                            src={article.thumbnail || NoImage}
                                            alt="img-article"
                                            className="dark:border border-silver-light w-11 min-w-44 h-6 mr-3"
                                        />
                                        <h4 className="truncate text-sm font-bold w-full max-w-150 md:max-w-[520px]">
                                            {article.title}
                                        </h4>
                                    </button>
                                </td>

                                <td className="pr-2 pt-4">
                                    {" "}
                                    {dateConverter(article.published_at)}
                                </td>
                                <td className="pr-2 pt-4 text-base font-bold">
                                    {article.total_views}
                                </td>
                                <td className="pr-2 pt-4 text-base font-bold w-24">
                                    {article.total_unlocks_for_url}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : tab === "categories" ? (
                <CategoryBarGraph data={data.topPerformingCategories} />
            ) : null}

            <div className="flex items-center justify-end w-full h-2">
                {/* <h4 className="text-sm font-medium mr-0.5 flex items-center text-green-500">
                    <ArrowUp />
                    <p className="ml-0.5">5.34%</p>
                </h4>
                <p className="text-10 font-medium text-gray-500 italic dark:text-silver-light">
                    valuable than average article valuation
                </p> */}
            </div>
        </div>
    );
};

export default TopLeft;
