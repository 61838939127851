// Function to add comma top number as currency
export const numberWithCommas = (number, currency) => {
    if (currency === "inr") {
        let _number = number.toString();
        let _number1 = _number.split(".")[0];

        let num1 = _number1;
        num1 = num1.toString();
        let lastThree = num1.substring(num1.length - 3);
        let otherNumbers = num1.substring(0, num1.length - 3);
        if (otherNumbers !== "") lastThree = "," + lastThree;

        let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

        if (_number.split(".")[1]) {
            res =
                otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
                lastThree +
                "." +
                _number.split(".")[1];
        }

        return res;
        // return number.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",");
    } else {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
};
