import React, { useState } from "react";
import mixpanel from "mixpanel-browser";

import Info from "components/shared/icons/Info";
// import ArrowDown from "components/shared/icons/ArrowDown";
// import PieChart from "./PieChart";
import BarChart from "./BarChart";
import { Tooltip } from "components/shared/Tooltip";

const Category = ({ currency, data, currencyRate }) => {
    const [tab, setTab] = useState("marketCAP");
    const [hover, setHover] = useState(false);

    const mixPanelTrack = () => {
        // mix panel
        mixpanel.track("ReportClicked", {
            view: "Overview",
            report: `Category data ${tab}`,
        });
    };

    const setClass = (_tab) => {
        let _class = "text-xs font-medium text-gray-500 cursor-pointer dark:text-silver-medium";

        if (tab === _tab) {
            _class =
                "text-xs font-medium bg-theme-blue dark:bg-dark-Blue dark:text-black py-0.5 px-2 rounded-xl text-white cursor-pointer dark:text-white";
        }
        return _class;
    };

    return (
        <div
            onClick={() => mixPanelTrack()}
            className="flex flex-col bg-white dark:bg-theme-bgDark rounded-xl py-5 px-4 pb-3.5 w-full mx-4 my-4 xl:my-0 xl:max-w-500"
        >
            <div className="flex items-center justify-between mb-6">
                <div className="flex items-center ">
                    <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                        Category data
                    </h5>
                    <p
                        className="relative cursor-pointer"
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                    >
                        <Info />
                        {hover && (
                            <Tooltip
                                left="-80px"
                                text="This shows the total value of all the articles for different categories on your website."
                            />
                        )}
                    </p>
                </div>
                <div className="flex items-center list-none">
                    <li
                        onClick={() => setTab("users")}
                        className={setClass("users")}
                        style={{ marginRight: "16px" }}
                    >
                        Users
                    </li>
                    <li onClick={() => setTab("marketCAP")} className={setClass("marketCAP")}>
                        Marketcap
                    </li>
                </div>
            </div>

            <div className="flex justify-between mb-2">
                {/* <PieChart /> */}
                <BarChart tab={tab} currency={currency} data={data} currencyRate={currencyRate} />
                {/* <ul>
                    <li className="mb-2.5 w-32 flex items-center justify-between text-10 font-normal text-gray-500 dark:text-white">
                        <div className="flex items-center">
                            <p className="w-2 h-2 mr-1" style={{ backgroundColor: "#4A65BF" }}></p>
                            Entertainment
                        </div>
                        <p>{tab === "users" ? "62.23%" : "$98757"}</p>
                    </li>
                    <li className="w-32 mb-2.5 flex items-center justify-between text-10 font-normal text-gray-500 dark:text-white">
                        <div className="flex items-center">
                            <p className="w-2 h-2 mr-1" style={{ backgroundColor: "#81BE66" }}></p>
                            Sports
                        </div>
                        <p> {tab === "users" ? "28.67%" : "$98744"}</p>
                    </li>
                    <li className="w-32 mb-2.5 flex items-center justify-between text-10 font-normal text-gray-500 dark:text-white">
                        <div className="flex items-center">
                            <p className="w-2 h-2 mr-1" style={{ backgroundColor: "#F4BD4C" }}></p>
                            Politics
                        </div>
                        <p>{tab === "users" ? "11.76%" : "$88757"}</p>
                    </li>
                    <li className="w-32 mb-2.5 flex items-center justify-between text-10 font-normal text-gray-500 dark:text-white">
                        <div className="flex items-center">
                            <p className="w-2 h-2 mr-1" style={{ backgroundColor: "#FF6565" }}></p>
                            Technology
                        </div>
                        <p>{tab === "users" ? "4.78%" : "$68757"}</p>
                    </li>
                    <li className="w-32 mb-2.5 flex items-center justify-between text-10 font-normal text-gray-500 dark:text-white">
                        <div className="flex items-center">
                            <p className="w-2 h-2 mr-1" style={{ backgroundColor: "#68B8D9" }}></p>
                            Others
                        </div>
                        <p>{tab === "users" ? "2.45%" : "$87543"}</p>
                    </li>
                </ul> */}
            </div>

            {/* <div className="flex items-center justify-end w-full">
                <h4 className="text-sm font-medium mr-0.5 flex items-center text-red-500">
                    <ArrowDown />
                    <p className="ml-0.5">53.34%</p>
                </h4>
                <p className="text-10 font-medium text-gray-500 italic dark:text-silver-light">
                    Marketcap in India compared to others
                </p>
            </div> */}
        </div>
    );
};

export default Category;
