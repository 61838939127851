import { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import mixpanel from "mixpanel-browser";

import Info from "components/shared/icons/Info";
import { currencyDisplay } from "components/shared/currency";
import { Tooltip } from "components/shared/Tooltip";
import { converterCurrency } from "components/shared/convertCurrency";
import { numberWithCommas } from "components/shared/NumberCommaAddition";

const AverageBarChart = ({ currency, data, currencyRate }) => {
    const [_data, setData] = useState(data);
    const [hover, setHover] = useState(false);

    const mixPanelTrack = () => {
        // mix panel
        mixpanel.track("ReportClicked", {
            view: "Conversions",
            report: "Average Overall buying power",
        });
    };

    // let _d = _data;
    // const colorList = ["#0075FF", "#999999"];
    // _d.map((barPoints, index) => (barPoints.itemStyle = { color: colorList[index] }));

    // Code to set max limit for Y axis
    // let max = Math.max(..._data.map((o) => o.value));
    let max = Math.max(data);
    max = parseInt(max * 2.0);

    const options = {
        tooltip: {
            show: false,
            trigger: "none",
        },
        grid: {
            left: "0%",
            right: "0%",
            bottom: "2%",
            top: "5%",
            containLabel: true,
        },
        legend: {
            show: false,
        },
        xAxis: {
            type: "category",
            data: [""],
            // data: _data.map((pubs) => pubs.publication),
            axisLabel: {
                show: true,
                color: "#555",
            },
            axisTick: {
                show: false,
            },
        },
        yAxis: {
            type: "value",
            axisLine: {
                show: true,
                onZero: true,
                onZeroAxisIndex: null,
            },
            max: max,
            splitLine: {
                show: true,
                lineStyle: {
                    color: localStorage.theme === "light" ? "#D1D1D1" : "#393939",
                    type: "dashed",
                    opacity: "0.6",
                },
            },
            axisLabel: {
                show: true,
                position: "top",
                fontSize: 10,
                formatter: function (d) {
                    return currencyDisplay(currency) + converterCurrency(d, currencyRate);
                },
                color: localStorage.theme === "light" ? "#000" : "#909090",
            },
        },
        series: [
            {
                // data: _d.map((points) => ({
                //     value: points.value,
                //     itemStyle: points.itemStyle,
                // })),
                data: [_data],
                label: {
                    show: true,
                    position: "top",
                    fontSize: 12,
                    color: localStorage.theme === "light" ? "#000" : "#fff",
                    formatter: function (d) {
                        return (
                            currencyDisplay(currency) +
                            numberWithCommas(converterCurrency(d.data, currencyRate), currency)
                        );
                    },
                },
                type: "bar",
                barWidth: 95,
                color: "#0075FF",
            },
        ],
    };

    useEffect(() => {
        setData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!options) return <div> Loading....</div>;

    return (
        <div
            onClick={() => mixPanelTrack()}
            className="flex flex-1 w-11/12 md:w-full px-5 flex-col items-center justify-center bg-white dark:bg-theme-bgDark rounded-xl pb-2 mb-4 lg:mb-0"
        >
            <div className="flex items-center justify-between w-full pl-0 pt-4 pb-2 mb-2">
                <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                        <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                            Average Overall buying power
                        </h5>
                        <p
                            className="relative cursor-pointer"
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            <Info />
                            {hover && (
                                <Tooltip
                                    left="-150px"
                                    text="This shows avg overall buying power of all users of the website. As well as a comparison on how the other websites are performing."
                                />
                            )}
                        </p>
                    </div>
                </div>
            </div>
            {options && (
                <ReactECharts
                    option={options}
                    style={{
                        display: "block",
                        width: "100%",
                        maxWidth: "250px",
                        height: "420px",
                    }}
                />
            )}
        </div>
    );
};

export default AverageBarChart;
