import React, { useState } from "react";
import MoonLogo from "./icons/moon";
import SunLogo from "./icons/sun";

const ThemeToggle = () => {
    const [theme, setTheme] = useState(localStorage.theme);

    const toggle = () => {
        if (theme === "light") {
            setTheme("dark");
            localStorage.setItem("theme", "dark");
        } else {
            setTheme("light");
            localStorage.setItem("theme", "light");
        }
        setTimeout(() => {
            window.location.reload();
        }, 100);
    };

    const setClass = (type) => {
        let _class;
        if (type === "moon" && theme === "light") {
            _class = "bg-gray-400 dark:bg-gray-500 py-1 px-2.5 rounded-l-3xl";
        } else if (type === "moon" && theme === "dark") {
            _class = "bg-blue-700 py-1 px-2.5 rounded-3xl";
        }

        if (type === "sun" && theme === "light") {
            _class = "bg-blue-700 py-1 px-2.5 rounded-l-3xl";
        } else if (type === "sun" && theme === "dark") {
            _class = "bg-gray-400 dark:bg-gray-500 py-1 px-2.5 rounded-3xl";
        }

        return _class;
    };
    return (
        <div
            className="flex items-center justify-between bg-gray-400 dark:bg-gray-500 rounded-3xl overflow-hidden cursor-pointer"
            onClick={() => toggle()}
        >
            <div className={setClass("moon")}>
                <MoonLogo color="white" opacity={theme === "light" ? "1" : "1"} />
            </div>
            <div className={setClass("sun")}>
                <SunLogo color="white" opacity={theme === "light" ? "1" : "1"} />
            </div>
        </div>
    );
};

export default ThemeToggle;
