import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import mixpanel from "mixpanel-browser";

import Info from "components/shared/icons/Info";
import { Tooltip } from "components/shared/Tooltip";

const BarChart = ({ chartData }) => {
    const mixPanelTrack = () => {
        // mix panel
        mixpanel.track("ReportClicked", {
            view: "Overview",
            report: "Most Active Time",
        });
    };

    const [hover, setHover] = useState(false);
    const [options, setOptions] = useState({});
    let data = chartData;

    let innerWidth = window.innerWidth;

    // let activeStartTime = data.mostActiveSlots[0].slot * 2;
    // let activeEndTime = data.mostActiveSlots[data.mostActiveSlots.length - 1].slot * 2;

    const convertTime = (time, add) => {
        let _time = time;
        if (time === 0) {
            _time = "12am";
        } else if (_time < 12) {
            if (add) {
                _time = _time + ":00am";
            } else {
                _time = _time + "am";
            }
        } else {
            if (add) {
                if (_time === 12) {
                    _time = "12:00pm";
                } else {
                    _time = _time - 12 + ":00pm";
                }
            } else {
                if (_time === 12) {
                    _time = "12pm";
                } else {
                    _time = _time - 12 + "pm";
                }
            }
        }
        return _time;
    };

    data.userDistribution.map((points) => {
        // console.log("points are- - ", convertTime(points.slot * 2));
        data.mostActiveSlots.map((aSlots) => {
            if (points.slot === aSlots.slot) {
                points.active = true;
            }
        });
    });

    let xAxisPoints = data.userDistribution.map((points) => convertTime(points.slot * 2));

    // xAxisPoints.push("12pm");

    // Function to return color accordingly the status
    const getColor = (status) => {
        const colorActive = {
            color: localStorage.theme === "light" ? "#25CA83" : "#01D692",
        };

        const colorDull = {
            color: localStorage.theme === "light" ? "#B8B8B8" : "#525252",
        };

        if (status) {
            return colorActive;
        } else {
            return colorDull;
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setOptions({
                tooltip: {
                    show: true,
                    trigger: "item",
                },
                grid: {
                    left: "0%",
                    right: "0%",
                    bottom: "3%",
                    top: "5%",
                    containLabel: true,
                },
                legend: {
                    show: false,
                },
                xAxis: {
                    type: "category",
                    data: xAxisPoints,
                    axisLabel: {
                        interval: "auto",
                        fontSize: 11,
                    },
                },
                yAxis: {
                    type: "value",
                    axisLine: {
                        show: true,
                        onZero: true,
                        onZeroAxisIndex: null,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: true,
                        position: "top",
                        fontSize: 10,
                        formatter: function (d) {
                            return d;
                            // return d / 1000 + "k";
                        },
                        color: localStorage.theme === "light" ? "#000" : "#D9D9D9",
                    },
                },
                series: [
                    {
                        data: data.userDistribution.map((points) => ({
                            value: points.users,
                            itemStyle: getColor(points.active),
                        })),
                        type: "bar",
                        barWidth: innerWidth < 550 ? 20 : 25,
                        color: localStorage.theme === "light" ? "#B8B8B8" : "#525252",
                    },
                ],
            });
        }, 150);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!options) return <div> Loading....</div>;

    return (
        <div
            onClick={() => mixPanelTrack()}
            className="flex flex-1 w-full px-4 xl:px-3 xl:mr-4 flex-col items-center justify-center bg-white dark:bg-theme-bgDark rounded-xl pb-2 xl:max-w-390"
        >
            <div className="flex items-center justify-between w-full pt-4 pb-2">
                <div className="flex items-center ">
                    <h5 className="text-13 font-semibold text-silver-medium dark:text-silver-medium">
                        Most Active Time (24h)
                    </h5>
                    <p
                        className="relative cursor-pointer"
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                    >
                        <Info />
                        {hover && (
                            <Tooltip
                                left="-80px"
                                text="This shows the most active time (Maximum numbers of Users on your website over the selected period), right now its repetitive."
                            />
                        )}
                    </p>
                </div>

                {/* <h6 className="dark:text-green-dark text-gray-500 text-10 font-bold">
                    {convertTime(activeStartTime, true)} - {convertTime(activeEndTime, true)}
                </h6> */}
            </div>
            {options && (
                <ReactECharts
                    option={options}
                    style={{
                        display: "block",
                        width: "100%",
                        height: "170px",
                        maxWidth: "366px",
                    }}
                />
            )}
        </div>
    );
};

export default BarChart;
