import React from "react";

// import bg from "assests/images/homeSection1-1.png";
import leftP from "assests/images/homeSection1-left.png";
import rightP from "assests/images//homeSection1-right.png";
import bg1 from "assests/images/homeSection1-circle.png";

import "./styles.scss";

const Section1 = () => {
    return (
        <div
            className="w-full h-full lg:pt-40 flex flex-1 items-center justify-center flex-col-reverse lg:flex-row"
            style={{
                background: "linear-gradient(103.61deg, #001627 0.99%, #000000 97.81%)",
                minHeight: "650px",
            }}
        >
            <div className="pt-20 mt-1 lg:pt-0 lg:mr-16 lg:max-w-720 w-full lg:flex items-center justify-center">
                <div className="relative">
                    <img src={bg1} alt="img" className="h-full w-full lg:max-w-500" />
                    <img
                        // data-aos="fade-right"
                        src={leftP}
                        alt="img"
                        className="-top-10 lg:-top-32 left-6 lg:left-12 absolute w-full max-w-181 h-420 lg:h-530"
                    />
                    <img
                        // data-aos="fade-left"
                        src={rightP}
                        alt="img"
                        className="top-14 lg:-top-14 left-48 lg:left-52 absolute w-full max-w-200 h-420 lg:h-530"
                    />{" "}
                </div>
            </div>
            <div
                // data-aos="fade-left"
                className="w-full flex items-center justify-center max-w-512 py-10 lg:py-0 lg:-mt-36"
            >
                <p
                    className="lg:text-4xl text-2xl text-center lg:text-left font-normal lg:font-medium mb-10 lg:mb-4 px-10 lg:px-0"
                    style={{ maxWidth: "504px" }}
                >
                    See increased user engagement with your content using
                    <br /> real-time value.
                </p>
            </div>
        </div>
    );
};

export default Section1;
