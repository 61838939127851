const Info = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2247_1488)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5455 3.64467C8.58776 3.64467 6.19008 6.04235 6.19008 9.00004C6.19008 11.9577 8.58776 14.3554 11.5455 14.3554C14.5031 14.3554 16.9008 11.9577 16.9008 9.00004C16.9008 6.04235 14.5031 3.64467 11.5455 3.64467ZM5 9.00004C5 5.38509 7.9305 2.45459 11.5455 2.45459C15.1604 2.45459 18.0909 5.38509 18.0909 9.00004C18.0909 12.615 15.1604 15.5455 11.5455 15.5455C7.9305 15.5455 5 12.615 5 9.00004ZM10.9504 6.61988C10.9504 6.29125 11.2168 6.02484 11.5455 6.02484H11.5514C11.88 6.02484 12.1464 6.29125 12.1464 6.61988C12.1464 6.94851 11.88 7.21492 11.5514 7.21492H11.5455C11.2168 7.21492 10.9504 6.94851 10.9504 6.61988ZM11.5455 8.40501C11.8741 8.40501 12.1405 8.67142 12.1405 9.00005V11.3802C12.1405 11.7088 11.8741 11.9753 11.5455 11.9753C11.2168 11.9753 10.9504 11.7088 10.9504 11.3802V9.00005C10.9504 8.67142 11.2168 8.40501 11.5455 8.40501Z"
                    fill="#989898"
                />
            </g>
            <defs>
                <clipPath id="clip0_2247_1488">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Info;
