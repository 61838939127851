const MoonLogo = ({ color, opacity }) => {
    let _color = "white";
    let _opacity = 1;
    if (color) _color = color;
    if (color) _opacity = opacity;

    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.23 9.74999C16.1217 9.6605 15.9905 9.60322 15.8513 9.58465C15.712 9.56609 15.5704 9.587 15.4425 9.64499C14.6494 10.0079 13.7871 10.1947 12.915 10.1925C11.3016 10.1905 9.75446 9.55082 8.61083 8.41282C7.46721 7.27481 6.81988 5.73082 6.80998 4.11749C6.8134 3.61187 6.87633 3.1084 6.99748 2.61749C7.02319 2.48665 7.01355 2.35131 6.96955 2.22543C6.92555 2.09955 6.84878 1.98768 6.74716 1.90134C6.64554 1.815 6.52274 1.75731 6.3914 1.73423C6.26007 1.71114 6.12495 1.72349 5.99998 1.76999C4.82422 2.29867 3.80216 3.11756 3.0298 4.14973C2.25744 5.1819 1.76014 6.39346 1.58462 7.67061C1.40909 8.94775 1.56111 10.2486 2.02638 11.4508C2.49165 12.6531 3.25491 13.7173 4.24445 14.5436C5.234 15.3699 6.41734 15.931 7.68331 16.1744C8.94929 16.4177 10.2563 16.3352 11.4817 15.9347C12.707 15.5341 13.8105 14.8287 14.6883 13.8846C15.5661 12.9405 16.1895 11.7887 16.5 10.5375C16.5378 10.3942 16.5323 10.243 16.4843 10.1028C16.4362 9.96269 16.3477 9.83991 16.23 9.74999V9.74999ZM9.10498 14.7675C7.8463 14.7586 6.62113 14.3609 5.59722 13.6288C4.57332 12.8967 3.80073 11.866 3.38524 10.6778C2.96975 9.48968 2.93168 8.20213 3.27623 6.99149C3.62079 5.78086 4.33112 4.70632 5.30998 3.91499V4.11749C5.31197 6.13385 6.11384 8.06706 7.53963 9.49285C8.96541 10.9186 10.8986 11.7205 12.915 11.7225C13.4444 11.7244 13.9725 11.6691 14.49 11.5575C13.9724 12.5366 13.1973 13.3559 12.2483 13.9268C11.2994 14.4978 10.2125 14.7989 9.10498 14.7975V14.7675Z"
                fill={_color}
                fillOpacity={_opacity}
            />
        </svg>
    );
};

export default MoonLogo;
