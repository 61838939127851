import React, { useState } from "react";
import jwtDecode from "jwt-decode";

import Klogo from "components/shared/icons/Klogo";
import ChevronDown from "./icons/ChevronDown";
import UserModal from "components/Overview/FilterModals/UserModal";
import EditProfile from "components/Overview/FilterModals/EditProfile";
import BarIcon from "./icons/BarIcon";
import MobileUserModal from "components/Overview/FilterModals/MobileUserModal";
import Bell from "./icons/Bell";
import NotificationModal from "components/Overview/FilterModals/NotificationModal.js";

// import newsPub from "assests/images/newsPub.png";

const Header = ({ tab, cacheTime }) => {
    const [modal, setModal] = useState(false);
    const [mobileUserModal, setMobileUserModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [notficationModal, setNotficationModal] = useState(false);
    const [notficationMobileModal, setNotficationMobileModal] = useState(false);

    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const userInfo = jwtDecode(localStorage?.accessToken);

    let name = userInfo?.name
        .split(" ")
        .map((n) => n[0])
        .join("");

    name = name.substring(0, 3);

    let dateObj = new Date(cacheTime);

    dateObj = `${dateObj.getDate()} ${
        monthNames[dateObj.getUTCMonth()]
    } ${dateObj.getFullYear()} ${dateObj.toLocaleTimeString()}`;

    return (
        <div className="flex flex-row items-center justify-between py-3 px-4 lg:px-11 relative bg-theme-bgwhite dark:bg-theme-bgDark mb-1.5">
            <div className="flex-col items-end hidden lg:flex">
                <Klogo color={localStorage.theme === "light" ? "#000" : "#fff"} />
                <p className="text-10 font-light italic dark:text-white text-gray-700">Insights</p>
            </div>
            <div className="flex flex-col items-end lg:hidden">
                <Klogo
                    width={"75"}
                    height={"19"}
                    color={localStorage.theme === "light" ? "#000" : "#00FFFF"}
                />
                <p className="text-10 font-light italic dark:text-white text-gray-700">Insights</p>
            </div>
            <div className="flex lg:hidden">
                {/* //mobile bell icon */}
                {/* <div className="relative mr-6 mt-1 cursor-pointer">
                    <div onClick={() => setNotficationMobileModal(!notficationMobileModal)}>
                        <Bell />
                        <span className="absolute w-2.5 h-2.5 top-0 right-0 bg-red-600 rounded-full"></span>
                    </div>
                </div> */}
                <div
                    className="cursor-pointer"
                    onClick={() => setMobileUserModal(!mobileUserModal)}
                >
                    <BarIcon />
                </div>
            </div>

            <div className="hidden lg:flex items-center dark:text-silver-dark relative">
                {/* desktop bell icon */}
                {/* <div className="relative mr-6 cursor-pointer">
                    <div onClick={() => setNotficationModal(!notficationModal)}>
                        <Bell />
                        <span className="absolute w-2.5 h-2.5 top-0 right-0 bg-red-600 rounded-full"></span>
                    </div>
                    {notficationModal && (
                        <NotificationModal closeModal={() => setNotficationModal(false)} />
                    )}
                </div> */}
                {/* <div className="hidden lg:flex mr-10">
                    <ThemeToggle />
                </div> */}
                <div className="flex items-center cursor-pointer" onClick={() => setModal(!modal)}>
                    <h6 className="rounded-full uppercase h-9 w-9 mr-2 border border-gray-400 text-sm flex items-center justify-center text-gray-600 dark:text-gray-400 dark:border-gray-400">
                        {name}
                    </h6>

                    <div className="mr-5 hidden lg:flex flex-col">
                        <h6 className="text-13 m-0 font-normal text-gray-700 dark:text-silver-medium">
                            {userInfo?.name}
                        </h6>
                        <p
                            className="text-10 font-light text-gray-600 dark:text-silver-medium"
                            style={{ marginTop: "-4px" }}
                        >
                            {userInfo?.email}
                        </p>
                    </div>
                    <ChevronDown />
                </div>
                {modal && (
                    <UserModal
                        closeModal={() => setModal(false)}
                        userInfo={userInfo}
                        name={name}
                        editModal={() => setEditModal(true)}
                    />
                )}
            </div>
            {mobileUserModal && (
                <MobileUserModal
                    dateObj={dateObj}
                    tab={tab}
                    closeModal={() => setMobileUserModal(false)}
                    userInfo={userInfo}
                    name={name}
                    editModal={() => setEditModal(true)}
                />
            )}

            {editModal && (
                <EditProfile
                    closeModal={() => setEditModal(false)}
                    userInfo={userInfo}
                    name={name}
                />
            )}

            {notficationMobileModal && (
                <NotificationModal closeModal={() => setNotficationMobileModal(false)} />
            )}
        </div>
    );
};

export default Header;
