import React, { useEffect, useState } from "react";

import { currencyDisplay } from "components/shared/currency";
import { converterCurrency } from "components/shared/convertCurrency";

import ArrowDown from "components/shared/icons/ArrowDown";
import ArrowUp from "components/shared/icons/ArrowUp";

const ArticlePrice = ({ price, currencyRate }) => {
    const [articlePrice, setArticlePrice] = useState("");
    const [priceDirection, setPriceDirection] = useState(Math.random() < 0.3 ? "down" : "up");
    const [animationDirection, setAnimationDirection] = useState(
        Math.random() < 0.3 ? "down" : "up"
    );

    useEffect(() => {
        if (!articlePrice) {
            setArticlePrice(price);
        }

        let time = Math.floor(Math.random() * (1500 - 500 + 1) + 500);

        if (articlePrice > price) {
            setTimeout(() => {
                setArticlePrice(price);
                setPriceDirection("down");
                setAnimationDirection("down");
            }, time);
        } else {
            setTimeout(() => {
                setArticlePrice(price);
                setPriceDirection("up");
                setAnimationDirection("up");
            }, time);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [price]);

    let _articlePrice = `${articlePrice}`.slice(0, `${articlePrice}`.indexOf(".") + 3);

    return (
        <p className="flex items-center text-2xl font-bold mb-3.5 dark:text-white">
            {currencyDisplay("inr")}
            {_articlePrice?.split(".")[0]}.
            <span className={priceDirection === "up" ? "text-green-500" : "text-red-500"}>
                {_articlePrice?.split(".")[1]}
            </span>
            {priceDirection === "up" && <ArrowUp />}
            {priceDirection === "down" && <ArrowDown />}
        </p>
    );
};

export default ArticlePrice;
