import React from "react";

import bg from "assests/images/homesection6.png";
import bg1 from "assests/images/homesection6-mb.png";

import "./style.scss";

const Section5 = () => {
    return (
        <div
            className="w-full h-full flex flex-1 items-center justify-center"
            style={{
                background: "linear-gradient(103.61deg, #001627 0.99%, #000000 97.81%)",
                minHeight: "650px",
            }}
        >
            <div
                className="flex items-center justify-end w-full flex-col lg:flex-row"
                style={{ maxWidth: "1200px" }}
            >
                <div
                    // data-aos="fade-right"
                    className="w-full relative text-center lg:text-left pt-10 lg:pt-0"
                    style={{ maxWidth: "490px" }}
                >
                    <p
                        className="font-medium mb-7 text-xl px-8 lg:px-0 lg:text-3xl text-center lg:text-left"
                        style={{ maxWidth: "470px" }}
                    >
                        Get real-time stats about authors, most valuable articles, individual
                        author engagement and user interactions for your website.
                    </p>

                    <button
                        onClick={() => window.scrollTo({ top: 20, behavior: "smooth" })}
                        className="partner-button border border-blue2 bg-blue2 lg:bg-blue8 rounded-lg text-lg font-bold text-black lg:text-white flex items-center py-4 px-12"
                    >
                        Request Access
                    </button>
                </div>
                <img
                    // data-aos="fade-left"
                    src={bg}
                    alt="img"
                    className="hidden lg:block h-full w-full max-w-800"
                    style={{ marginTop: "30px" }}
                />
                <img
                    // data-aos="fade-left"
                    src={bg1}
                    alt="img"
                    className="lg:hidden h-full w-full max-w-850"
                    style={{ marginTop: "40px" }}
                />
            </div>
        </div>
    );
};

export default Section5;
