import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

const CategoryBarGraph = ({ data, currency, currencyRate }) => {
    const [options, setOptions] = useState({});

    // let innerWidth = window.innerWidth;

    const colorListLight = ["#009393", "#25CA83", "#00A3FF", "#006B6B", "#929292", "#25CA83"];
    const colorListDark = ["#25CA83", "#9CFFD4", "#3BABFF", "#009393", "#929292", "#1D6BA6"];

    let colorList = localStorage.theme === "light" ? colorListLight : colorListDark;

    data.map((barPoints, index) => (barPoints.itemStyle = { color: colorList[index] }));

    // Code to set max limit for Y axis
    let maxValues = data.map((o) => o);
    const cleanMaxValues = maxValues.filter((element) => {
        return element !== undefined;
    });
    let max = Math.max(...cleanMaxValues);
    max = parseInt(max * 1.2);

    useEffect(() => {
        setTimeout(() => {
            setOptions({
                xAxis: {
                    type: "category",
                    data: data.map((item) => item.categoryName),
                    name: "Categories",
                    nameLocation: "center",
                    nameTextStyle: {
                        padding: [16, 0, 0, 0],
                        fontWeight: 700,
                    },
                },
                gridLines: {
                    show: false,
                },
                grid: {
                    height: "82%",
                    top: "2%",
                    width: "90%",
                    left: "5%",
                    bottom: "10%",
                },
                yAxis: {
                    type: "value",
                    axisLine: {
                        show: true,
                        onZero: true,
                        onZeroAxisIndex: null,
                    },
                    axisTick: {
                        show: true,
                    },
                    splitLine: {
                        show: false,
                    },
                    max: max,
                    name: "Unlocks",
                    nameLocation: "center",
                    nameTextStyle: {
                        padding: [0, 0, 16, 0],
                        fontWeight: 700,
                    },
                },
                tooltip: {
                    show: true,
                    trigger: "item",
                },
                colorBy: "data",
                color: localStorage.theme === "light" ? colorListLight : colorListDark,
                series: [
                    {
                        data: data.map((item) => item.count),
                        type: "bar",
                        barMaxWidth: "100",
                        realtimeSort: "true",
                        barMinWidth: "8",
                    },
                ],
            });
        }, 150);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currency, currencyRate]);

    if (!options) return <div> Loading....</div>;

    return (
        <div className="flex flex-1 flex-col items-center justify-center w-full">
            <div className="text-white text-lg font-bold">Unlocked Articles By Category</div>
            {options && (
                <ReactECharts
                    option={options}
                    style={{
                        display: "block",
                        width: "100%",
                        height: "280px",
                        // maxWidth: "468px",
                    }}
                />
            )}
        </div>
    );
};

export default CategoryBarGraph;
