// import { h } from "preact";

const SvgIcons = ({ priceDirection }) => {
    return (
        <>
            {priceDirection === "up" && (
                <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "2px" }}
                >
                    <path
                        d="M6.50008 10.2917L6.50008 2.70837M6.50008 2.70837L2.70842 6.50004M6.50008 2.70837L10.2917 6.50004"
                        stroke="#007945"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )}
            {priceDirection === "down" && (
                <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "2px" }}
                >
                    <path
                        d="M6.50008 2.70829L6.50008 10.2916M6.50008 10.2916L2.70842 6.49996M6.50008 10.2916L10.2917 6.49996"
                        stroke="#F14B63"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )}
        </>
    );
};

export default SvgIcons;
