import { forgotPassword } from "components/shared/utils";
import React, { useEffect, useRef, useState } from "react";

const ForgotModal = ({ closeModal }) => {
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(true);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const modalRef = useRef();

    const setClass = () => {
        let _class =
            "bg-dark-inputBlue border border-gray-500 text-white focus:border-white focus:outline-none text-sm py-4 pl-3 rounded-md mb-10";

        if (error) {
            _class =
                "bg-dark-inputBlue border border-red-500 text-red-500 focus:border-white focus:outline-none text-sm py-4 pl-3 rounded-md mb-10";
        }

        return _class;
    };

    // Function to submit form and call API
    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        //     // Storing response
        const response = await fetch(forgotPassword, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // /make sure to serialize your JSON body
            body: JSON.stringify({
                email: email,
            }),
        });
        // Storing data in form of JSON
        const data = await response.json();

        console.log("data is", data);
        if (data.ok) {
            //success
            setSuccess(true);
        } else {
            //error
            setError(data.error.message);
        }
    };

    useEffect(() => {
        const clickOutside = (event) => {
            if (!modalRef.current.contains(event.target)) {
                closeModal();
            }
        };
        document.addEventListener("mousedown", clickOutside);
        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                width: "100%",
                height: "100%",
                transform: " translate(-50%, -50%)",
                zIndex: 1000,
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                overflowY: "auto",
            }}
        >
            <div
                className="rounded-sm w-full py-11 px-10"
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "100%",
                    maxWidth: "405px",
                    transform: " translate(-50%, -50%)",
                    backgroundColor: "#011727",
                }}
                ref={modalRef}
            >
                {!success ? (
                    <>
                        <h1 className="font-bold text-left text-white mb-2.5">Forgot Password?</h1>
                        <p className="text-xs font-normal text-white text-left mb-8">
                            Enter your email address and we’ll send you a link to reset your
                            password.
                        </p>

                        {error && <div className="text-xs text-red-500 mb-2">{error}</div>}

                        <form className="flex flex-col" onSubmit={(e) => handleSubmit(e)}>
                            <input
                                type="email"
                                placeholder="e.g. email@domain.com"
                                className={setClass()}
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                style={{ width: "100%" }}
                                onFocus={() => setError(null)}
                            />
                            <button
                                disabled={loading}
                                type="submit"
                                className="w-full bg-blue7 text-white py-4 rounded-md text-sm font-bold"
                            >
                                Reset Password
                            </button>
                        </form>
                    </>
                ) : (
                    <div>
                        <h1 className="font-bold text-left text-white mb-2.5">Forgot Password?</h1>
                        <p className="text-sm font-medium  text-white text-left mb-8">
                            Please reach out to{" "}
                            <a className="text-blue-500" href="mailto:support@kunato.io">
                                support@kunato.io
                            </a>{" "}
                            or your Account Manager to Reset Password.
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ForgotModal;
