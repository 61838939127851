import React, { useRef } from "react";

import video1 from "assests/images/login/loginf2.mp4";

const LoginStep2 = () => {
    const videoRef = useRef();
    return (
        <div className="w-full min-h-screen flex items-end justify-start flex-1 pb-40 relative">
            <div
                className="absolute z-10"
                style={{
                    backgroundColor: "rgba(0,0,0,0.6)",
                    minWidth: "100%",
                    top: "0px",
                    height: "100%",
                }}
            ></div>
            <video
                ref={videoRef}
                controls={false}
                autoPlay={true}
                loop
                muted
                style={{
                    position: "absolute",
                    top: "0px",
                    minWidth: "100%",
                    height: "100%",
                    objectFit: "cover",
                }}
            >
                <source src={video1} type="video/mp4" />
            </video>
            <h6 className="font-bold text-4xl max-w-700 pl-6 lg:pl-16 z-10">
                Monitor the efficiency and contribution of authors.
            </h6>
        </div>
    );
};

export default LoginStep2;
