export const Tooltip = ({ left, text }) => {
    return (
        <div
            className="absolute top-4 text-11 w-60 p-2 rounded-md"
            style={
                localStorage.theme === "light"
                    ? {
                          color: "#6D6D6D",
                          backgroundColor: "#E6E6E6",
                          left: left,
                          zIndex: 500,
                      }
                    : {
                          color: "#989898",
                          backgroundColor: "#1D1D1D",
                          left: left,
                          zIndex: 500,
                      }
            }
        >
            {text}
        </div>
    );
};
