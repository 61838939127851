const BarIcon = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.99999 10.6667H28C28.3536 10.6667 28.6928 10.5262 28.9428 10.2761C29.1929 10.0261 29.3333 9.68696 29.3333 9.33333C29.3333 8.97971 29.1929 8.64057 28.9428 8.39052C28.6928 8.14048 28.3536 8 28 8H3.99999C3.64637 8 3.30723 8.14048 3.05718 8.39052C2.80713 8.64057 2.66666 8.97971 2.66666 9.33333C2.66666 9.68696 2.80713 10.0261 3.05718 10.2761C3.30723 10.5262 3.64637 10.6667 3.99999 10.6667ZM28 21.3333H3.99999C3.64637 21.3333 3.30723 21.4738 3.05718 21.7239C2.80713 21.9739 2.66666 22.313 2.66666 22.6667C2.66666 23.0203 2.80713 23.3594 3.05718 23.6095C3.30723 23.8595 3.64637 24 3.99999 24H28C28.3536 24 28.6928 23.8595 28.9428 23.6095C29.1929 23.3594 29.3333 23.0203 29.3333 22.6667C29.3333 22.313 29.1929 21.9739 28.9428 21.7239C28.6928 21.4738 28.3536 21.3333 28 21.3333ZM28 14.6667H3.99999C3.64637 14.6667 3.30723 14.8071 3.05718 15.0572C2.80713 15.3072 2.66666 15.6464 2.66666 16C2.66666 16.3536 2.80713 16.6928 3.05718 16.9428C3.30723 17.1929 3.64637 17.3333 3.99999 17.3333H28C28.3536 17.3333 28.6928 17.1929 28.9428 16.9428C29.1929 16.6928 29.3333 16.3536 29.3333 16C29.3333 15.6464 29.1929 15.3072 28.9428 15.0572C28.6928 14.8071 28.3536 14.6667 28 14.6667Z"
                fill={localStorage.theme === "light" ? "black" : "white"}
            />
        </svg>
    );
};

export default BarIcon;
