import React from "react";
import { Link } from "react-router-dom";

const Terms = () => {
    return (
        <div className="flex flex-col w-full max-w-716 mb-5 px-4 lg:px-0">
            <h5 className="font-bold mb-5">Service Agreement</h5>
            <div className="flex flex-col max-h-80 lg:max-h-96 overflow-auto px-6 py-2 mb-5 bg-blue3">
                <div className="w-full bg-blue3">
                    <div className="mb-5">
                        <h6 className="font-bold mb-3 text-gray-300">INTRODUCTION</h6>
                        <p className="text-xs text-gray1 mb-3">
                            <span className="font-bold">Kunato</span> is a content-payment service
                            provider. It aims to transform the way we consume, create, and search
                            for content -- shifting the world from popularity-based methods to a
                            Value-based index system.
                        </p>
                        <p className="text-xs text-gray1 mb-3">
                            Its technology, Kunato.Ai is a revolutionary patented quantitative
                            deep-learning value system for the valuation of digital content which
                            transforms information into tradable goods thus creating a new economy.
                            Its real-time dynamic value-based payment system caters and attracts all
                            types of users and covers every type of subject (trading, politics,
                            sports, entertainment, science, etc.) which quickly promotes quality
                            content, matches content to users instantly, yields the highest-valued
                            content, and eliminates “fake news” over time.
                        </p>
                        <p className="text-xs text-gray1 mb-3">
                            By accepting and implementing the Kunato Plugin,{" "}
                            <strong>the Company</strong> hereby agrees to be bound by this Service
                            Agreement (this “Agreement”), which also effectively includes Kunato’s
                            Terms of Service and Privacy Policy (the “Terms”) as defined herein.
                        </p>
                        <p className="text-xs text-gray1 mb-3">
                            The Company and Kunato agree that the company shall continuously
                            implement Kunato Plugin on their platforms and websites.
                        </p>
                    </div>
                    <div className="mb-5">
                        <h6 className="font-bold mb-3  text-gray-300">DEFINITIONS</h6>
                        <p className="text-xs text-gray1 mb-3">
                            a: “Service Agreement – Kunato.Ai” means this agreement, provisions
                            herein, and evolving Privacy Policy and Terms and Conditions of the
                            Service.
                        </p>
                        <p className="text-xs text-gray1 mb-3">
                            b: “Privacy Policy” means the privacy policy available at{" "}
                            <Link className="font-semibold text-gray-400" to="/privacy">
                                Privacy Policy
                            </Link>
                            , which may be amended by Kunato from time to time.
                        </p>
                        <p className="text-xs text-gray1 mb-3">
                            c: . “Terms and Conditions” means the policies applicable to the Company
                            and the Content, available at{" "}
                            <Link className="font-semibold text-gray-400" to="/terms">
                                Terms of Service
                            </Link>
                            , which may be amended by Kunato from time to time.
                        </p>
                    </div>
                    <div className="mb-5">
                        <h6 className="font-bold mb-3 text-gray-300">ACCESS TO SERVICES </h6>
                        <p className="text-xs text-gray1 mb-3">
                            Kunato may modify, suspend, or discontinue the Services or Company’s
                            access thereto, at any time and without notice or liability. The
                            Company’s use of the Services is subject to this Agreement, as well as
                            the Terms and Kunato’s Policies, which are hereby incorporated by
                            reference. Kunato reserves the right to amend its Policies at any time.
                            The Company’s use of the Services indicates the assent to and acceptance
                            of the revised Terms and revised Policies.
                        </p>
                        <p className="text-xs text-gray1 mb-3">
                            By using the services, the Company shall also provide Kunato with a
                            guest access to their Google Analytics to be used solely for data
                            analysis purposes. The Company may also opt-out of the service at any
                            time by giving a 12-hour written notice to Kunato through their Account
                            Manager.
                        </p>
                    </div>
                    <div className="mb-5">
                        <h6 className="font-bold mb-3 text-gray-300">CONFIDENTIAL INFORMATION</h6>
                        <p className="text-xs text-gray1 mb-3">
                            During the effectivity of this Agreement and for three (3) years after
                            the termination or expiration thereof, neither Party shall make use of
                            the other Party’s Confidential Information for purposes other than the
                            performance of its obligations under this Agreement. Each Party shall
                            protect the other Party’s Confidential Information by exercising the
                            same degree of care as it uses to protect its own information of like
                            nature, but no less than a reasonable degree of care and caution.
                        </p>
                        <p className="text-xs text-gray1 mb-3">
                            The receiving Party shall only disclose the Confidential Information to
                            its employees, consultants, and subcontractors who have a need to know
                            such Confidential Information in order to perform its obligations under
                            this Agreement. “Confidential Information” shall mean all information of
                            either Party not generally available to the public which is provided to
                            the receiving Party by the disclosing Party or which the receiving Party
                            has access to or discovers in the performance of this Agreement,
                            including without limitation all information relating to a Party’s
                            products, business, and operations (including without limitation
                            business plans, financial records, customers, suppliers, vendors,
                            products, costs, sources, strategies, inventions, procedures, forecasts,
                            sales materials and data, technical advice or knowledge, contractual
                            agreements, pricing, product specifications, trade secrets, procedures,
                            distribution methods, inventories, marketing strategies, algorithms,
                            designs, drawings, worksheets, blueprints, manufacturing processes,
                            computer programs and systems, and know-how or other intellectual
                            property of a Party and its affiliates that may be at any time
                            furnished, communicated, or delivered by a Party to the other Party,
                            whether in oral, tangible, electronic, or other form), and any other
                            information that a reasonable person would believe to be confidential or
                            proprietary in nature given the circumstances of disclosure. All
                            Confidential Information shall remain the property of the disclosing
                            Party.
                        </p>
                        <p className="text-xs text-gray1 mb-3">
                            Confidential Information shall not include any information that:
                        </p>
                        <ol className="text-xs text-gray1 list-decimal pl-5">
                            <li>
                                Was rightfully known to a Party prior to disclosure by the other
                                Party;
                            </li>
                            <li>
                                Is rightfully obtained by a Party from a third party lawfully in
                                possession of such information without restriction on use or
                                disclosure;
                            </li>
                            <li>
                                Is made available by the disclosing Party to the public without
                                restrictions on use or disclosure;
                            </li>
                            <li>
                                Is independently developed or learned by the receiving Party through
                                legitimate means; or
                            </li>
                            <li>
                                Is disclosed pursuant to any applicable laws, regulations, or order
                                of a court of competent jurisdiction, or by the receiving Party in
                                defence of a claim against the receiving Party. Each Party will
                                provide reasonable prior written notice to the other Party unless
                                prohibited by applicable law, if it is required to disclose any of
                                the other Party’s Confidential Information under operation of law,
                                and such disclosure shall not constitute a breach of these Terms.
                            </li>
                        </ol>
                    </div>
                    <div className="mb-5">
                        <h6 className="font-bold mb-3 text-gray-300">
                            THE COMPANY REPRESENTATIONS AND WARRANTIES{" "}
                        </h6>
                        <p className="text-xs text-gray1 mb-3">
                            The Company warrants and represents that:
                        </p>
                        <ol className="text-xs text-gray1 list-decimal pl-5">
                            <li>
                                The Company owns or is authorized to use The Company’s Properties in
                                connection with the Services,
                            </li>
                            <li>
                                The Company shall comply with all applicable laws, rules,
                                regulations, and industry self-regulations in connection to The
                                Company’s use of the Services and/or The Company’s performance of
                                its obligations under these Terms,
                            </li>
                            <li>
                                The Company shall not infringe any third-party intellectual property
                                rights or privacy rights, and
                            </li>
                            <li>
                                The Company shall not spread malicious code, viruses, Trojan horses,
                                malware, spyware, or similarly harmful measures to the Services or
                                End-Users.
                            </li>
                        </ol>
                    </div>
                    <div className="mb-5">
                        <h6 className="font-bold mb-3 text-gray-300">WARRANTY DISCLAIMER</h6>
                        <p className="text-xs text-gray1 font-semibold mb-3">
                            THE SERVICES, THE SITE, AND ALL CONTENTS AVAILABLE OR ACCESSIBLE THROUGH
                            THE SERVICES AND THE SITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE
                            BASIS. EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, KUNATO MAKES NO
                            OTHER WARRANTIES AND HEREBY EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY
                            KIND, EXPRESS OR IMPLIED, WITH RESPECT TO THE SERVICES, THE SITE, AND
                            THEIR CONTENTS, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF
                            MERCHANTABILITY, NON-INFRINGEMENT, TITLE AND FITNESS FOR A PARTICULAR
                            PURPOSE, AND IMPLIED WARRANTIES ARISING FROM COURSE OF DEALING OR
                            PERFORMANCE. KUNATO MAKES NO WARRANTIES OF ANY KIND THAT THE SERVICES OR
                            SITE WILL ALWAYS BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY,
                            SECURE, OPERATE WITHOUT ERROR, OR THAT ANY SOFTWARE PROVIDED HEREUNDER
                            WILL OPERATE WITHOUT ERROR. ANY CONTENT, INCLUDING ADS, TRANSMITTED
                            THROUGH THE USE OF THE SERVICES IS DONE AT PUBLISHER’S OWN DISCRETION
                            AND RISK, AND PUBLISHER WILL BE SOLELY RESPONSIBLE FOR SUCH USE.
                            FURTHER, KUNATO MAKES NO REPRESENTATIONS REGARDING THE LEVEL OF CLICKS
                            OR IMPRESSIONS THROUGH THE SERVICES. SOME STATES MAY PROHIBIT A
                            DISCLAIMER OF WARRANTIES AND PUBLISHER MAY HAVE OTHER RIGHTS THAT VARY
                            FROM STATE TO STATE.
                        </p>
                    </div>
                    <div className="mb-5 list-decimal">
                        <h6 className="font-bold mb-3 text-gray-300">INDEMNIFICATION </h6>
                        <li className="text-xs text-gray1 mb-3">
                            <span className="font-bold">By The Company</span>
                            . The Company hereby agrees to defend, indemnify, and hold harmless
                            Kunato, and its directors, officers, employees, affiliates, agents, and
                            representatives, from and against any and all third-party demands,
                            claims, actions, proceedings, damages, liabilities, losses, fees, costs,
                            or expenses (“Losses”), including without limitation reasonable
                            attorneys’ fees and the costs of any investigation, directly or
                            indirectly arising from or in any way connected with (a) any breach of
                            or default under this Agreement by The Company, including, but not
                            limited to, any breach by The Company of its representations and
                            warranties under this Agreement, (b) any allegation that the Company’s
                            content, application(s), disclosures or privacy policy violates any
                            applicable law, (c) any negligence, gross negligence, or willful
                            misconduct by or on behalf of the Company or its employees or agents,
                            (d) the Company Properties, (e) the modification, combination, or use of
                            the Kunato Services with any products, materials, or technology not
                            approved, directed, or provided by Kunato, and/or (f) The Company’s use
                            of the Services or Kunato Services to connect or otherwise work with any
                            Third-Party Demand Partner.
                        </li>
                        <li className="text-xs text-gray1 mb-3">
                            <span className="font-bold">By KUNATO</span>. Kunato hereby agrees to
                            defend, indemnify, and hold harmless The Company, and its directors,
                            officers, employees, agents, and representatives, from and against any
                            and all Losses, including without limitation reasonable attorneys’ fees
                            and the costs of any investigation, directly or indirectly arising from
                            any third-party claim that the Services infringe a third-party
                            intellectual property right, provided that such Losses do not arise
                            from: (a) a modification to the Services or Kunato Services made by The
                            Company that is not under the approval or direction of Kunato, or (b)
                            the combination or use of the Services or Kunato Services with products,
                            materials, or technology not approved or provided by Kunato. For the
                            avoidance of doubt and subject to the following sentence, Kunato shall
                            have no obligation hereunder to the extent that any such Losses arise
                            out of or relate to any third-party materials (including, but not
                            limited to Ads). If and to the extent that Kunato is indemnified by its
                            Advertisers with respect to the Demand Policies, (“Indemnified
                            Content”), Kunato will pass through such indemnity to The Company for
                            third-party claims brought against the Company alleging that the
                            Indemnified Content does not comply with the Demand Policies.
                        </li>
                    </div>
                    <div className="mb-5">
                        <h6 className="font-bold mb-3 text-gray-300">INDEMNIFICATION PROCEDURE </h6>
                        <p className="text-xs text-gray1 mb-3">
                            The obligations of each Party (the “Indemnitor”) under this Agreement to
                            defend, indemnify, and hold harmless the other Party and its respective
                            directors, officers, employees, agents, and representatives (each an
                            “Indemnitee”) shall be subject to the following: (a) the Indemnitee
                            shall provide the Indemnitor with prompt notice of the claim giving rise
                            to such obligation; provided, however, that any failure or delay in
                            giving such notice shall only relieve the Indemnitor of its obligation
                            to defend, indemnify, and hold the Indemnitee harmless to the extent it
                            reasonably demonstrates that its defense or settlement of the claim or
                            suit was adversely affected thereby; (b) the Indemnitor shall have sole
                            control of the defense and of all negotiations for settlement of such
                            claim or suit; provided, however, that the Indemnitor shall not settle
                            any claim unless such settlement completely and forever releases the
                            Indemnitee from all liability with respect to such claim or unless the
                            Indemnitee consents to such settlement in writing (such consent shall
                            not be unreasonably withheld); and (c) the Indemnitee shall cooperate
                            with the Indemnitor in the defense or settlement of any such claim or
                            suit; provided, however, that the Indemnitee shall be reimbursed for all
                            reasonable out-of-pocket expenses incurred in providing any cooperation
                            requested by the Indemnitor. Subject to clause (b) above, the Indemnitee
                            may participate in the defense of any claim or suit in which the
                            Indemnitee is involved at its own expense. The obligations of each Party
                            (the “Indemnitor”) under this Agreement to defend, indemnify, and hold
                            harmless the other Party and its respective directors, officers,
                            employees, agents, and representatives (each an “Indemnitee”) shall be
                            subject to the following: (a) the Indemnitee shall provide the
                            Indemnitor with prompt notice of the claim giving rise to such
                            obligation; provided, however, that any failure or delay in giving such
                            notice shall only relieve the Indemnitor of its obligation to defend,
                            indemnify, and hold the Indemnitee harmless to the extent it reasonably
                            demonstrates that its defense or settlement of the claim or suit was
                            adversely affected thereby; (b) the Indemnitor shall have sole control
                            of the defense and of all negotiations for settlement of such claim or
                            suit; provided, however, that the Indemnitor shall not settle any claim
                            unless such settlement completely and forever releases the Indemnitee
                            from all liability with respect to such claim or unless the Indemnitee
                            consents to such settlement in writing (such consent shall not be
                            unreasonably withheld); and (c) the Indemnitee shall cooperate with the
                            Indemnitor in the defense or settlement of any such claim or suit;
                            provided, however, that the Indemnitee shall be reimbursed for all
                            reasonable out-of-pocket expenses incurred in providing any cooperation
                            requested by the Indemnitor. Subject to clause (b) above, the Indemnitee
                            may participate in the defense of any claim or suit in which the
                            Indemnitee is involved at its own expense.
                        </p>
                    </div>
                    <div className="mb-5">
                        <h6 className="font-bold mb-3 text-gray-300">LIMITATION OF LIABILITY </h6>
                        <p className="text-xs text-gray1 mb-3 font-semibold">
                            EXCEPT FOR THE PARTIES’ CONFIDENTIALITY OBLIGATIONS AND THE PARTIES’
                            INDEMNIFICATION OBLIGATIONS, IN NO EVENT SHALL EITHER PARTY OR ITS
                            SUBSIDIARIES, AFFILIATES, SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES,
                            AGENTS, AND REPRESENTATIVES BE LIABLE UNDER THIS AGREEMENT TO THE OTHER
                            PARTY FOR ANY INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, STATUTORY,
                            PUNITIVE, OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION LOST
                            PROFITS, LOST SAVINGS AND LOST REVENUES, LOSS OF DATA, LOSS OF USE, LOSS
                            OF TIME, SHUTDOWN OR SLOWDOWN COSTS, INCONVENIENCE, LOST BUSINESS
                            OPPORTUNITIES, DAMAGE TO GOODWILL OR REPUTATION, OR OTHER ECONOMIC LOSS,
                            REGARDLESS OF WHETHER SUCH LIABILITY IS BASED ON BREACH OF CONTRACT,
                            TORT, STRICT LIABILITY OR OTHERWISE, AND EVEN IF ADVISED OF THE
                            POSSIBILITY OF SUCH DAMAGES OR SUCH DAMAGES COULD HAVE BEEN REASONABLY
                            FORESEEN, EXCEPT WHEN SUCH DAMAGES ARE CAUSED BY THE GROSS NEGLIGENCE OR
                            WILLFUL MISCONDUCT OF THE PARTY, ITS EMPLOYEES, OR AGENTS. FOR THE
                            AVOIDANCE OF DOUBT, PUBLISHER SPECIFICALLY AGREES THAT KUNATO SHALL NOT
                            BE LIABLE FOR ANY LOSSES ARISING FROM INTERRUPTION, SUSPENSION, OR
                            TERMINATION OF THE SERVICES, WHETHER INTENTIONALLY OR NEGLIGENTLY.
                            PUBLISHER FURTHER AGREES THAT KUNATO SHALL NOT BE LIABLE TO PUBLISHER OR
                            END-USERS FOR ANY THREATENING, DEFAMATORY, OBSCENE, OR OTHERWISE
                            OFFENSIVE CONTENT THAT MAY BE TRANSMITTED THROUGH THE SERVICES.
                        </p>
                        <p className="text-xs text-gray1 mb-3">
                            <span className="font-bold">SOLE AND EXCLUSIVE REMEDY</span>. IF
                            PUBLISHER IS DISSATISFIED WITH THE SERVICES, THE CONTENT AVAILABLE ON OR
                            THROUGH THE SERVICES, OR ANY OF TERMS OF THE SERVICES, PUBLISHER’S SOLE
                            AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SERVICES. THIS REMEDY
                            IS INTENDED TO BE THE SOLE AND EXCLUSIVE REMEDY OF THE PUBLISHER UNDER
                            THIS AGREEMENT. THE PARTIES ALSO AGREE THAT, REGARDLESS OF THE FAILURE
                            ON THE SOLE AND EXCLUSIVE REMEDY, KUNATO WILL NOT BE LIABLE FOR ANY
                            CONSEQUENTIAL, SPECIAL, INCIDENTAL, STATUTORY, PUNITIVE, OR EXEMPLARY
                            DAMAGES, LOSS OF USE, LOSS OF PROFITS, OR BUSINESS INTERRUPTION ARISING
                            FROM OR RELATING TO THE PUBLISHER’S USE OF THE SERVICES OR FROM ANY
                            BREACH BY KUNATO OF THIS AGREEMENT AND THE TERMS.
                        </p>
                        <p className="text-xs text-gray1 mb-3">
                            NO ACTION OR PROCEEDING AGAINST KUNATO OR KUNATO’S DIRECTORS, OFFICERS,
                            SHAREHOLDERS, EMPLOYEES, AGENTS, REPRESENTATIVES, PARENTS, SUBSIDIARIES,
                            SUCCESSORS, OR ASSIGNEES MAY BE COMMENCED MORE THAN TWELVE (12) MONTHS
                            AFTER THE CLAIM ARISES.
                        </p>
                    </div>
                    <div className="mb-5">
                        <h6 className="font-bold mb-3 text-gray-300">MISCELLANEOUS</h6>
                        <ol className="list-decimal pl-5 text-xs text-gray1">
                            <li>
                                <p className="text-xs text-gray1 mb-3">
                                    <span className="font-bold">Notices</span>
                                    . All notices and other communications required or permitted to
                                    be given to a Party pursuant to this Agreement shall be in
                                    writing, and shall be deemed duly given (a) on the date
                                    delivered if personally delivered, (b) on the business day after
                                    being sent by Federal Express or another recognized overnight
                                    courier service which utilizes a written form of receipt for
                                    next day or next business day delivery in each case to the
                                    addressed to the applicable Party or (c) on the business day
                                    following the day of facsimile or email transmission; provided
                                    that a Party hereto may change its address for receiving notice
                                    by the proper giving of notice hereunder.
                                </p>
                            </li>
                            <li>
                                <p className="text-xs text-gray1 mb-3">
                                    <span className="font-bold">Marketing and Publicity</span>
                                    .  During the effectivity of this Agreement and other than the
                                    fees agreed upon herein, the Company hereby grants to KUNATO a
                                    non-exclusive, royalty-free, fully paid-up, worldwide license
                                    and right to use and display the Company’s name, logos,
                                    trademarks, tradenames, and/or service marks, including those of
                                    the Company properties, in and on Kunato’s customer lists,
                                    website, presentations, case studies, testimonials, blog posts,
                                    materials provided to prospective partners, social media, and
                                    other physical and digital marketing materials.
                                </p>
                            </li>
                            <li>
                                <p className="text-xs text-gray1 mb-3">
                                    <span className="font-bold">Governing Law</span>
                                    . This Agreement shall be governed by, and construed in
                                    accordance with, the laws of the State of Delaware without
                                    regard to the conflict of law provisions. Each Party hereby
                                    submits to the exclusive jurisdiction of the state and federal
                                    courts in Delaware for purposes of all legal proceedings arising
                                    out of or relating to this Agreement and the subject matter
                                    hereof, and waives any jurisdictional, venue, or inconvenient
                                    forum objections to such courts. The prevailing Party in any
                                    litigation shall be entitled to recovery of its reasonable
                                    attorneys’ fees from the other Party, in addition to any other
                                    award of damages from the court.
                                </p>
                            </li>
                            <li>
                                <p className="text-xs text-gray1 mb-3">
                                    <span className="font-bold">Binding Effect; Assignment</span>
                                    . This Agreement and the rights, obligations, and licenses
                                    herein, shall be binding upon and inure to the benefit of the
                                    Parties hereto and their respective heirs, successors, assigns,
                                    and personal representatives. Neither Party shall assign this
                                    Agreement in whole or part without the prior written consent of
                                    the other Party, except that either Party may assign this
                                    Agreement in connection with a merger, reorganization, or sale
                                    of a substantial part of the assets or business to which these
                                    Agreement relate.
                                </p>
                            </li>
                            <li>
                                <p className="text-xs text-gray1 mb-3">
                                    <span className="font-bold">Severability; Waiver</span>
                                    .  If any portion of this Agreement is held to be illegal or
                                    unenforceable, that portion shall be restated, eliminated, or
                                    limited to the minimum extent necessary so that this Agreement
                                    shall reflect as nearly as possible the original intention of
                                    the Parties, and the remainder of this Agreement shall remain in
                                    full force and effect. The failure of Kunato to enforce any
                                    right or term of this Agreement shall not be deemed a waiver of
                                    such right or term.
                                </p>
                            </li>
                            <li>
                                <p className="text-xs text-gray1 mb-3">
                                    <span className="font-bold">Relationship of the Parties</span>
                                    .  Nothing contained in this Agreement shall be deemed to create
                                    a joint venture, partnership, fiduciary, or other similar
                                    relationships between the Parties. Each Party has sole
                                    responsibility for its activities and its personnel and shall
                                    have no authority and shall not represent to any third party
                                    that it has the authority to bind or otherwise obligate the
                                    other Party in any manner. Nothing contained in this Agreement
                                    shall be deemed to create a joint venture, partnership,
                                    fiduciary, or other similar relationships between the Parties.
                                    Each Party has sole responsibility for its activities and its
                                    personnel and shall have no authority and shall not represent to
                                    any third party that it has the authority to bind or otherwise
                                    obligate the other Party in any manner.
                                </p>
                            </li>
                            <li>
                                <p className="text-xs text-gray1 mb-3">
                                    <span className="font-bold">Headings; Interpretation</span>
                                    . The section headings used in this Agreement are intended for
                                    reference purposes only and shall not affect the interpretation
                                    of this Agreement. If an ambiguity or question arises with
                                    respect to any provision of this Agreement, the same will be
                                    construed as if drafted jointly by the Parties and no
                                    presumption or burden of proof will arise favoring or
                                    disfavoring either Party by virtue of authorship of any of the
                                    provisions of this Agreement.
                                </p>
                            </li>
                            <li>
                                <p className="text-xs text-gray1 mb-3">
                                    <span className="font-bold">Force Majeure</span>
                                    . Neither Party shall be liable for any failure or delay in the
                                    performance of any of their respective obligations if prevented
                                    from doing so by a Force Majeure Event. “Force Majeure Event”
                                    means (a) floods, earthquakes, or other similar elements of
                                    nature or acts of God; (b) riots, civil disorders, rebellions or
                                    revolutions in any country; or (c) any other cause beyond the
                                    reasonable control of the non-performing Party, provided the
                                    non-performing Party is without fault in failing to prevent or
                                    causing such default or delay, and such default or delay could
                                    not have been prevented or circumvented by the non-performing
                                    Party through the reasonable use of alternate sources,
                                    workaround plans, or other reasonable precautions.
                                </p>
                            </li>
                            <li>
                                <p className="text-xs text-gray1 mb-3">
                                    <span className="font-bold">Cumulative Remedies</span>
                                    .  All rights and remedies under this Agreement are cumulative
                                    and are in addition to any other rights and remedies a Party may
                                    have at law or in equity.
                                </p>
                            </li>
                            <li>
                                <p className="text-xs text-gray1 mb-3">
                                    <span className="font-bold">Entire Agreement; Amendment</span>
                                    . This constitutes the entire agreement of the Parties with
                                    respect to the subject matter hereof and supersedes any and all
                                    existing or prior agreements and communications, whether written
                                    or oral, relating to the subject matter hereof. Kunato reserves
                                    the right to change or modify any of the terms and conditions
                                    contained in this Agreement at any time and in its sole
                                    discretion upon providing prior notice to the Company. For the
                                    Terms as defined in this Agreement, any changes or modifications
                                    will be effective immediately upon posting of the revised
                                    version on Kunato’s Services, and the Company waives any right
                                    the Company may have to receive additional notice of such
                                    changes or modifications. The Company’s continued use of
                                    Kunato’s Services following the posting of the revised Terms
                                    shall constitute the Company’s agreement to be bound by the
                                    revised Terms. If the Company does not agree to any portion of
                                    the revised Terms, the Company must stop using the Services and
                                    provide a written notice to Kunato.
                                </p>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Terms;
