import React, { useState, useEffect, useRef } from "react";
import mixpanel from "mixpanel-browser";

import customFetch from "components/shared/customFetch";
import { authServer } from "components/shared/utils";

import eye1 from "assests/images/eye11.png";
import eye2 from "assests/images/eye22.png";
import errorImg from "assests/images/error.png";
import errorImgWhite from "assests/images/errorWhite.png";
import Close from "components/shared/icons/Close";
import PasswordChecker from "./EditProfile/PasswordChecker";

/**
 * Currency dropdown
 * @prop {Function} closeModal - Function to close modal
 */

const EditProfile = ({ closeModal, userInfo, name }) => {
    const modalRef = useRef();

    const [form, setForm] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    });
    const [error, setError] = useState(false);
    const [showPwd, setShowPwd] = useState(false);
    const [showPwd1, setShowPwd1] = useState(false);
    const [showPwd2, setShowPwd2] = useState(false);
    const [updated, setUpdated] = useState(false);

    // mix panel
    mixpanel.track("Edit profile", {
        username: userInfo?.name,
        email: userInfo?.email,
    });

    // Function to handle form input change
    const handleChange = (field, event) => {
        let _form;
        _form = { ...form };
        _form[field] = event.target.value;
        setForm(_form);
    };

    // Function to update user request
    const handleUpdate = async () => {
        setError(null);
        setUpdated(false);
        setError(null);

        if (
            form.newPassword.length < 8 ||
            form.oldPassword.length < 8 ||
            form.confirmPassword.length < 8
        ) {
            return;
        } else if (form.confirmPassword !== form.newPassword) {
            setError("New and Confirm passwords do not match.");
            setTimeout(() => {
                setError(null);
            }, 3000);
        } else {
            const options = {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                // /make sure to serialize your JSON body
                body: JSON.stringify(form),
            };
            // Storing response
            const response = await customFetch(`${authServer}/users/${userInfo.id}`, options);

            // Storing data in form of JSON
            const data = await response.json();

            if (data.ok) {
                setTimeout(() => {
                    closeModal();
                    setUpdated(false);
                }, 1500);
                setUpdated(true);
            } else {
                setError(data.error.message);
            }
        }
    };

    useEffect(() => {
        const clickOutside = (event) => {
            if (!modalRef.current.contains(event.target)) {
                closeModal();
            }
        };
        document.addEventListener("mousedown", clickOutside);
        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div
            style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                width: "100%",
                height: "100%",
                transform: " translate(-50%, -50%)",
                zIndex: 1000,
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                overflowY: "auto",
            }}
        >
            <div
                ref={modalRef}
                className="bg-white dark:bg-theme-bgDark shadow-md z-50 pt-6 pb-4 rounded-md px-11 py-6"
                style={{
                    position: "absolute",
                    top: "40%",
                    left: "50%",
                    width: "100%",
                    maxWidth: "650px",
                    transform: " translate(-50%, -50%)",
                }}
            >
                <div className="dark:text-white flex flex-col w-full ">
                    <div className="mb-8 relative">
                        <h5 className="text-15 font-medium">Edit Profile</h5>
                        <p className="text-xs font-light">Update your avatar and password</p>
                        <p
                            className="absolute right-0 top-0 cursor-pointer"
                            onClick={() => closeModal()}
                        >
                            <Close />
                        </p>
                    </div>
                    <div className="relative flex items-center justify-between border-t border-gray-400 pt-5 pb-8">
                        <p
                            className="text-theme-blue dark:text-dark-Blue text-sm font-medium absolute bg-white dark:bg-black pr-5"
                            style={{
                                top: "-10px",
                            }}
                        >
                            Profile Details
                        </p>
                        <div className="flex items-center">
                            <h6 className="rounded-full mb-2 h-16 w-16 mr-12 border flex items-center justify-center text-gray-600 dark:text-gray-400 dark:border-gray-400">
                                {name}
                            </h6>
                            <h4 className="text-15 font-medium -mt-2">{userInfo.name}</h4>
                        </div>
                        {/* <button className="text-11 font-medium  dark:border-dark-Blue border border-border-borderGray px-10 py-1.5 rounded">
                            Edit
                        </button> */}
                    </div>

                    <div className="relative flex items-center justify-between border-t border-gray-400 pt-5 mb-6">
                        <p
                            className="text-theme-blue dark:text-dark-Blue text-sm font-medium absolute bg-white dark:bg-black pr-5"
                            style={{
                                top: "-10px",
                            }}
                        >
                            Password Details
                        </p>
                        <form className="w-full">
                            <label className="text-11">Current Password</label>
                            <div className="flex items-center w-full">
                                <div className="flex items-center bg-white text-black dark:bg-theme-bgDark border border-gray-500 dark:border-gray-800 text-11 rounded relative w-56 mb-4">
                                    <input
                                        type={showPwd ? "text" : "password"}
                                        placeholder="Enter your current password here"
                                        className="bg-white text-black dark:bg-theme-bgDark dark:text-white text-11 py-1.5 px-2 focus:outline-none"
                                        value={form.oldPassword}
                                        onChange={(event) => handleChange("oldPassword", event)}
                                        style={{ width: "100%" }}
                                    />
                                    <img
                                        src={showPwd ? eye2 : eye1}
                                        alt="img"
                                        width={17}
                                        height={11}
                                        className="cursor-pointer absolute right-2"
                                        onClick={() => setShowPwd(!showPwd)}
                                    />
                                </div>

                                {error && (
                                    <div className="flex items-center bg-red-500 dark:bg-red-bg rounded mb-4 ml-2.5 py-1 px-3">
                                        <img
                                            src={
                                                localStorage.theme === "light"
                                                    ? errorImgWhite
                                                    : errorImg
                                            }
                                            alt="img"
                                            width={18}
                                            height={18}
                                        />
                                        <p className="text-white text-13 dark:text-red-text ml-3">
                                            {error || "Wrong Password"}
                                        </p>
                                    </div>
                                )}
                            </div>

                            <div className="flex items-start">
                                <div className="mr-4">
                                    <label className="text-11">New Password</label>
                                    <div className="flex items-center bg-white dark:bg-theme-bgDark text-black border border-gray-500 dark:border-gray-800 text-11 rounded relative w-56 ">
                                        <input
                                            type={showPwd1 ? "text" : "password"}
                                            placeholder="Enter your current password here"
                                            className="bg-white dark:bg-theme-bgDark text-black dark:text-white text-11 py-1.5 px-2 focus:outline-none"
                                            value={form.newPassword}
                                            onChange={(event) => handleChange("newPassword", event)}
                                            style={{ width: "100%" }}
                                        />
                                        <img
                                            src={showPwd1 ? eye2 : eye1}
                                            alt="img"
                                            width={17}
                                            height={11}
                                            className="cursor-pointer absolute right-2"
                                            onClick={() => setShowPwd1(!showPwd1)}
                                        />
                                    </div>
                                    {form.newPassword.length > 0 && (
                                        <PasswordChecker password={form.newPassword} />
                                    )}
                                </div>
                                <div>
                                    <label className="text-11">Confirm Password</label>
                                    <div className="flex items-center bg-white dark:bg-theme-bgDark text-black border border-gray-500 dark:border-gray-800 text-11 rounded relative w-56 ">
                                        <input
                                            type={showPwd2 ? "text" : "password"}
                                            placeholder="Enter your current password here"
                                            className="bg-white dark:bg-theme-bgDark text-black dark:text-white text-11 py-1.5 px-2 focus:outline-none"
                                            value={form.confirmPassword}
                                            onChange={(event) =>
                                                handleChange("confirmPassword", event)
                                            }
                                            style={{ width: "100%" }}
                                        />
                                        <img
                                            src={showPwd2 ? eye2 : eye1}
                                            alt="img"
                                            width={17}
                                            height={11}
                                            className="cursor-pointer absolute right-2"
                                            onClick={() => setShowPwd2(!showPwd2)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="relative flex items-center border-t border-gray-400 pt-6 mb-5">
                        <p
                            className="text-theme-blue dark:text-dark-Blue text-sm font-medium absolute bg-white dark:bg-black pr-5"
                            style={{
                                top: "-10px",
                            }}
                        >
                            Contact Details
                        </p>

                        <div className="flex flex-col">
                            <label className="text-11">Email</label>
                            <input
                                type={"email"}
                                placeholder="Enter your current password here"
                                className="bg-input-gray dark:bg-theme-bgDark text-white dark:text-gray-500 text-xs font-medium py-1.5 px-2 focus:border-none w-56 rounded"
                                value={userInfo.email}
                                disabled
                            />
                        </div>
                    </div>

                    <div className="flex items-center mb-2">
                        <button
                            onClick={() => handleUpdate()}
                            className="text-11 font-medium px-10 py-1.5 bg-blue-600 dark:bg-dark-Blue dark:text-black text-white rounded "
                        >
                            Update
                        </button>
                        {updated && (
                            <p className="text-xs bg-green-light text-white dark:text-green-dark dark:bg-green-bg px-3 py-1.5 ml-2.5 rounded">
                                Password Updated Successfully
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditProfile;
