import React from "react";

import bg1 from "assests/images/homesection7/newstep12bg.png";
import pub from "assests/images/homesection7/newstep12p.png";
import Component from "../Prices";

const SlideStep12 = ({ articlePrices }) => {
    const url =
        "https://www.wittyflick.com/2022/09/make-witty-move-and-use-ai-to-consume-content.html";

    const price = articlePrices[url];

    return (
        <div className="flex w-full items-center justify-between mb-4 min-w-165 max-w-169 lg:min-w-270 lg:max-w-270 overflow-hidden rounded-xl">
            <a
                href="https://www.wittyflick.com/2022/09/make-witty-move-and-use-ai-to-consume-content.html"
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col bg-white max-w-270 items-center justify-start text-center
                pb-16 h-72"
                style={{ borderRadius: "17px" }}
            >
                <div
                    className="mb-3 min-h-147 w-165 lg:w-270 flex items-end justify-end relative"
                    style={{
                        backgroundImage: `url(${bg1})`,
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "scroll",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                >
                    <div
                        className="w-full h-full absolute"
                        style={{
                            backgroundColor: "rgba(0,0,0,0.2)",
                        }}
                    ></div>{" "}
                    <div
                        className="w-full h-1/2 absolute"
                        style={{
                            background: "linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)",
                        }}
                    ></div>
                    {price && <Component price={price} url={url} idKey={url} />}
                </div>

                <h5 className="text-black text-sm font-bold mb-8 lg:mb-10">
                    Make a Witty Move and Use AI to Consume Content!
                </h5>
                <img src={pub} alt="img1" className="lg:w-32 w-28 mb-3" />
            </a>
        </div>
    );
};

export default SlideStep12;
