import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import jwtDecode from "jwt-decode";

import { getAIData, getAIDataRevenue, getAIDataViews } from "components/shared/utils";
import customFetch from "components/shared/customFetch";

import Header from "components/shared/Header";
import Sidebar from "components/shared/Sidebar";
import Filters from "components/Overview/Filters";

import Spinner from "components/shared/spinner";
import Articles from "./Articles";
import AuthorData from "./Author";
import LineChartAI from "./LineGraph/Graph";
import AverageBarChart from "./AveragePrice";

const AIPage = () => {
    const currentCurrency = localStorage.getItem("currentCurrency");

    const [filter, setFilter] = useState(7);
    const [currency, setCurrency] = useState(currentCurrency || "inr");
    const [currencyRate, setCurrencyRate] = useState("0.012849");
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState("");
    const [articleData, setArticleData] = useState("");
    const [cacheTime, setCacheTime] = useState("");
    const [date, setDate] = useState([]);
    const [fetchingArticleData, setFetchingArticleData] = useState(false);

    let storage = localStorage;

    let userInfo;
    let name;

    if (localStorage?.accessToken) {
        userInfo = jwtDecode(localStorage?.accessToken);

        name = userInfo?.name
            .split(" ")
            .map((n) => n[0])
            .join("");

        name = name.substring(0, 3);
    }

    // mix panel
    mixpanel.time_event("AI data page - page_view_exit");

    // mix panel
    if (userInfo) {
        mixpanel.track("PageView", {
            view: "AI data",
            username: name,
            email: userInfo?.email,
        });
    } else {
        mixpanel.track("PageView", {
            view: "AI data",
        });
    }

    setInterval(() => {
        mixpanel.track("PagePoll", {
            pollTime: 5,
            view: "AI data",
        });
    }, 5000);

    useEffect(() => {
        if (storage.theme === "dark") {
            document.body.style.backgroundColor = "#000";
        } else {
            document.body.style.backgroundColor = "#EEE";
        }
    }, [storage.theme]);

    // Function to convert currency
    const currencyConverter = async () => {
        const response = await fetch(
            `https://a2.qx.live/currency-conversion?base=${currency}&to=usd`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        // Storing data in form of JSON
        const data = await response.json();

        if (data.ok) {
            localStorage.setItem("currentCurrency", currency);
            setCurrencyRate(data.rate);
        }
    };

    // Function to get data points for primary article
    const getData = async () => {
        let _date = date;
        if (localStorage.filterDates) {
            _date = JSON.parse(localStorage.filterDates);
        }
        let url =
            _date.length > 0
                ? getAIData + `?&startDate=${_date[0]}&endDate=${_date[1]}`
                : getAIData;

        const response = await customFetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        // Storing data in form of JSON
        const data = await response.json();
        if (data.ok) {
            setData(data.data);
            setArticleData(data.data?.articleData?.highestValue);
            setCacheTime(data.data.createdAt);
        }

        setLoading(false);
    };

    // Function to get data points for primary article
    const getArticleData = async (tab) => {
        if (tab === "value") {
            setArticleData(data.articleData?.highestValue);
            return;
        }

        setFetchingArticleData(true);
        let _date = date;
        if (localStorage.filterDates) {
            _date = JSON.parse(localStorage.filterDates);
        }

        let AiAPI = tab === "views" ? getAIDataViews : getAIDataRevenue;
        let url = _date.length > 0 ? AiAPI + `?&startDate=${_date[0]}&endDate=${_date[1]}` : AiAPI;

        const response = await customFetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        // Storing data in form of JSON
        const _data = await response.json();

        if (_data.ok) {
            if (tab === "views") {
                setArticleData(_data.highestViews);
            } else {
                setArticleData(_data.highestRevenuePotential);
            }
        }

        setFetchingArticleData(false);
    };

    useEffect(() => {
        setLoading(true);
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    useEffect(() => {
        currencyConverter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currency]);

    if (!storage.accessToken) return <Navigate to="/login" />;

    return (
        <div className="bg-theme-gray dark:bg-black flex flex-1 flex-col min-h-screen min-w-full">
            <Header cacheTime={cacheTime} />
            <div className="flex flex-1 flex-row">
                <Sidebar />
                {loading ? (
                    <div className="flex flex-1 items-center justify-center w-full min-h-full">
                        <Spinner />
                    </div>
                ) : (
                    <div className="flex flex-1 flex-col items-center w-full">
                        <Filters
                            setFilter={setFilter}
                            cacheTime={cacheTime}
                            currency={currency}
                            setCurrency={setCurrency}
                            date={date}
                            setDate={setDate}
                        />
                        <div className="flex w-full flex-col xl:flex-row items-center 2xl:justify-center mb-6 md:mb-0">
                            <div
                                className="flex flex-col w-11/12 md:w-full items-start mb-4 xl:mb-0"
                                style={{ maxWidth: "840px" }}
                            >
                                <Articles
                                    data={articleData}
                                    currency={currency}
                                    currencyRate={currencyRate}
                                    fetchingArticleData={fetchingArticleData}
                                    getArticleData={getArticleData}
                                />
                                <LineChartAI filter={filter} data={data?.valuedArticlesByDate} />
                            </div>
                            <div className="flex items-center flex-col justify-start w-full md:max-w-335">
                                <AverageBarChart
                                    currency={currency}
                                    data={data?.averageArticlePrice}
                                    currencyRate={currencyRate}
                                />

                                <AuthorData
                                    data={data?.authorEngagement}
                                    currency={currency}
                                    currencyRate={currencyRate}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AIPage;
