import React from "react";

import { currencyDisplay } from "components/shared/currency";
import { converterCurrency } from "components/shared/convertCurrency";
import { numberWithCommas } from "components/shared/NumberCommaAddition";

const TableFor3 = ({ data, currency, currencyRate }) => {
    const userInitials = (name) => {
        let _name = name;

        _name = _name
            .split(" ")
            .map((n) => n[0])
            .join("");
        _name = _name.substring(0, 3);

        return _name;
    };

    return (
        <>
            <div className="flex items-start mt-5">
                <div className="flex flex-col items-start list-none text-11 font-bold text-blue-600  dark:text-cyan-400">
                    <li className="pl-4 py-2 text-left opacity-0 mb-10">a</li>
                    <li className="pl-4 py-2 text-left">Author</li>
                    <li className="pl-4 py-2">Articles</li>
                    <li className="pl-4 py-2">Value</li>
                </div>
                <div className="flex items-center justify-around w-full dark:text-white">
                    {data.map((article, index) => (
                        <div
                            key={index}
                            className="border border-gray-300 dark:border-silver-dark rounded-lg p-1"
                        >
                            <div className="flex flex-col items-start list-none text-11">
                                <li className="pl-2 py-2 text-left">
                                    <p
                                        className="flex uppercase justify-center items-center text-center bg-gray-400 text-white rounded-full p-1 mr-2 text-2xl font-medium"
                                        style={{ width: "52px", height: "52px" }}
                                    >
                                        {userInitials(article.author)}
                                    </p>
                                </li>
                                <li className="py-2 text-left">{article.author || "Anonymous"}</li>
                                <li className="py-2 text-left">{article?.numberOfArticles}</li>
                                <li className=" py-2">
                                    {" "}
                                    {currencyDisplay(currency)}
                                    {numberWithCommas(
                                        converterCurrency(article?.value, currencyRate),
                                        currency
                                    )}
                                </li>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default TableFor3;
