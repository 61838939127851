import React from "react";

import { currencyDisplay } from "components/shared/currency";
import { converterCurrency } from "components/shared/convertCurrency";
import { numberWithCommas } from "components/shared/NumberCommaAddition";

const TableFor2 = ({ data, currency, currencyRate }) => {
    const userInitials = (name) => {
        let _name = name;

        _name = _name
            .split(" ")
            .map((n) => n[0])
            .join("");
        _name = _name.substring(0, 3);

        return _name;
    };

    return (
        <div className="flex items-center mt-5 justify-around mx-3">
            {data.map((article, index) => (
                <div
                    key={index}
                    className="flex items-start border border-gray-300 dark:border-silver-dark p-2 pt-20 rounded-lg outline-none"
                >
                    <div className="mr-2 flex flex-col items-start list-none text-11 font-bold text-blue-600 dark:text-cyan-400">
                        {/* <li className="pl-2 py-2 text-left opacity-0 mb-12">a</li> */}
                        <li className="pl-2 py-2 text-left">Author</li>
                        <li className="pl-2 py-2">Articles</li>
                        <li className="pl-2 py-2">Value</li>
                    </div>
                    <div className="flex items-center justify-around w-full dark:text-white relative">
                        <div>
                            <div className="flex flex-col items-start list-none text-11">
                                <li
                                    className="py-2 text-left absolute -top-20 right-2/4"
                                    // style={{ marginLeft: "-30px" }}
                                >
                                    <p
                                        className="flex uppercase justify-center items-center text-center bg-gray-400 text-white rounded-full p-1 text-3xl font-medium"
                                        style={{ width: "65px", height: "65px" }}
                                    >
                                        {userInitials(article.author)}
                                    </p>
                                </li>
                                <li className="py-2 text-left">{article.author || "Anonymous"}</li>
                                <li className="py-2 text-left">{article?.numberOfArticles}</li>
                                <li className=" py-2">
                                    {" "}
                                    {currencyDisplay(currency)}
                                    {numberWithCommas(
                                        converterCurrency(article?.value, currencyRate),
                                        currency
                                    )}
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TableFor2;
