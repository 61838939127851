import React from "react";
import { Link } from "react-router-dom";

import Facebook from "components/shared/icons/Facebook";
import Instagram from "components/shared/icons/Instagram";
import Linkedin from "components/shared/icons/Linkedin";
import Twitter from "components/shared/icons/Twitter";

import "./style.css";

const Footer = () => {
  return (
    <div className="flex flex-col py-6 lg:py-7 lg:px-16 lg:bg-black pb-1">
      <div className="flex flex-col lg:flex-row items-center justify-between text-sm mb-4">
        <div className="flex flex-col lg:flex-row items-center font-bold list-none text-sm text-white mb-20 lg:mb-0">
          <div className="text-sm font-bold">
            <a
              href="https://kunato.ai/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h2>Kunato.Ai</h2>
            </a>
          </div>
          <li className="cursor-pointer lg:pl-12 pt-7 lg:pt-0">
            <Link to="/terms">Terms of Service</Link>
          </li>
          <li className="cursor-pointer lg:pl-12 pt-7 lg:pt-0">
            <Link to="/Disclaimer">Disclaimer</Link>
          </li>
          <li className="cursor-pointer lg:pl-12 pt-7 lg:pt-0">
            <Link to="/privacy">Privacy Policy</Link>
          </li>

          <li className="cursor-pointer lg:pl-12 pt-7 lg:pt-0">
            <a
              href="https://www.linkedin.com/company/kunato/jobs/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Career
            </a>
          </li>
          <li className="cursor-pointer lg:pl-12 pt-7 lg:pt-0">
            <a href="/Login">Login</a>
          </li>
        </div>
        <div className="flex items-center mb-7 lg:mb-0 svg-footer">
          <p className="pl-5 cursor-pointer">
            <a
              href="https://in.linkedin.com/company/kunato"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Linkedin />
            </a>
          </p>
          <p className="pl-5 cursor-pointer">
            <a
              href="https://www.instagram.com/kunato.ai/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram />
            </a>
          </p>
          <p className="pl-5 cursor-pointer">
            <a
              href="https://www.facebook.com/KunatoAi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook />
            </a>
          </p>
          <p className="pl-5 cursor-pointer">
            <a
              href="https://twitter.com/ai_kunato"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter />
            </a>
          </p>
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row items-center justify-between text-10 mb-6 lg:mb-0">
        <p className="text-gray1 lg:mb-0">© 2023 Kunato All rights reserved</p>
      </div>
    </div>
  );
};

export default Footer;
