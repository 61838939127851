import React, { useEffect, useRef } from "react";

import Close from "components/shared/icons/Close";

/**
 * Notification modal
 * @prop {Function} closeModal - Function to close modal
 */

const NotificationModal = ({ closeModal }) => {
    const modalRef = useRef();

    useEffect(() => {
        const clickOutside = (event) => {
            if (!modalRef.current.contains(event.target)) {
                closeModal();
            }
        };
        document.addEventListener("mousedown", clickOutside);
        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            ref={modalRef}
            className="bg-white dark:bg-silver-dark2 shadow-md z-50 rounded-md pt-3 right-0 w-screen min-h-screen lg:min-h-full top-16 lg:top-8 lg:w-335 lg:-right-5 lg:max-h-96"
            style={{
                position: "absolute",
            }}
        >
            <div className="dark:text-white flex flex-col items-center justify-center w-full px-2.5 pb-1.5">
                <div className="flex items-center justify-between w-full text-13 font-bold mb-2.5">
                    <h4>Notifications</h4>
                    {/* <p onClick={() => closeModal()}>
                        <Close color={localStorage.theme === "light" ? "#212121" : "#E3E3E3"} />
                    </p> */}
                </div>
                <div className="px-2.5 pt-3 mb-1.5 text-xs w-full bg-theme-gray dark:bg-silver-dark1 rounded-md">
                    <div className="flex mb-3">
                        <span className="w-2 h-2 rounded-full bg-theme-blue mt-1 mr-2"></span>
                        <h6>
                            Your July 2022 Insight report is ready{" "}
                            <span className=" text-light-blue">click here</span> to download
                        </h6>
                    </div>
                    <p className="italic text-gray-400 mb-3 ml-3">1h ago</p>
                </div>
                <div className="px-2.5 pt-3 mb-1.5 text-xs w-full bg-theme-gray dark:bg-silver-dark1 rounded-md">
                    <div className="flex mb-3">
                        <span className="w-2 h-2 rounded-full bg-theme-blue mt-1 mr-2"></span>
                        <h6>
                            Your June 2022 Insight report is ready{" "}
                            <span className=" text-light-blue">click here</span> to download
                        </h6>
                    </div>
                    <p className="italic text-gray-400 mb-3 ml-3">1h ago</p>
                </div>
                <div className="px-2.5 pt-3 mb-1.5 text-xs w-full bg-theme-gray dark:bg-silver-dark1 rounded-md">
                    <div className="flex mb-3">
                        <span className="w-2 h-2 rounded-full bg-theme-blue mt-1 mr-2"></span>
                        <h6>
                            Your May 2022 Insight report is ready{" "}
                            <span className=" text-light-blue">click here</span> to download
                        </h6>
                    </div>
                    <p className="italic text-gray-400 mb-3 ml-3">1h ago</p>
                </div>
            </div>
            <div className="mx-2.5 mb-1.5">
                <button className="w-full py-1.5  text-xs bg-theme-gray dark:bg-silver-dark1 dark:text-white rounded-md">
                    Mark all as read
                </button>
            </div>
        </div>
    );
};

export default NotificationModal;
