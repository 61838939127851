import React, { useEffect } from "react";

import usd from "assests/images/usd.png";
import inr from "assests/images/inr.png";
import eur from "assests/images/eur.png";
import gbp from "assests/images/gbp.png";
import jpy from "assests/images/jpy.png";
import cad from "assests/images/cad.png";
import aud from "assests/images/aud.png";
import aed from "assests/images/aed.png";
import ils from "assests/images/ils.png";
import cny from "assests/images/cny.png";
import brl from "assests/images/brl.png";

/**
 * Currency dropdown
 * @prop {Function} closeModal - Function to close modal
 * @prop {Function} setCurrency - Function to set currency
 */

const CurrencyFilter = ({ setCurrency, closeModal }) => {
    // Logic added to remove scroll when modal is open
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    return (
        <div
            className="bg-white dark:bg-black relative shadow z-50"
            style={{
                position: "absolute",
                top: "35px",
                left: "2px",
                width: "100%",
                maxWidth: "100px",
            }}
        >
            <div className="rounded-md bg-white dark:bg-theme-bgDark dark:text-white w-full">
                <div
                    className="flex items-center justify-between py-2 px-3 cursor-pointer border-b
                     border-gray-100 dark:border-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700  dark:hover:text-white"
                    onClick={() => {
                        setCurrency("usd");
                        closeModal();
                    }}
                >
                    <div className="flex items-center">
                        <img src={usd} alt="usd" />
                        <h6 className="pl-2 text-xs font-normal">USD</h6>
                    </div>
                    <p className="text-10 font-medium">$</p>
                </div>
                <div
                    className="flex items-center justify-between py-2 px-3 cursor-pointer border-b
                     border-gray-100 dark:border-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700  dark:hover:text-white"
                    onClick={() => {
                        setCurrency("inr");
                        closeModal();
                    }}
                >
                    <div className="flex items-center">
                        <img src={inr} alt="inr" />
                        <h6 className="pl-2 text-xs font-normal">INR</h6>
                    </div>
                    <p className="text-10 font-medium">₹</p>
                </div>
                <div
                    className="flex items-center justify-between py-2 px-3 cursor-pointer border-b
                     border-gray-100 dark:border-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700  dark:hover:text-white"
                    onClick={() => {
                        setCurrency("eur");
                        closeModal();
                    }}
                >
                    <div className="flex items-center">
                        <img src={eur} alt="eur" />
                        <h6 className="pl-2 text-xs font-normal">EUR</h6>
                    </div>
                    <p className="text-10 font-medium">€</p>
                </div>
                <div
                    className="flex items-center justify-between py-2 px-3 cursor-pointer border-b
                     border-gray-100 dark:border-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700  dark:hover:text-white"
                    onClick={() => {
                        setCurrency("gbp");
                        closeModal();
                    }}
                >
                    <div className="flex items-center">
                        <img src={gbp} alt="gbp" />
                        <h6 className="pl-2 text-xs font-normal">GBP</h6>
                    </div>
                    <p className="text-10 font-medium"> £</p>
                </div>
                <div
                    className="flex items-center justify-between py-2 px-3 cursor-pointer border-b
                     border-gray-100 dark:border-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700  dark:hover:text-white"
                    onClick={() => {
                        setCurrency("jpy");
                        closeModal();
                    }}
                >
                    <div className="flex items-center">
                        <img src={jpy} alt="jpy" />
                        <h6 className="pl-2 text-xs font-normal">JPY</h6>
                    </div>
                    <p className="text-10 font-medium">¥</p>
                </div>
                <div
                    className="flex items-center justify-between py-2 px-3 cursor-pointer border-b
                     border-gray-100 dark:border-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700  dark:hover:text-white"
                    onClick={() => {
                        setCurrency("cad");
                        closeModal();
                    }}
                >
                    <div className="flex items-center">
                        <img src={cad} alt="cad" />
                        <h6 className="pl-2 text-xs font-normal">CAD</h6>
                    </div>
                    <p className="text-10 font-medium">$</p>
                </div>
                <div
                    className="flex items-center justify-between py-2 px-3 cursor-pointer border-b
                     border-gray-100 dark:border-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700  dark:hover:text-white"
                    onClick={() => {
                        setCurrency("aud");
                        closeModal();
                    }}
                >
                    <div className="flex items-center">
                        <img src={aud} alt="aud" />
                        <h6 className="pl-2 text-xs font-normal">AUD</h6>
                    </div>
                    <p className="text-10 font-medium">$</p>
                </div>
                <div
                    className="flex items-center justify-between py-2 px-3 cursor-pointer border-b
                     border-gray-100 dark:border-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700  dark:hover:text-white"
                    onClick={() => {
                        setCurrency("aed");
                        closeModal();
                    }}
                >
                    <div className="flex items-center">
                        <img src={aed} alt="aed" />
                        <h6 className="pl-2 text-xs font-normal">AED</h6>
                    </div>
                    <p className="text-10 font-medium">د.إ</p>
                </div>
                <div
                    className="flex items-center justify-between py-2 px-3 cursor-pointer border-b
                     border-gray-100 dark:border-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700  dark:hover:text-white"
                    onClick={() => {
                        setCurrency("ils");
                        closeModal();
                    }}
                >
                    <div className="flex items-center">
                        <img src={ils} alt="ils" />
                        <h6 className="pl-2 text-xs font-normal">ILS</h6>
                    </div>
                    <p className="text-10 font-medium">₪</p>
                </div>
                <div
                    className="flex items-center justify-between py-2 px-3 cursor-pointer border-b
                     border-gray-100 dark:border-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700  dark:hover:text-white"
                    onClick={() => {
                        setCurrency("cny");
                        closeModal();
                    }}
                >
                    <div className="flex items-center">
                        <img src={cny} alt="cny" />
                        <h6 className="pl-2 text-xs font-normal">CNY</h6>
                    </div>
                    <p className="text-10 font-medium">¥</p>
                </div>
                <div
                    className="flex items-center justify-between py-2 px-3 cursor-pointer border-b
                     border-gray-100 dark:border-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700  dark:hover:text-white"
                    onClick={() => {
                        setCurrency("brl");
                        closeModal();
                    }}
                >
                    <div className="flex items-center">
                        <img src={brl} alt="brl" />
                        <h6 className="pl-2 text-xs font-normal">BRL</h6>
                    </div>
                    <p className="text-10 font-medium">R$</p>
                </div>
            </div>
        </div>
    );
};

export default CurrencyFilter;
