const HomeFeature2 = () => {
    return (
        <svg
            width="47"
            height="47"
            viewBox="0 0 47 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="23.5" cy="23.5" r="23.5" fill="black" />
            <path
                d="M24.2388 22.7611H33.9999C33.6432 17.5329 29.467 13.3564 24.2388 13V22.7611Z"
                fill="#00FFFF"
            />
            <path
                d="M31.2393 30.6113C32.8139 28.9037 33.8319 26.6748 33.9998 24.2113H24.8394L31.2393 30.6113Z"
                fill="#00FFFF"
            />
            <path
                d="M22.7887 24.2113V13C17.3208 13.3727 13 17.9233 13 23.4862C13 29.2927 17.7073 34 23.5138 34C26.048 34 28.3704 33.1013 30.1849 31.6076L22.7887 24.2113Z"
                fill="#00FFFF"
            />
        </svg>
    );
};

export default HomeFeature2;
