const Star = ({ width, height, color }) => {
    let _width = "13";
    let _height = "12";
    if (width) _width = width;
    if (height) _height = height;
    return (
        <svg
            width={_width}
            height={_height}
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.496 4.52132C12.4565 4.407 12.3848 4.30659 12.2894 4.23226C12.1939 4.15793 12.079 4.11288 11.9585 4.10257L8.40227 3.58382L6.80852 0.352569C6.75734 0.246899 6.67743 0.157783 6.57795 0.0954272C6.47847 0.0330719 6.36343 0 6.24602 0C6.12861 0 6.01357 0.0330719 5.91409 0.0954272C5.8146 0.157783 5.7347 0.246899 5.68352 0.352569L4.08977 3.57757L0.533519 4.10257C0.417845 4.11901 0.309096 4.16755 0.219613 4.24267C0.130129 4.31779 0.0634936 4.41649 0.0272686 4.52757C-0.00589078 4.63612 -0.00886644 4.75164 0.018661 4.86175C0.0461884 4.97186 0.10318 5.0724 0.183519 5.15257L2.76477 7.65257L2.13977 11.2026C2.11454 11.3203 2.12386 11.4429 2.16663 11.5555C2.2094 11.6681 2.28379 11.7659 2.38085 11.8372C2.47791 11.9085 2.5935 11.9502 2.71372 11.9574C2.83395 11.9646 2.95368 11.9368 3.05852 11.8776L6.24602 10.2088L9.43352 11.8776C9.52124 11.9271 9.62031 11.9529 9.72102 11.9526C9.85341 11.953 9.98253 11.9115 10.0898 11.8338C10.1867 11.7644 10.2618 11.6686 10.3061 11.5579C10.3504 11.4471 10.3621 11.326 10.3398 11.2088L9.71477 7.65882L12.296 5.15882C12.3863 5.08237 12.453 4.98188 12.4884 4.86904C12.5238 4.75619 12.5264 4.63562 12.496 4.52132ZM8.65227 7.02132C8.57993 7.09157 8.52566 7.17829 8.4941 7.27406C8.46254 7.36983 8.45462 7.47182 8.47102 7.57132L8.92102 10.1963L6.57102 8.94632C6.47969 8.90114 6.37916 8.87764 6.27727 8.87764C6.17537 8.87764 6.07485 8.90114 5.98352 8.94632L3.63352 10.1963L4.08352 7.57132C4.09992 7.47182 4.092 7.36983 4.06044 7.27406C4.02888 7.17829 3.97461 7.09157 3.90227 7.02132L2.02727 5.14632L4.65852 4.76507C4.75977 4.75099 4.85602 4.71228 4.93883 4.65235C5.02164 4.59242 5.08849 4.51309 5.13352 4.42132L6.24602 2.04007L7.42102 4.42757C7.46604 4.51934 7.53289 4.59867 7.61571 4.6586C7.69852 4.71853 7.79477 4.75724 7.89602 4.77132L10.5273 5.15257L8.65227 7.02132Z"
                fill={color ? color : "#646464"}
            />
        </svg>
    );
};

export default Star;
